import cn from "classnames";

import { IdsText } from "@emergn-infinity/ids-react";

import { ShowMore } from "components/ShowMore";

export const InfoItem = ({
    className,
    label,
    value,
    showMore,
}: {
    className?: string;
    label: string;
    value: string | React.ReactNode;
    showMore?: boolean;
}) => {
    return (
        <div className={cn("flex-column gap-2 text-break", className)}>
            <IdsText customClasses="text-secondary">
                <span>{label}</span>
            </IdsText>
            {showMore && typeof value === "string" ? (
                <ShowMore value={value} />
            ) : (
                <IdsText>
                    <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>
                </IdsText>
            )}
        </div>
    );
};
