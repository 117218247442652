import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IdsButton } from "@emergn-infinity/ids-react";

import { MeasureCostsGrid } from "components/Grids/MeasureCostsGrid";
import { Tile } from "components/Tile";

import { MeasureCostEdit } from "./MeasureCostEdit";
import { MeasureCostDelete } from "./MeasureCostDelete";

import { Tabs } from "pages/Home/utils";

import { useMeasureDetails } from "../utils";

import { MeasureCostsRights } from "utils/constants";
import { circlePlusRegularIcon } from "utils/icons";
import { hasRights } from "utils/user";

export const MeasureCostsTile: React.FC<{
    selectedMeasure: string;
    sectorNumber?: string;
    readOnly?: boolean;
}> = ({ selectedMeasure, sectorNumber, readOnly }) => {
    const [costNumberForEdit, setCostNumberForEdit] = useState<string>();
    const [costNumberForDelete, setCostNumberForDelete] = useState<string>();
    const [isAdding, setIsAdding] = useState(false);

    const measure = useMeasureDetails(selectedMeasure);

    const editRights = hasRights(MeasureCostsRights) && !readOnly;

    return (
        <Tile
            title="Measure Costs"
            action={
                editRights && (
                    <IdsButton padding="sm" variant="secondary" clickHandler={() => setIsAdding(true)}>
                        <div className="flex-row gap-2 align-center">
                            <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                            Add Measure Cost
                        </div>
                    </IdsButton>
                )
            }
        >
            <div className="p-3">
                {costNumberForEdit && measure && (
                    <MeasureCostEdit costNumber={costNumberForEdit} measure={measure} onClose={() => setCostNumberForEdit(undefined)} />
                )}
                {costNumberForDelete && measure && (
                    <MeasureCostDelete
                        costNumber={costNumberForDelete}
                        measure={measure}
                        onClose={() => setCostNumberForDelete(undefined)}
                    />
                )}
                {isAdding && measure && <MeasureCostEdit measure={measure} onClose={() => setIsAdding(false)} />}
                <MeasureCostsGrid
                    gridType={Tabs.ExploreTrms.id}
                    measureNumber={measure?.measureNumber}
                    sectorNumber={sectorNumber}
                    styles={{
                        sector: {
                            minWidth: 0,
                        },
                        vintage: {
                            minWidth: 0,
                        },
                        cost: {
                            minWidth: 0,
                        },
                        costType: {
                            minWidth: 0,
                        },
                    }}
                    editRights={editRights}
                    onEdit={setCostNumberForEdit}
                    onDelete={setCostNumberForDelete}
                />
            </div>
        </Tile>
    );
};
