import { StandardizeRights } from "utils/constants";

export const Tabs = {
    ExploreTrms: {
        id: "tab-explore-trms",
        name: "Explore TRMs",
        hidden: false,
        rights: [],
    },
    AnalyzeMeasures: {
        id: "tab-analyze-measures",
        name: "Analyze Measures",
        hidden: false,
        rights: [],
    },
    BenchmarkTrm: {
        id: "tab-benchmark-trm",
        name: "Benchmark TRM",
        hidden: false,
        rights: [],
    },
    MeasureCharacterization: {
        id: "tab-measure-characterization",
        name: "Measure Characterization",
        hidden: true,
        rights: [],
    },
    StandardizeData: {
        id: "tab-standardize-data",
        name: "Standardize Data",
        hidden: false,
        rights: StandardizeRights,
    },
    ReviewUpdates: {
        id: "tab-review-updates",
        name: "Review Updates",
        hidden: true,
        rights: [],
    },
    TrmViewer: {
        id: "trm-viewer",
        name: "TRM Viewer",
        hidden: true,
        rights: [],
    },
} as const;
