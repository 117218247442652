import { isEmpty } from "lodash";
import { useGetTrmLinksQuery, useGetSectorPagesQuery, useGetTrmLinkFileQuery } from "store/apiSlice";

export const useViewerData = (trmNumber: string, sectorNumber?: string, measureNumber?: string) => {
    const { data: links, isLoading: isLoadingLinks } = useGetTrmLinksQuery({ trmNumber }, { skip: isEmpty(trmNumber) });
    const { data: sectorPages, isLoading: isLoadingSectorPages } = useGetSectorPagesQuery(
        { sectorNumber: sectorNumber! },
        { skip: isEmpty(sectorNumber) },
    );

    // Get link. All links are the same for a sector
    const linkNumber = sectorPages?.[0]?.linkNumber;
    const link = links?.find((link) => link.linkNumber === linkNumber);

    // Get page number
    const sectorPage = sectorPages?.find((page) => page.measureNumber === measureNumber);
    const pageNumber = sectorPage?.pageNumber ?? 1;

    // Get file
    const { data: trmFile, isLoading: isLoadingTrmFile } = useGetTrmLinkFileQuery(
        { trmNumber, linkNumber: link?.linkNumber! },
        { skip: isEmpty(trmNumber) || isEmpty(link?.linkNumber) },
    );

    const isLoading = isLoadingLinks || isLoadingSectorPages || isLoadingTrmFile;

    return { pageNumber, trmFile, isLoading };
};
