import { memo } from "react";
import { isEqual } from "lodash";
import { useObjectUrl } from "./useObjectUrl";

export const PdfViewer: React.FC<{
    file: Blob | undefined;
    pageNumber: number;
}> = memo(
    ({ file, pageNumber }) => {
        const url = useObjectUrl(file, pageNumber);

        if (!file) {
            return null;
        }

        return (
            <div className="flex flex-col h-full">
                <object data={url} type="application/pdf" className="w-full h-full">
                    <p>Your browser does not support PDFs. Please download the PDF to view it.</p>
                </object>
            </div>
        );
    },
    (prevProps, nextProps) => {
        return isEqual(prevProps, nextProps);
    },
);
