import { isEmpty } from "lodash";
import React, { useCallback, useRef } from "react";

import { IdsContainer, IdsRow, IdsCol } from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";

import { useGetTrmDetailsQuery } from "store/apiSlice";
import { useAppSelector, useAppDispatch } from "store/hooks";

import { bookSolidIcon, fileSlashSolidIcon } from "utils/icons";

import { Sidebar } from "./Sidebar";
import { UpgradingTrmBanner } from "./UpgradingTrmBanner";
import { TrmDetailsTile } from "./TrmDetails/TrmDetailsTile";
import { MeasureDetailsTile } from "./MeasureDetailsTile";
import { MeasureTiles } from "./MeasureTiles";

export const ExploreTrms = () => {
    const dispatch = useAppDispatch();

    const { selectedTrm, selectedMeasure } = useAppSelector((state) => state.exploreTrms);
    const upgradeTrmState = useAppSelector((state) => state.upgradeTrm);

    const selectTrmRef = useRef<HTMLIdsDropdownElement>(null);
    const selectMeasureRef = useRef<HTMLIdsDropdownElement>(null);

    const { data: trmDetails } = useGetTrmDetailsQuery({ trmNumber: selectedTrm }, { skip: isEmpty(selectedTrm) });

    const isUpgrading = Object.values(upgradeTrmState[selectedTrm] ?? {}).some((value) => !isEmpty(value));
    const showTrmDetails = !isEmpty(selectedTrm);
    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);

    const onChooseClick = useCallback((ref: React.RefObject<HTMLIdsDropdownElement>) => {
        setTimeout(() => {
            ref.current?.querySelector("ids-icon")?.click();
        }, 100);
    }, []);

    const onTrmSelect = (trmNumber: string) => {
        dispatch(selectorChange({ selector: "trm", value: trmNumber }));
    };

    const onMeasureSelect = (measureNumber: string) => {
        dispatch(selectorChange({ selector: "measure", value: measureNumber }));
    };

    const onMeasureClear = () => {
        dispatch(selectorChange({ selector: "measure", value: "" }));
    };

    return (
        <IdsContainer fullHeight customClasses="p-0">
            <IdsRow noGutters>
                <Sidebar
                    selectedTrm={selectedTrm}
                    selectedMeasure={selectedMeasure}
                    selectTrmRef={selectTrmRef}
                    selectMeasureRef={selectMeasureRef}
                    onTrmSelect={onTrmSelect}
                    onMeasureSelect={onMeasureSelect}
                    onMeasureClear={onMeasureClear}
                />
                <IdsCol xs="9">
                    {showTrmDetails ? (
                        <>
                            {isUpgrading && <UpgradingTrmBanner selectedTrm={selectedTrm} />}
                            <div key={`${selectedTrm}`} className="flex-column fill-height with-scroll gap-3 p-3">
                                <TrmDetailsTile selectedTrm={selectedTrm} selectedMeasure={selectedMeasure} trmDetails={trmDetails} />
                                {showMainContent ? (
                                    <div key={`${selectedTrm}-${selectedMeasure}`} className="flex-column gap-3">
                                        <MeasureDetailsTile selectedMeasure={selectedMeasure} />
                                        <MeasureTiles key={`${selectedTrm}-${selectedMeasure}`} />
                                    </div>
                                ) : (
                                    <NothingFoundBlock
                                        icon={fileSlashSolidIcon}
                                        title="No Measure Chosen"
                                        message="Please select a measure to view its details."
                                        secondaryActionLabel="Choose Measure"
                                        onSecondaryActionClick={() => onChooseClick(selectMeasureRef)}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <NothingFoundBlock
                            icon={bookSolidIcon}
                            title="No TRM Chosen"
                            message="Please select a TRM to view its details."
                            secondaryActionLabel="Choose TRM"
                            onSecondaryActionClick={() => onChooseClick(selectTrmRef)}
                        />
                    )}
                </IdsCol>
            </IdsRow>
        </IdsContainer>
    );
};
