import { isEmpty, isNumber } from "lodash";
import { evaluate } from "mathjs";
import { useMemo } from "react";

import { IdsDivider, IdsHelper, IdsText } from "@emergn-infinity/ids-react";

import { Tile } from "components/Tile";

import { formatAlgorithmText, formatNumber } from "utils/string";

import { getAssignedValue, findEquation } from "./utils";

import type { MeasureAlgorithm, Variable } from "types";

export const SavingsCalculationTile: React.FC<{
    algorithm?: MeasureAlgorithm;
    savingsCalc?: string;
    assignedSavingsCalc?: string;
    variableValues: Variable[];
}> = ({ algorithm, savingsCalc, assignedSavingsCalc, variableValues }) => {
    const algorithmWithVariables = applyAlgorithmVariables(assignedSavingsCalc ?? "", variableValues);
    const unit = algorithm?.algorithm.split("=")?.[0].trim();

    const { result, errorMessage } = useMemo(() => {
        if (isEmpty(algorithmWithVariables) || isEmpty(variableValues)) {
            return {
                result: "",
                errorMessage: "",
            };
        }

        try {
            return {
                result: evaluate(algorithmWithVariables),
                errorMessage: "",
            };
        } catch (error) {
            const areVariablesSet = variableValues
                .filter((variable) => variable.isFoundInAlgorithm)
                .every((variable) => !isEmpty(variable.userInput) || isNumber(variable.lookupValue) || !isEmpty(variable.lookupEquation));

            return {
                result: "",
                errorMessage: areVariablesSet ? (error as Error).message : "Variables not set",
            };
        }
    }, [algorithmWithVariables, variableValues]);

    if (!algorithm || !savingsCalc || !assignedSavingsCalc) {
        return null;
    }

    return (
        <Tile title="Savings Calculation">
            <div className="flex-row p-3 gap-3">
                <Tile className="flex-one-in-row" title="Calculation">
                    <div className="flex-column p-3">
                        {savingsCalc !== assignedSavingsCalc && (
                            <>
                                <IdsText size="md">
                                    <div className="flex-row gap-1">
                                        <span>{unit}</span>
                                        <span>=</span>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: formatAlgorithmText(savingsCalc),
                                            }}
                                        />
                                    </div>
                                </IdsText>
                                <IdsDivider spacing="sm" />
                            </>
                        )}
                        <IdsText size="md">
                            <div className="flex-row gap-1">
                                <span>{unit}</span>
                                <span>=</span>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: formatAlgorithmText(assignedSavingsCalc),
                                    }}
                                />
                            </div>
                        </IdsText>
                        <IdsDivider spacing="sm" />
                        <IdsText size="md">
                            <div className="flex-row gap-1">
                                <span>{unit}</span>
                                <span>=</span>
                                <span dangerouslySetInnerHTML={{ __html: formatAlgorithmText(algorithmWithVariables) }} />
                            </div>
                        </IdsText>
                        {!isEmpty(errorMessage) && <IdsHelper helperIcon="ui-form-error_circle" isInvalid helperText={errorMessage} />}
                    </div>
                </Tile>
                <div className="flex-column align-center justify-center">
                    <IdsText weight="bold">=</IdsText>
                </div>
                <Tile className="flex-one-in-row" title="Result">
                    <div className="flex-column fill-height justify-center p-3">
                        <IdsText weight="bold">{`${unit} = ${formatNumber(result)}`}</IdsText>
                    </div>
                </Tile>
            </div>
        </Tile>
    );
};

const applyAlgorithmVariables = (algorithm: string, variableValues: Variable[]) => {
    let result = algorithm;
    const overridenEquationVariables = variableValues.filter(
        (variable) => (!isEmpty(variable.equation) || !isEmpty(variable.lookupEquation)) && !isEmpty(variable.userInput),
    );

    // Replace variables with real values
    variableValues.forEach((variable) => {
        const assignedValue = getAssignedValue(variable);

        if (assignedValue) {
            result = result.replaceAll(`<${variable.variable}>`, assignedValue.toString());
        }
    });

    // If there are variables that have equation and user input provided,
    // those variables are considered to be overridden and therefore need to
    // replace whole equation with a real value
    if (!isEmpty(overridenEquationVariables)) {
        overridenEquationVariables.forEach((variable) => {
            const variableEquation = variable.equation ?? variable.lookupEquation;
            const assignedValue = getAssignedValue(variable);

            if (assignedValue && variableEquation) {
                const equation = findEquation(variableEquation, variableValues, true);

                result = result.replaceAll(`(${equation})`, assignedValue.toString());
            }
        });
    }

    // remove text surrounded by []
    result = result.replaceAll(/\[.*?\]/g, "");

    return result;
};
