import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import { useState } from "react";

import { IdsText, IdsHelper, IdsButton } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LinkCard, LinkSaveParams } from "components/LinkCard";

import { TrmDetailsDelete } from "pages/ExploreTrms/TrmDetails/TrmDetailsDelete";

import { circlePlusRegularIcon } from "utils/icons";

import type { LinkModel } from ".";

export const LinksStep: React.FC<{
    trmFamiliarName: string;
    links: LinkModel[];
    isEditing: boolean;
    setLinks: (links: LinkModel[]) => void;
    setIsEditing: (isEditing: boolean) => void;
}> = ({ trmFamiliarName, links, isEditing, setLinks, setIsEditing }) => {
    const [linkNumberForEdit, setLinkNumberForEdit] = useState("");
    const [linkForDelete, setLinkForDelete] = useState<LinkModel>();

    let linkText = `${links.length} links`;

    if (links.length === 1) {
        linkText = linkText.substring(0, linkText.length - 1);
    }

    const onCancel = () => {
        setIsEditing(false);
        setLinkNumberForEdit("");
    };

    const onSave = (params: LinkSaveParams) => {
        const { linkNumber, ...rest } = params;

        const newLinks = [...links];

        if (linkNumber) {
            const index = newLinks.findIndex((link) => link.linkNumber === linkNumber);

            newLinks[index] = { ...newLinks[index], ...rest };
        } else {
            newLinks.push({ linkNumber: uuidv4(), ...rest });
        }

        setIsEditing(false);
        setLinkNumberForEdit("");
        setLinks(newLinks);
    };

    const onEdit = (linkNumber: string) => {
        setIsEditing(true);
        setLinkNumberForEdit(linkNumber);
    };

    const onAddLink = () => {
        setIsEditing(true);
    };

    const onDelete = (linkNumber: string) => {
        const newLinks = [...links].filter((link) => link.linkNumber !== linkNumber);

        setLinkForDelete(undefined);
        setLinks(newLinks);
    };

    if (linkForDelete) {
        return (
            <TrmDetailsDelete
                headerText="Delete Link"
                trmFamiliarName={trmFamiliarName}
                confirmationMessage="Are you sure you want to delete this link?"
                onClose={() => setLinkForDelete(undefined)}
                onDelete={() => onDelete(linkForDelete.linkNumber)}
            >
                <LinkCard linkName={linkForDelete.linkName} urlLink={linkForDelete.urlLink} availability={linkForDelete.availability} />
            </TrmDetailsDelete>
        );
    }

    return (
        <div className="flex-column gap-4">
            <div className="flex-row align-center justify-space-between">
                <IdsText size="sm" weight="bold" component="h4">
                    Links
                </IdsText>
                <IdsHelper helperText={linkText} isInvalid={isEmpty(links)} />
            </div>
            <div className="flex-column gap-2">
                {links.map((link) => (
                    <div key={link.linkNumber}>
                        <LinkCard
                            isEditing={link.linkNumber === linkNumberForEdit}
                            linkNumber={link.linkNumber}
                            availability={link.availability}
                            format={link.format}
                            documentType={link.documentType}
                            linkName={link.linkName}
                            linkDescription={link.linkDescription}
                            urlLink={link.urlLink}
                            onCancel={onCancel}
                            onSave={onSave}
                            onDelete={!isEditing ? () => setLinkForDelete(link) : undefined}
                            onEdit={!isEditing ? () => onEdit(link.linkNumber) : undefined}
                        />
                    </div>
                ))}
                {isEditing && !linkNumberForEdit && <LinkCard isEditing onCancel={onCancel} onSave={onSave} />}
                {!isEditing && (
                    <div className="rounded-edges-rounder" style={{ border: "1px dashed var(--ids-semantic-border-color-neutral-faint)" }}>
                        <IdsButton variant="tertiary" padding="lg" fullWidth clickHandler={onAddLink}>
                            <div className="flex-row align-center gap-2">
                                <FontAwesomeIcon icon={circlePlusRegularIcon} size="lg" />
                                Add Link
                            </div>
                        </IdsButton>
                    </div>
                )}
            </div>
        </div>
    );
};
