import cn from "classnames";
import { isEmpty } from "lodash";
import { useMemo, useRef } from "react";
import { toast } from "react-toastify";

import { IdsButton, IdsProgressCircular, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setActiveTab } from "pages/Home/homeSlice";
import { Tabs } from "pages/Home/utils";

import { useGetUpgradeTrmVersionStatusesQuery } from "store/apiSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEventsQuery } from "store/signalrSlice";
import { setStagingNumber, completeUpgrade } from "store/upgradeTrmSlice";

import { arrowRightSolidIcon, arrowsRotateSolidIcon, triangleExclamationSolidIcon } from "utils/icons";

export const UpgradingTrmBanner: React.FC<{
    selectedTrm: string;
}> = ({ selectedTrm }) => {
    const dispatch = useAppDispatch();

    const upgradeTrmState = useAppSelector((state) => state.upgradeTrm[selectedTrm]);

    const completeRef = useRef(false);
    const errorRef = useRef(false);

    const { data: allStatuses } = useGetUpgradeTrmVersionStatusesQuery();
    const { data: currentStatuses } = useEventsQuery("PostStatusAsync");

    const isStaged = currentStatuses?.[currentStatuses.length - 1] === "Staged";
    const isComplete = currentStatuses?.[currentStatuses.length - 1] === "Complete";
    const isError = currentStatuses?.[currentStatuses.length - 1] === "Error";
    const stagingNumber = !isComplete ? currentStatuses?.[0] : undefined;

    const statuses = useMemo(() => {
        let statuses: string[] = [];

        if (stagingNumber && currentStatuses) {
            statuses = currentStatuses.filter((status) => status !== stagingNumber);
        }

        return statuses;
    }, [stagingNumber, currentStatuses]);

    const progress = useMemo(() => {
        let progress = 0;

        if (allStatuses && statuses) {
            const stepsCount = allStatuses.find((status) => status.statusName === "Complete" || status.statusName === "Error")?.statusOrder;

            if (stepsCount) {
                progress = (statuses.length / stepsCount) * 100;
            }
        }

        return progress;
    }, [allStatuses, statuses]);

    // TODO: Fix bad setState
    if (isEmpty(upgradeTrmState.stagingNumber) && stagingNumber) {
        dispatch(setStagingNumber({ targetTrmNumber: selectedTrm, stagingNumber }));
    }
    if (isComplete && !completeRef.current) {
        completeRef.current = true;

        dispatch(completeUpgrade(selectedTrm));
    } else if (isError && !errorRef.current) {
        errorRef.current = true;

        toast.error(`Unexpected error during update. Review updates and logs for more info. Staging Number ${stagingNumber}`);
    }

    return (
        <div
            className={cn("flex-row align-center gap-3 bg-theme-base-blue p-3", {
                "bg-alert-warning": isStaged,
            })}
            style={{ borderBottom: "1px solid var(--theme-base-border)" }}
        >
            <FontAwesomeIcon
                icon={isStaged ? triangleExclamationSolidIcon : arrowsRotateSolidIcon}
                size="lg"
                color={isStaged ? "var(--theme-feedback-warning)" : "var(--ids-semantic-ink-color-brand-a-accent)"}
                fixedWidth
            />
            <div className="fill-width">
                <IdsText weight="bold" style={{ color: "var(--ids-text-heading-color)" }}>
                    Update is Under Review
                </IdsText>
                <IdsText size="md" style={{ color: "var(--theme-text-text)" }}>
                    We're currently processing your submission by comparing it with the current version. This may take a few moments.
                </IdsText>
            </div>
            {isStaged || isError ? (
                <IdsButton padding="sm" clickHandler={() => dispatch(setActiveTab(Tabs.ReviewUpdates.id))}>
                    <div className="flex-row align-center gap-2">
                        Review Updates
                        <FontAwesomeIcon icon={arrowRightSolidIcon} size="lg" />
                    </div>
                </IdsButton>
            ) : (
                <div className="flex-row align-center gap-2">
                    <IdsText customClasses="text-secondary" style={{ textWrap: "nowrap" }}>
                        <>{statuses === undefined || isEmpty(statuses) ? "Starting..." : statuses[statuses.length - 1]}</>
                    </IdsText>
                    <IdsProgressCircular progress={progress} color="brand" size="xs" />
                </div>
            )}
        </div>
    );
};
