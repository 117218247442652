import { isEmpty } from "lodash";
import React, { useState } from "react";

import { useGetLookupsQuery, useCreateLookupsMutation, useUpdateLookupsMutation } from "store/apiSlice";

import {
    IdsFieldWrapper,
    IdsButton,
    IdsButtonGroup,
    IdsDivider,
    IdsRadioButton,
    IdsRadioButtonGroup,
    IdsTextInput,
    IdsText,
    IdsModal,
} from "@emergn-infinity/ids-react";

import { LookupsGrid } from "./LookupsGrid";

import { EditFormTags } from "components/EditFormTags";

import { LookupVariants } from "utils/constants";

import { LookupType, Measure, LookupEntry } from "types";

const LOOKUPS_MODAL_STYLE = {
    width: "640px",
    maxWidth: "100%",
};

export const ManageLookups: React.FC<{
    measure: Measure;
    lookupType: LookupType;
    title: string;
    breadcrumb: JSX.Element[];
    lookupByPremise?: string;
    sector?: string;
    endUse?: string;
    vintage?: string;
    variable?: string;
    modalStyle?: React.CSSProperties;
    onClose: () => void;
}> = ({ measure, lookupType, title, breadcrumb, lookupByPremise, sector, endUse, vintage, variable, modalStyle, onClose }) => {
    const [hasErrors, setHasErrors] = useState(false);
    const [lookupsByValue, setLookupsByValue] = useState<LookupState[]>([]);
    const [lookupsByEquation, setLookupsByEquation] = useState<LookupState[]>([]);
    const [tableName, setTableName] = useState(lookupByPremise ?? "");
    const [lookupVariant, setLookupVariant] = useState<keyof typeof LookupVariants>(LookupVariants.Value);

    const { data: lookupsData, isLoading: isLoadingLookupsData } = useGetLookupsQuery(
        { trmNumber: measure.trmNumber, lookupType, lookupByPremise },
        { skip: isEmpty(lookupByPremise) },
    );

    const [create, createStatus] = useCreateLookupsMutation();
    const [update, updateStatus] = useUpdateLookupsMutation();

    let lookups: LookupState[] = [];
    let setLookups = setLookupsByValue;
    const isAddingNew = isEmpty(lookupByPremise);

    if (lookupVariant === LookupVariants.Value) {
        lookups = lookupsByValue;
    } else if (lookupVariant === LookupVariants.Equation) {
        lookups = lookupsByEquation;
        setLookups = setLookupsByEquation;
    }

    // If read-only mode, fill with lookups
    if (
        lookupsData !== undefined &&
        !isLoadingLookupsData &&
        !isEmpty(lookupByPremise) &&
        !isEmpty(lookupsData) &&
        isEmpty(lookupsByValue) &&
        isEmpty(lookupsByEquation)
    ) {
        const newLookupsByValue = lookupsData
            .filter((lookup) => lookup.lookupValue !== null)
            .map((lookup) => ({
                lookupCriteria: lookup.lookupCriteria,
                lookupValue: String(lookup.lookupValue),
            }));

        const newLookupsByEquation = lookupsData
            .filter((lookup) => lookup.lookupEquation !== null)
            .map((lookup) => ({
                lookupCriteria: lookup.lookupCriteria,
                lookupEquation: lookup.lookupEquation!,
            }));

        setLookupsByValue(newLookupsByValue);
        setLookupsByEquation(newLookupsByEquation);

        if (isEmpty(newLookupsByValue) && !isEmpty(newLookupsByEquation)) {
            setLookupVariant(LookupVariants.Equation);
        }
    }

    const onSaveLookups = async () => {
        if (isEmpty(lookups) || (isAddingNew && createStatus.isLoading) || (!isAddingNew && updateStatus.isLoading)) {
            return;
        }

        // Set error if table name is empty
        if (isEmpty(tableName)) {
            setHasErrors(true);

            return;
        }

        // Set error if any lookup criteria or value for lookups with value is empty
        if (lookupVariant === LookupVariants.Value && lookups.some((item) => isEmpty(item.lookupCriteria) || isEmpty(item.lookupValue))) {
            setHasErrors(true);

            return;
        }

        // Set error if any lookup criteria or equation for lookups with equation is empty
        if (
            lookupVariant === LookupVariants.Equation &&
            lookups.some((item) => isEmpty(item.lookupCriteria) || isEmpty(item.lookupEquation))
        ) {
            setHasErrors(true);

            return;
        }

        try {
            const entries = lookups.map((lookup) => ({ ...lookup, lookupValue: Number(lookup.lookupValue) }));

            const lookupsModel = {
                trmNumber: measure.trmNumber,
                lookupByPremise: tableName,
                lookupType,
                lookupVerified: true,
                entries,
            };

            if (isAddingNew) {
                await create({ lookups: lookupsModel });
            } else {
                await update({ lookups: lookupsModel });
            }

            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal version={2} isOpen closeHandler={onClose} showCloseButton customClasses="slideout">
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    {title}
                </IdsText>
            </div>
            <div slot="main" style={modalStyle ?? LOOKUPS_MODAL_STYLE}>
                <EditFormTags
                    trmName={measure.trmFamiliarName}
                    measureName={measure.measureName}
                    sector={sector}
                    endUse={endUse}
                    vintage={vintage}
                    variable={variable}
                />
                <IdsDivider />
                <div className="flex-row align-center flex-wrap gap-2 pt-3 pb-4">
                    {breadcrumb.map((element, index) => (
                        <React.Fragment key={String(index)}>
                            {element}
                            <IdsText>/</IdsText>
                        </React.Fragment>
                    ))}
                    <IdsText weight="bold">New Lookup Table</IdsText>
                </div>
                <IdsFieldWrapper wrapperLabel="Lookup table name" isRequired isInvalid={hasErrors && isEmpty(tableName)}>
                    <IdsTextInput defaultValue={tableName} changeHandler={setTableName} />
                </IdsFieldWrapper>
                <IdsFieldWrapper htmlFor="lookup-variant" wrapperLabel="Lookup type">
                    <IdsRadioButtonGroup horizontalOrientation>
                        <IdsRadioButton
                            idValue={LookupVariants.Value}
                            name="lookup-variant"
                            label={LookupVariants.Value}
                            defaultChecked={lookupVariant === LookupVariants.Value}
                            changeHandler={(e: any) => setLookupVariant(e.target.id)}
                        />
                        <IdsRadioButton
                            idValue={LookupVariants.Equation}
                            name="lookup-variant"
                            label={LookupVariants.Equation}
                            defaultChecked={lookupVariant === LookupVariants.Equation}
                            changeHandler={(e: any) => setLookupVariant(e.target.id)}
                        />
                    </IdsRadioButtonGroup>
                </IdsFieldWrapper>
                {!isLoadingLookupsData && (
                    <div key={`lookups-by-${lookupVariant}`}>
                        <LookupsGrid lookups={lookups} lookupVariant={lookupVariant} hasErrors={hasErrors} setLookups={setLookups} />
                    </div>
                )}
            </div>
            <div slot="footer">
                <IdsButtonGroup spaceBetween="md">
                    <IdsButton variant="secondary" clickHandler={onClose}>
                        Cancel
                    </IdsButton>
                    <IdsButton
                        variant="primary"
                        isDisabled={
                            (lookupVariant === LookupVariants.Value && isEmpty(lookupsByValue)) ||
                            (lookupVariant === LookupVariants.Equation && isEmpty(lookupsByEquation)) ||
                            isEmpty(tableName)
                        }
                        clickHandler={onSaveLookups}
                    >
                        <>{createStatus.isLoading ? "Saving..." : "Save Lookup Table"}</>
                    </IdsButton>
                </IdsButtonGroup>
            </div>
        </IdsModal>
    );
};

export type LookupState = Omit<LookupEntry, "lookupValue"> & {
    lookupValue?: string;
};
