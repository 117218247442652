export function saveStateInStorage<T>(key: string, state: T) {
    sessionStorage.setItem(key, JSON.stringify(state));
}

export function loadStateFromStorage<T>(key: string, fallbackState: T): T {
    try {
        const serializedState = sessionStorage.getItem(key);
        if (serializedState) {
            return JSON.parse(serializedState);
        }
    } catch (error) {
        console.error("Could not load state", error);
    }

    return fallbackState;
}
