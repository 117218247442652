import { Tile } from "components/Tile";

import { useGetTrmDetailsQuery } from "store/apiSlice";

import { loaderSolidIcon } from "utils/icons";

import { ChangesField } from "./ChangesField";

import type { TrmUpdateModel, TrmDetails } from "types";

export const TrmInformation: React.FC<{
    sourceTrmNumber: string;
    targetTrmDetails: TrmDetails;
    targetTrmDetailsState: TrmUpdateModel;
    setTargetTrmDetailsState: (trmDetails: TrmUpdateModel) => void;
}> = ({ sourceTrmNumber, targetTrmDetails, targetTrmDetailsState, setTargetTrmDetailsState }) => {
    const { data: sourceTrmDetails } = useGetTrmDetailsQuery({ trmNumber: sourceTrmNumber });

    const onSave = (value: string, key: string) => {
        setTargetTrmDetailsState({
            ...targetTrmDetailsState,
            [key]: value,
        });
    };

    return (
        <Tile title="Updates" icon={loaderSolidIcon} noDivider>
            {sourceTrmDetails && (
                <div className="flex-column gap-4 p-3">
                    <ChangesField
                        idValue="trm-familiar-name"
                        label="TRM Familiar Name"
                        sourceValue={sourceTrmDetails.trmFamiliarName}
                        targetValue={targetTrmDetailsState.trmFamiliarName}
                        onSave={(value) => onSave(value, "trmFamiliarName")}
                    />
                    <ChangesField
                        idValue="trm-name"
                        label="TRM Name"
                        sourceValue={sourceTrmDetails.trmName}
                        targetValue={targetTrmDetailsState.trmName}
                        onSave={(value) => onSave(value, "trmName")}
                    />
                    <ChangesField
                        idValue="authored-by"
                        label="Authored By"
                        sourceValue={sourceTrmDetails.preparedBy}
                        targetValue={targetTrmDetailsState.preparedBy}
                        onSave={(value) => onSave(value, "preparedBy")}
                    />
                    <ChangesField
                        idValue="applicable-year"
                        label="Applicable Year"
                        sourceValue={String(sourceTrmDetails.applicableYear)}
                        targetValue={String(targetTrmDetailsState.applicableYear)}
                        yearInput
                        onSave={(value) => onSave(value, "applicableYear")}
                    />
                    <ChangesField
                        idValue="year-authored"
                        label="Year Authored"
                        sourceValue={String(sourceTrmDetails.yearPrepared)}
                        targetValue={String(targetTrmDetailsState.yearPrepared)}
                        yearInput
                        onSave={(value) => onSave(value, "yearPrepared")}
                    />
                    <ChangesField
                        idValue="summer-def"
                        label="Summer Peak Definition"
                        sourceValue={sourceTrmDetails.defSummerPeak}
                        targetValue={targetTrmDetailsState.defSummerPeak}
                        onSave={(value) => onSave(value, "defSummerPeak")}
                    />
                    <ChangesField
                        idValue="winter-def"
                        label="Winter Peak Definition"
                        sourceValue={sourceTrmDetails.defWinterPeak}
                        targetValue={targetTrmDetailsState.defWinterPeak}
                        onSave={(value) => onSave(value, "defWinterPeak")}
                    />
                </div>
            )}
        </Tile>
    );
};
