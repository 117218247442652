import { createSlice } from "@reduxjs/toolkit";

import { saveStateInStorage, loadStateFromStorage } from "./utils";

import type { PayloadAction } from "@reduxjs/toolkit";

const STATE_KEY = "upgrade-trm-state";

const initialState: UpgradeTrmState = {};

export const upgradeTrmSlice = createSlice({
    name: "upgrade-trm",
    initialState: loadStateFromStorage(STATE_KEY, initialState),
    reducers: {
        startUpgrade: (state, action: PayloadAction<StartUpgradePayload>) => {
            const { publisherNumber, sourceTrmNumber, targetTrmNumber } = action.payload;

            state[targetTrmNumber] = {
                publisherNumber,
                sourceTrmNumber,
                stagingNumber: "",
            };

            saveStateInStorage(STATE_KEY, state);
        },
        setStagingNumber: (state, action: PayloadAction<SetStagingNumberPayload>) => {
            const { targetTrmNumber, stagingNumber } = action.payload;

            state[targetTrmNumber] = {
                ...state[targetTrmNumber],
                stagingNumber,
            };

            saveStateInStorage(STATE_KEY, state);
        },
        completeUpgrade: (state, action: PayloadAction<string>) => {
            const targetTrmNumber = action.payload;

            state[targetTrmNumber] = {
                publisherNumber: "",
                sourceTrmNumber: "",
                stagingNumber: "",
            };

            saveStateInStorage(STATE_KEY, state);
        },
    },
});

type UpgradeTrmState = {
    [key: string]: {
        publisherNumber: string;
        sourceTrmNumber: string;
        stagingNumber: string;
    };
};

type StartUpgradePayload = {
    publisherNumber: string;
    sourceTrmNumber: string;
    targetTrmNumber: string;
};

type SetStagingNumberPayload = {
    targetTrmNumber: string;
    stagingNumber: string;
};

export const { startUpgrade, setStagingNumber, completeUpgrade } = upgradeTrmSlice.actions;

export default upgradeTrmSlice.reducer;
