import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

import { IdsButton } from "@emergn-infinity/ids-react";

import { Badge } from "components/Badge";

import type { BadgeVariant } from "components/Badge";

import "./style.scss";

// Currently supported sizes are - default, lg, xl
export const IconButton: React.FC<{
    icon: IconProp;
    color?: string;
    size?: SizeProp;
    square?: boolean;
    title?: string;
    isDisabled?: boolean;
    badgeNumber?: number;
    badgeVariant?: BadgeVariant;
    onClick?: () => void;
}> = ({ icon, color, size, square, title, isDisabled, badgeNumber, badgeVariant, onClick }) => {
    let sizeClassName = "";
    let paddingClassName = "";

    if (size) {
        sizeClassName = `size-${size}`;
    }

    return (
        <div className="icon-button">
            <IdsButton
                customClasses={cn(sizeClassName, paddingClassName, {
                    "square-button": square,
                })}
                variant="tertiary"
                title={title ?? ""}
                isDisabled={isDisabled}
                clickHandler={onClick}
            >
                <FontAwesomeIcon icon={icon} color={color} size={size} fixedWidth />
            </IdsButton>
            {(badgeNumber || badgeVariant) && <Badge variant={badgeVariant} number={badgeNumber} />}
        </div>
    );
};
