import { map } from "lodash";
import { useState } from "react";

import { IdsTabItem, IdsTabPanel, IdsTabs, IdsText } from "@emergn-infinity/ids-react";

import { MemoizeComponentWrapper } from "components/MemoizeComponentWrapper";
import { PowerBiReport } from "components/PowerBiReport";

import { Tabs } from "./utils";

export const AnalyzeMeasures: React.FC = () => {
    const [activeTab, setActiveTab] = useState<Tab>(Tabs.CurrentLandscape.id);

    const onTabChange = (id: Tab) => {
        document.getElementById("analyze-measures-tabs")?.scrollIntoView({ behavior: "smooth" });

        setActiveTab(id);
    };

    return (
        <div className="flex-column bg-white analyze-measures">
            <div className="py-4">
                <div className="flex-column gap-2 px-5 py-4">
                    <IdsText weight="bold" component="h2">
                        Analyze Measures
                    </IdsText>
                    <IdsText customClasses="text-secondary">
                        {`View Power BI reports for ${Tabs.CurrentLandscape.name} and ${Tabs.MeasureHistory.name}.`}
                    </IdsText>
                </div>
            </div>
            <IdsTabs id="analyze-measures-tabs" className="full-height powerbi-report-tabs">
                {map(Tabs, (tab) => (
                    <IdsTabItem
                        key={`tab-header-${tab.id}`}
                        slot="header"
                        idValue={tab.id}
                        label={tab.name}
                        isActive={tab.id === activeTab}
                        onClick={() => onTabChange(tab.id)}
                    />
                ))}

                {map(Tabs, (tab) => (
                    <IdsTabPanel key={`tab-panel-${tab.id}`} slot="panel" idValue={tab.id} style={{ backgroundColor: "inherit" }}>
                        {/* Load each tab only once */}
                        <MemoizeComponentWrapper active={activeTab === tab.id}>
                            <PowerBiReport reportId={tab.reportId!} />
                        </MemoizeComponentWrapper>
                    </IdsTabPanel>
                ))}
            </IdsTabs>
        </div>
    );
};

type Tab = (typeof Tabs)[keyof typeof Tabs]["id"];
