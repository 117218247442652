import { IdsButtonGroup, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";

import { AccessLevels } from "utils/constants";
import {
    linkSimpleRegularIcon,
    lockKeyholeOpenRegularIcon,
    lockKeyholeRegularIcon,
    penToSquareRegularIcon,
    trashRegularIcon,
} from "utils/icons";

export const Link: React.FC<{
    linkName: string;
    urlLink: string;
    availability?: string;
    onDelete?: () => void;
    onEdit?: () => void;
}> = ({ linkName, urlLink, availability, onDelete, onEdit }) => {
    return (
        <div className="flex flex-row">
            {/* TODO: Add Tailwind classes */}
            <div
                className="flex flex-row grow shrink basis-0 rounded-lg items-center"
                style={{
                    backgroundColor: "var(--ids-semantic-background-color-brand-b-subtle-default)",
                    paddingTop: "1.5rem",
                    paddingBottom: "1.5rem",
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                }}
            >
                <FontAwesomeIcon icon={linkSimpleRegularIcon} size="lg" color="var(--ids-semantic-border-color-neutral-subtle)" />
            </div>
            <div className="flex flex-row items-center w-full min-w-0">
                <div className="flex flex-col w-full overflow-hidden px-2">
                    {availability && (
                        <div className="flex flex-row items-center gap-2">
                            <FontAwesomeIcon
                                className="icon-secondary"
                                icon={availability === AccessLevels.Public ? lockKeyholeOpenRegularIcon : lockKeyholeRegularIcon}
                                size="xs"
                            />
                            <IdsText customClasses="text-secondary" size="sm" weight="bold">
                                {availability}
                            </IdsText>
                        </div>
                    )}
                    <IdsText weight="bold">
                        <>{linkName}</>
                    </IdsText>
                    <IdsText customClasses="text-secondary" size="md">
                        <div className="truncate">{urlLink}</div>
                    </IdsText>
                </div>
                {onDelete && onEdit && (
                    <div className="border-l border-solid border-base-color-border pl-2">
                        <IdsButtonGroup customClasses="flex-nowrap">
                            <IconButton icon={trashRegularIcon} size="lg" onClick={onDelete} />
                            <IconButton icon={penToSquareRegularIcon} size="lg" onClick={onEdit} />
                        </IdsButtonGroup>
                    </div>
                )}
            </div>
        </div>
    );
};
