// Compile time flags to identify environment we are in
export const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT";
export const isProdEnvironment = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION";

export const clientRoute = {
    resetPassword: "resetpassword",
    sso: "sso",
};

export const Rights = [
    "FFCU",
    "FFCR",
    "FFTU",
    "FFTR",
    "FPU",
    "FPR",
    "FTU",
    "FTR",
    "FZCU",
    "FZCR",
    "SALU",
    "SALR",
    "SEUU",
    "SEUR",
    "SMU",
    "SMR",
    "SPU",
    "SPR",
    "SSU",
    "SSR",
    "SVAU",
    "SVAR",
    "SVU",
    "SVR",
    "TALU",
    "TALR",
    "TASU",
    "TASR",
    "TCU",
    "TCR",
    "TELU",
    "TELR",
    "TEUU",
    "TEUR",
    "TLU",
    "TLR",
    "TMU",
    "TMR",
    "TSU",
    "TSR",
    "TVU",
    "TVR",
] as const;

export const StandardizeRights = Rights.filter((code) => ["SEUU", "SMU", "SPU", "SSU", "SVAU", "SVU"].includes(code));

export const TrmRights = Rights.find((code) => code === "FTU") as "FTU";
export const MeasuresRights = Rights.find((code) => code === "TMU") as "TMU";
export const MeasureLivesRights = Rights.find((code) => code === "TELU") as "TELU";
export const MeasureCostsRights = Rights.find((code) => code === "TCU") as "TCU";
export const AlgorithmsRights = Rights.find((code) => code === "TALU") as "TALU";
export const AssumptionsRights = Rights.find((code) => code === "TASU") as "TASU";

export const API_PAGINATION_FETCH_LIMIT = 1000;

// Make sure values can be divided from API_PAGINATION_FETCH_LIMIT
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const ITEMS_PER_PAGE = [25, 50, 100];

export const AccessLevels = {
    Public: "Public",
    Confidential: "Confidential",
} as const;

export const LinkFormats = {
    PDF: "PDF",
    Word: "Word",
    Excel: "Excel",
} as const;

// TODO: Remove when GET endpoint is ready
export const DocumentTypes = {
    ResidentialMeasures: "Residential Measures",
    NonResidentialMeasures: "Non-residential Measures",
    TableOfContents: "Table of Contents",
    Other: "Other",
} as const;

export const CostTypes = {
    Incremental: "Incremental",
    Install: "Install",
    Baseline: "Baseline",
    Program: "Program",
    Unspecified: "Unspecified",
} as const;

export const LookupVariants = {
    Value: "Value",
    Equation: "Equation",
} as const;

const strategy = {
    Deemed: "Deemed",
    PartiallyDeemed: "Partially Deemed",
    Calculated: "Calculated",
    Lookup: "Lookup",
    UserDefined: "User Defined",
    InputRequired: "Input Required",
    InputAllowed: "Input Allowed, Default Provided",
    Binary: "Binary",
    Equation: "Equation",
    MissingFromTrm: "Missing From TRM",
};

export const AlgorithmStrategy = (({ Deemed, PartiallyDeemed, Calculated }) => ({ Deemed, PartiallyDeemed, Calculated }) as const)(
    strategy,
);
export const CostStrategy = (({ Deemed, Lookup, UserDefined, Equation }) => ({ Deemed, Lookup, UserDefined, Equation }) as const)(strategy);
export const EulStrategy = (({ Deemed, Lookup, UserDefined, Equation }) => ({ Deemed, Lookup, UserDefined, Equation }) as const)(strategy);
export const VarStrategy = (({ Deemed, Lookup, InputRequired, InputAllowed, Binary, Equation, MissingFromTrm }) =>
    ({
        Deemed,
        Lookup,
        InputRequired,
        InputAllowed,
        Binary,
        Equation,
        MissingFromTrm,
    }) as const)(strategy);

// Order here is important
const allFuelTypeUnits = [
    "MWh",
    "kWh",
    "Wh",
    "MW (Summer)",
    "kW (Summer)",
    "W (Summer)",
    "MW (Winter)",
    "kW (Winter)",
    "W (Winter)",
    "MW",
    "kW",
    "W",
    "Therm",
    "MCF",
    "MMBTU",
    "CCF",
    "CF",
    "BTU",
    "Dth",
    "Peak Therm",
    "Peak MCF",
    "Peak MMBTU",
    "Peak CCF",
    "Peak CF",
    "Peak BTU",
    "Gal",
    "kGal",
    "lb",
    "1000-lb",
    "Ton",
    "MMBtu",
];

export const fuelTypeUnits = allFuelTypeUnits.reduce((acc, unit) => {
    const index = allFuelTypeUnits.findIndex((u) => u === unit);

    if (index !== -1) {
        acc[unit] = index + 1;
    }

    return acc;
}, {});
