import cn from "classnames";

export const Skeleton: React.FC<SkeletonProps> = (props) => {
    const { className, backgroundClassName = "bg-theme-base", ...rest } = props;

    return <div className={cn("animate-pulse rounded-md", className, backgroundClassName)} {...rest} />;
};

type SkeletonProps = React.HTMLAttributes<HTMLDivElement> & {
    backgroundClassName?: string;
};
