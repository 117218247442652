import { isEmpty, isNil, isNumber } from "lodash";
import { createRef, useMemo, useState } from "react";

import {
    IdsButton,
    IdsButtonGroup,
    IdsCheckbox,
    IdsDropdown,
    IdsHelper,
    IdsTable,
    IdsTableCell,
    IdsTableRow,
    IdsTag,
    IdsTextInput,
} from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";
import { NothingFoundBlock } from "components/NothingFoundBlock";
import { Tile } from "components/Tile";
import { Tooltip } from "components/Tooltip";

import { VariableEdit } from "pages/ExploreTrms/Variables/VariableEdit";
import { VariableDelete } from "pages/ExploreTrms/Variables/VariableDelete";
import { getAssignedValue, getVariableDescription, findEquation, useMeasureDetails } from "pages/ExploreTrms/utils";

import { VariableMapping } from "sidebars/VariableMapping";

import { endpoints, useUpdateSavingsCalculationMutation } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";

import { AssumptionsRights, VarStrategy as Strategy } from "utils/constants";
import {
    arrowProgressRegularIcon,
    circleExclamationLightIcon,
    circlePlusRegularIcon,
    fileExportRegularIcon,
    linkSimpleRegularIcon,
    penToSquareRegularIcon,
    subtitlesSlashSolidIcon,
    trashRegularIcon,
} from "utils/icons";
import { formatAlgorithmText, formatNumber, formatVariableText, sanitizeAndSetNumericInput } from "utils/string";
import { hasRights, isAdminUser } from "utils/user";

import type { MeasureAlgorithm, AlgorithmVariable, Variable } from "types";

export const VariablesTile: React.FC<{
    selectedMeasure: string;
    algorithm?: MeasureAlgorithm;
    assignedSavingsCalc?: string;
    variableValues: Variable[];
    tempAssignedCalculation?: string;
    algorithmAssumptions?: AlgorithmVariable[];
    isLoading: boolean;
    isFetching: boolean;
    isError: boolean;
    setAssignedSavingsCalc: (assignedSavingsCalc: string | undefined) => void;
    setVariableValues: (variableValues: Variable[]) => void;
    onExport: (variables: AlgorithmVariable[]) => void;
    readOnly?: boolean;
    compact?: boolean;
}> = ({
    selectedMeasure,
    algorithm,
    assignedSavingsCalc,
    variableValues,
    algorithmAssumptions,
    isLoading,
    isFetching,
    isError,
    setAssignedSavingsCalc,
    setVariableValues,
    onExport,
    readOnly,
    compact,
}) => {
    const dispatch = useAppDispatch();

    const [assumptionForEdit, setAssumptionForEdit] = useState<AlgorithmVariable>();
    const [assumptionForDelete, setAssumptionForDelete] = useState<AlgorithmVariable>();
    const [assumptionForMapping, setAssumptionForMapping] = useState<AlgorithmVariable>();
    const [isAdding, setIsAdding] = useState(false);
    const [showAll, setShowAll] = useState(true);

    const measure = useMeasureDetails(selectedMeasure);

    const [updateSavingsCalculation, updateSavingsCalculationStatus] = useUpdateSavingsCalculationMutation();

    const showFilter = (algorithmAssumptions ?? []).some((item) => !item.isFoundInAlgorithm);
    const editRights = hasRights(AssumptionsRights) && !readOnly;
    const allVariablesSet = variableValues
        .filter((variable) => variable.isFoundInAlgorithm)
        .every(
            (variable) =>
                !isEmpty(variable.userInput) ||
                isNumber(variable.lookupValue) ||
                !isEmpty(variable.lookupEquation) ||
                isNumber(variable.assignedValue),
        );

    const [additionalDetails, setAdditionalDetails] = useState<
        {
            id: number;
            lookupNumber: string;
        }[]
    >([]);

    const [userInputs, setUserInputs] = useState<
        {
            id: number;
            userInput?: string;
            errorMessage?: string;
        }[]
    >([]);

    const tableData = useMemo(
        () =>
            isEmpty(algorithm) || isEmpty(measure) ? [] : (algorithmAssumptions ?? []).filter((item) => showAll || item.isFoundInAlgorithm),
        [algorithm, measure, algorithmAssumptions, showAll],
    );

    const showEmptyState = isEmpty(tableData) && !isLoading && !isError && !isEmpty(measure);

    const inputRefs = useMemo(() => tableData.map(() => createRef<HTMLInputElement>()), [tableData]);

    const showVariablesErrorMessage = useMemo(
        () => !isEmpty(algorithm) && tableData.some((item) => !isEmpty(item.additionalDetails) || item.overrideAllowed),
        [algorithm, tableData],
    );

    if (!isEmpty(tableData) && tableData.some((item) => item.strategy === Strategy.Binary) && !isFetching && isEmpty(userInputs)) {
        const newUserInputs = tableData
            .filter((item) => item.strategy === Strategy.Binary)
            .map((filteredItem) => ({
                id: filteredItem.id,
                userInput: "1",
            }));

        setUserInputs(newUserInputs);
    }

    if (isFetching && !isEmpty(userInputs)) {
        setUserInputs([]);
    }

    if (isFetching && !isEmpty(additionalDetails)) {
        setAdditionalDetails([]);
    }

    const onAdditionalDetailsChange = async (id: number, assumptionNumber: string, lookupNumber?: string) => {
        setAdditionalDetails((prev) => {
            if (!lookupNumber) {
                return prev.filter((detail) => detail.id !== id);
            }

            const index = prev.findIndex((detail) => detail.id === id);
            if (index === -1) {
                return [...prev, { id, lookupNumber }];
            }

            return prev.map((detail, i) => (i === index ? { id, lookupNumber } : detail));
        });

        try {
            // Lookup is selected
            if (lookupNumber) {
                const lookupResponse = await dispatch(endpoints.getLookup.initiate({ lookupNumber }));

                if (lookupResponse.status === "fulfilled") {
                    const { lookupValue, lookupEquation } = lookupResponse.data;
                    let newVariableValues: Variable[] = [...variableValues];

                    // Lookup can either have lookup value or lookup equation
                    if (lookupValue) {
                        newVariableValues = newVariableValues.map((variable) =>
                            variable.id === id ? { ...variable, lookupValue } : variable,
                        );
                    } else if (lookupEquation && algorithm && assignedSavingsCalc && !updateSavingsCalculationStatus.isLoading) {
                        let assignedCalculation = assignedSavingsCalc;
                        const variable = newVariableValues.find((variable) => variable.id === id);

                        // If variable already had lookup equation selected,
                        // need to replace lookup equation with variable
                        // before selecting new lookup equation
                        if (variable?.lookupEquation) {
                            assignedCalculation = revertLookupEquation(
                                assignedSavingsCalc,
                                id,
                                variable.variable,
                                variable.lookupEquation,
                                variableValues,
                            );
                        }

                        newVariableValues = newVariableValues.map((variable) =>
                            variable.id === id ? { ...variable, lookupEquation } : variable,
                        );

                        const model = {
                            assumptionNumber,
                            lookupNumber,
                            algorithmNumber: algorithm.algorithmNumber,
                            assignedCalculation,
                        };

                        // Updates savings calculation expression with variable
                        // being replaced by lookup equation
                        const savingsCalcResponse = await updateSavingsCalculation({ model }).unwrap();

                        setAssignedSavingsCalc(savingsCalcResponse.assignedSavingsCalculation);
                    }

                    setVariableValues(newVariableValues);
                }
            }
            // Lookup is deselected
            else {
                const variable = variableValues.find((variable) => variable.id === id);

                if (variable?.lookupEquation && assignedSavingsCalc) {
                    const newAssignedSavingsCalc = revertLookupEquation(
                        assignedSavingsCalc,
                        id,
                        variable.variable,
                        variable.lookupEquation,
                        variableValues,
                    );

                    setAssignedSavingsCalc(newAssignedSavingsCalc);
                }

                const newVariableValues = [...variableValues].map((variable) =>
                    variable.id === id ? { ...variable, lookupValue: undefined, lookupEquation: undefined } : variable,
                );

                setVariableValues(newVariableValues);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onUserInputCheckboxChange = (id: number, value: boolean) => {
        const userInput = value ? "1" : "0";

        setUserInputs((prev) => prev.map((input) => (input.id === id ? { id, userInput } : input)));

        const newVariableValues = [...variableValues].map((variable) => (variable.id === id ? { ...variable, userInput } : variable));

        setVariableValues(newVariableValues);
    };

    const onUserInputNumberChange = (variable: AlgorithmVariable, value: string, inputIndex: number) => {
        const { id, assignedValue_Min, assignedValue_Max } = variable;

        const userInput = sanitizeAndSetNumericInput(value, inputRefs[inputIndex], { allowNegative: true });
        let errorMessage = "";

        if (userInput) {
            // These values are not a valid number: "-", "-0", "-0.", "0.", "0.0 .. 0", "-00", "00"
            if (userInput.match(/(-$)|(-0$)|(-?0\.$)|(\.0*$)|(^-?0\d)/g)) {
                errorMessage = `${formatVariableText(variable.variable)} value is not a valid number.`;
            } else if (assignedValue_Min !== null && Number(userInput) < assignedValue_Min) {
                errorMessage = `${formatVariableText(variable.variable)} value is less than allowed minimum value.`;
            } else if (assignedValue_Max !== null && Number(userInput) > assignedValue_Max) {
                errorMessage = `${formatVariableText(variable.variable)} value is greater than allowed maximum value.`;
            }
        }

        setUserInputs((prev) => {
            const index = prev.findIndex((input) => input.id === id);

            if (index === -1) {
                return [...prev, { id, userInput, errorMessage }];
            }

            return prev.map((input, i) => (i === index ? { id, userInput, errorMessage } : input));
        });

        if (isEmpty(errorMessage)) {
            const newVariableValues = [...variableValues].map((variable) => (variable.id === id ? { ...variable, userInput } : variable));

            setVariableValues(newVariableValues);
        }
    };

    const getInputValue = (variable: AlgorithmVariable) => {
        const selectedInput = userInputs.find((input) => input.id === variable.id);
        return !isNil(selectedInput?.userInput) ? String(selectedInput.userInput) : (variable.userInput ?? undefined);
    };

    const getInputError = (id: number) => {
        return !isEmpty(userInputs.find((input) => input.id === id)?.errorMessage ?? "");
    };

    const getCellStyle = (variable: AlgorithmVariable) => {
        if (!variable.isFoundInAlgorithm) {
            return {
                minWidth: 0,
                backgroundColor: "var(--ids-semantic-background-color-neutral-subtlest-default)",
                color: "var(--ids-semantic-ink-color-neutral-subtlest)",
            };
        }
        return {
            backgroundColor: "var(--ids-semantic-background-color-neutral-subtle-default)",
            color: "var(--ids-semantic-ink-color-neutral-subtle-onlight)",
            minWidth: 0,
        };
    };

    const getInputStyle = (variable: AlgorithmVariable) => {
        const hasAssignedValue = isNumber(variable.assignedValue);
        const hasUserInput = !isEmpty(getInputValue(variable));
        const hasLookup = !isEmpty(additionalDetails.find((detail) => detail.id === variable.id));
        const isFoundInAlgorithm = variable.isFoundInAlgorithm;
        const hasUserInputError = getInputError(variable.id);

        return (hasAssignedValue || hasUserInput || hasLookup || !isFoundInAlgorithm) && !hasUserInputError
            ? undefined
            : {
                  "--ids-input-field-border-color-default": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-input-field-border-color-hover": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-input-field-focus-ring-color-default": "var(--ids-semantic-focus-ring-color-critical)",
                  "--ids-input-field-text-color-filled": "var(--ids-semantic-ink-color-critical-subtlest)",
              };
    };

    const getAdditionalDetailsStyle = (variable: AlgorithmVariable) => {
        const defaultStyle = { width: "auto" };
        const hasUserInput = !isEmpty(getInputValue(variable));
        const hasLookup = additionalDetails.find((detail) => detail.id === variable.id) !== undefined;
        const isFoundInAlgorithm = variable.isFoundInAlgorithm;

        return hasUserInput || hasLookup || !isFoundInAlgorithm
            ? defaultStyle
            : {
                  ...defaultStyle,
                  "--ids-dropdown-field-border-color-default": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-dropdown-field-border-color-hover": "var(--ids-semantic-border-color-critical-subtlest)",
                  "--ids-dropdown-field-focus-ring-color-default": "var(--ids-semantic-focus-ring-color-critical)",
              };
    };

    const onStandardizeVariable = (variable: AlgorithmVariable) => {
        setAssumptionForMapping(variable);
    };

    return (
        <Tile
            title="Variables and Assumptions"
            action={
                <div className="flex-row gap-4">
                    {showFilter && (
                        <IdsCheckbox
                            idValue="measure-variables-filter"
                            label="Show All Variables"
                            defaultChecked={showAll}
                            clickHandler={(e: any) => setShowAll(e.target.checked)}
                        />
                    )}
                    <div className="flex-row gap-2">
                        {!isEmpty(algorithmAssumptions) && (
                            <Tooltip message="Copy to clipboard and paste into Excel." placement="top">
                                <IdsButton padding="sm" variant="secondary" clickHandler={() => onExport(algorithmAssumptions ?? [])}>
                                    <div className="flex-row gap-2 align-center">
                                        <FontAwesomeIcon icon={fileExportRegularIcon} fixedWidth />
                                        Export CSV
                                    </div>
                                </IdsButton>
                            </Tooltip>
                        )}
                        {editRights && !isEmpty(algorithm) && (
                            <IdsButton padding="sm" variant="secondary" clickHandler={() => setIsAdding(true)}>
                                <div className="flex-row gap-2 align-center">
                                    <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                                    Add Variable
                                </div>
                            </IdsButton>
                        )}
                    </div>
                </div>
            }
        >
            <div className="p-3">
                {/* Add Assumption */}
                {isAdding && measure && algorithm && (
                    <VariableEdit measure={measure} algorithm={algorithm} onClose={() => setIsAdding(false)} />
                )}

                {/* Edit Assumption */}
                {assumptionForEdit && measure && algorithm && (
                    <VariableEdit
                        measure={measure}
                        assumption={assumptionForEdit}
                        algorithm={algorithm}
                        onClose={() => setAssumptionForEdit(undefined)}
                    />
                )}

                {/* Delete Assumption */}
                {assumptionForDelete && measure && algorithm && (
                    <VariableDelete
                        measure={measure}
                        assumption={assumptionForDelete}
                        algorithm={algorithm}
                        onClose={() => setAssumptionForDelete(undefined)}
                    />
                )}

                {/* Standardize Variable */}
                {assumptionForMapping && measure && algorithm && (
                    <VariableMapping
                        assumptionForMapping={assumptionForMapping}
                        trmFamiliarName={measure.trmFamiliarName}
                        measureName={measure.measureName}
                        sector={algorithm.sector}
                        endUse={algorithm.endUse}
                        vintage={algorithm.vintage}
                        fuelType={algorithm.fuelType}
                        onClose={() => setAssumptionForMapping(undefined)}
                    />
                )}

                {showEmptyState ? (
                    <NothingFoundBlock icon={subtitlesSlashSolidIcon} title="No Variables" message="Variables added will show here" />
                ) : (
                    <>
                        <IdsTable variant="alternate" spacing="sm">
                            <IdsTableRow rowType="table-heading-row" customClasses="sticky-top" style={{ top: -25 }}>
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="Variable"
                                    style={{ minWidth: 0, width: compact ? "15rem" : "10rem" }}
                                />
                                {!compact && <IdsTableCell cellType="table-heading-cell" heading="Description" style={{ minWidth: 0 }} />}
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="Lookup Selection"
                                    style={{ minWidth: "12rem", width: "12rem" }}
                                />
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="User Input"
                                    style={{ minWidth: "5rem", width: "7rem" }}
                                />
                                <IdsTableCell
                                    cellType="table-heading-cell"
                                    heading="Assigned Value"
                                    style={{ minWidth: 0, width: "5rem", textAlign: "center" }}
                                />
                                <IdsTableCell cellType="table-heading-cell" heading="Units" style={{ minWidth: 0, width: "5%" }} />
                                <IdsTableCell cellType="table-heading-cell" heading="Source" style={{ minWidth: 0 }} />
                                {editRights && <IdsTableCell cellType="table-heading-cell" style={{ minWidth: 0 }} />}
                            </IdsTableRow>
                            {tableData?.map((item, index) => (
                                <IdsTableRow key={item.id} rowType="table-body-row">
                                    <IdsTableCell style={getCellStyle(item)}>
                                        <div className="flex flex-col gap-1">
                                            <div>
                                                <IdsTag variant="information" size="sm">
                                                    <div dangerouslySetInnerHTML={{ __html: formatVariableText(item.variable) }} />
                                                </IdsTag>
                                            </div>
                                            {compact && (
                                                <>
                                                    <div className="text-balance">{getVariableDescription(item)}</div>
                                                    {(item.equation ||
                                                        variableValues.find((variable) => variable.id === item.id)?.lookupEquation) && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: formatAlgorithmText(
                                                                    item.equation ??
                                                                        variableValues.find((variable) => variable.id === item.id)
                                                                            ?.lookupEquation ??
                                                                        "",
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    {!compact && (
                                        <IdsTableCell style={getCellStyle(item)}>
                                            <div>{getVariableDescription(item)}</div>
                                            {(item.equation ||
                                                variableValues.find((variable) => variable.id === item.id)?.lookupEquation) && (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: formatAlgorithmText(
                                                            item.equation ??
                                                                variableValues.find((variable) => variable.id === item.id)
                                                                    ?.lookupEquation ??
                                                                "",
                                                        ),
                                                    }}
                                                />
                                            )}
                                        </IdsTableCell>
                                    )}
                                    <IdsTableCell style={{ ...getCellStyle(item), maxWidth: "12rem", verticalAlign: "top" }}>
                                        <div>
                                            {item.additionalDetails && !isEmpty(item.additionalDetails) && (
                                                <IdsDropdown
                                                    size="sm"
                                                    idValue="algorithm-variables-additional-details"
                                                    placeholder=" "
                                                    items={item.additionalDetails.map((detail) => ({
                                                        value: detail.lookupNumber,
                                                        label: detail.lookupCriteria,
                                                    }))}
                                                    changeHandler={(lookupNumber) =>
                                                        onAdditionalDetailsChange(item.id, item.assumptionNumber, lookupNumber)
                                                    }
                                                    clearHandler={() => onAdditionalDetailsChange(item.id, item.assumptionNumber)}
                                                    style={getAdditionalDetailsStyle(item)}
                                                />
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    <IdsTableCell style={{ ...getCellStyle(item), verticalAlign: "top" }}>
                                        <div>
                                            {item.overrideAllowed && (
                                                <>
                                                    {item.strategy === Strategy.Binary ? (
                                                        <IdsCheckbox
                                                            idValue={item.id.toString()}
                                                            defaultChecked={getInputValue(item) === "1"}
                                                            changeHandler={(e: any) => onUserInputCheckboxChange(item.id, e.target.checked)}
                                                        />
                                                    ) : (
                                                        <IdsTextInput
                                                            size="sm"
                                                            innerRef={inputRefs[index]}
                                                            defaultValue={getInputValue(item)}
                                                            style={getInputStyle(item)}
                                                            changeHandler={(value) => onUserInputNumberChange(item, value, index)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    <IdsTableCell style={{ ...getCellStyle(item), textAlign: "right" }}>
                                        <span>
                                            {variableValues.find((variable) => variable.id === item.id) &&
                                                formatNumber(getAssignedValue(variableValues.find((variable) => variable.id === item.id)!))}
                                        </span>
                                    </IdsTableCell>
                                    <IdsTableCell style={getCellStyle(item)}>{item.units}</IdsTableCell>
                                    <IdsTableCell style={getCellStyle(item)}>
                                        <div>
                                            {!isEmpty(item.source) && (
                                                <Tooltip message={item.source}>
                                                    <IdsTag size="sm">
                                                        <div className="flex-row gap-2 align-center">
                                                            <FontAwesomeIcon icon={linkSimpleRegularIcon} fixedWidth />
                                                            {item.sourceYear ? item.sourceYear : "-"}
                                                        </div>
                                                    </IdsTag>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </IdsTableCell>
                                    {editRights && (
                                        <IdsTableCell style={{ ...getCellStyle(item), verticalAlign: "middle" }}>
                                            <IdsButtonGroup spaceBetween="sm" position="right" customClasses="flex-no-wrap">
                                                {!item.isFoundInAlgorithm && (
                                                    <IconButton
                                                        icon={trashRegularIcon}
                                                        size="lg"
                                                        title="Delete Variable"
                                                        onClick={() => setAssumptionForDelete(item)}
                                                    />
                                                )}
                                                <IconButton
                                                    icon={penToSquareRegularIcon}
                                                    size="lg"
                                                    title="Edit Variable"
                                                    onClick={() => setAssumptionForEdit(item)}
                                                />
                                                {isAdminUser() && (
                                                    <IconButton
                                                        icon={arrowProgressRegularIcon}
                                                        size="lg"
                                                        title="Standardize Variable"
                                                        badgeVariant={item.stdVariableNumber === null ? "critical" : "success"}
                                                        onClick={() => onStandardizeVariable(item)}
                                                    />
                                                )}
                                            </IdsButtonGroup>
                                        </IdsTableCell>
                                    )}
                                </IdsTableRow>
                            ))}
                        </IdsTable>
                        {showVariablesErrorMessage && (
                            <IdsHelper
                                customClasses="pt-2"
                                helperText="Fields outlined in red require input for savings calculations."
                                helperIcon="ui-alert-alert_circle"
                                helperInvalidText="Fields outlined in red require input for savings calculations."
                                helperInvalidIcon="ui-alert-alert_circle"
                                isInvalid={!allVariablesSet}
                            />
                        )}
                        {userInputs.some((input) => !isEmpty(input.errorMessage)) &&
                            userInputs
                                .filter((input) => !isEmpty(input.errorMessage))
                                .map((filteredInput) => (
                                    <div key={`variable-error-${filteredInput.id}`} className="flex-row align-center gap-1 py-1">
                                        <FontAwesomeIcon
                                            icon={circleExclamationLightIcon}
                                            size="sm"
                                            color="var(--ids-semantic-ink-color-critical-subtlest)"
                                        />
                                        <div
                                            dangerouslySetInnerHTML={{ __html: filteredInput.errorMessage! }}
                                            style={{
                                                color: "var(--ids-semantic-ink-color-critical-subtlest)",
                                                fontSize: "var(--ids-semantic-font-font-size-helper1-fixed)",
                                            }}
                                        />
                                    </div>
                                ))}
                    </>
                )}
            </div>
        </Tile>
    );
};

/**
 * Replaces lookup equation with variable.
 * @param currentSavingsCalc - current savings calculation expression
 * @param id - ID of the variable
 * @param variable - name of the variable
 * @param lookupEquation - lookup equation set for variable
 * @param variableValues - state of variable values
 * @returns savings calculation expression with lookup equation removed from the expression
 */
const revertLookupEquation = (
    currentSavingsCalc: string,
    id: number,
    variable: string,
    lookupEquation: string,
    variableValues: Variable[],
) => {
    let assignedSavingsCalc = "";

    // There can be cases where variables use the same lookup tables
    // and therefore same lookup equations could be used
    const variablesWithSameEquation = variableValues.filter((variable) => variable.lookupEquation === lookupEquation);

    const equation = findEquation(lookupEquation, variableValues);

    // If same lookup equations are used,
    // we need a different strategy to replace the right lookup equation
    // with the variable
    if (variablesWithSameEquation.length > 1) {
        const index = variablesWithSameEquation.findIndex((variable) => variable.id === id); // with index we know which lookup equation we need to replace
        let matchIndex = 0; // match index will get incremented by each match found

        assignedSavingsCalc = currentSavingsCalc.replaceAll(`(${equation})`, (match) => {
            let result = match;

            // If match index is the same as regular index,
            // lookup equation is found and can be replaced with the variable
            if (matchIndex === index) {
                result = `<${variable}>`;
            }

            matchIndex++;

            return result;
        });
    }
    // If different lookup equations are used,
    // then we just need to replace the lookup equation
    // with the variable
    else {
        assignedSavingsCalc = currentSavingsCalc.replace(`(${equation})`, `<${variable}>`);
    }

    return assignedSavingsCalc;
};
