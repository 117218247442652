import { IdsText, IdsLink, IdsRadioButtonGroup, IdsRadioButton } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { useState } from "react";

import { Menu } from "components/Menu";
import { Tooltip } from "components/Tooltip";

import { selectorChange } from "pages/ExploreTrms/exploreTrmsSlice";
import { TrmFilters } from "pages/ExploreTrms/utils";

import { useGetPublisherTrmsQuery, useGetMeasuresByTrmQuery, useUpdateTrmStatusMutation } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";

import {
    arrowUpRightFromSquareRegularIcon,
    caretDownSolidIcon,
    chevronRightRegularIcon,
    circleCheckSolidIcon,
    circleMinusSolidIcon,
    gearRegularIcon,
} from "utils/icons";

import type { PublisherTrms, TrmDetails } from "types";

export const TrmStatusMenu: React.FC<{
    selectedTrm: TrmDetails;
    selectedMeasure?: string;
}> = ({ selectedTrm, selectedMeasure }) => {
    const dispatch = useAppDispatch();

    const [activeTrm, setActiveTrm] = useState<PublisherTrms>();
    const [currentApplicableYear, setCurrentApplicableYear] = useState(selectedTrm.applicableYear);

    const { data: publisherTrms, isLoading: isLoadingPublisherTrms } = useGetPublisherTrmsQuery({
        publisherNumber: selectedTrm.publisherNumber,
    });

    const { data: measures } = useGetMeasuresByTrmQuery({ trmNumber: activeTrm?.trmNumber ?? "" }, { skip: activeTrm === undefined });

    const [updateTrmStatus, updateTrmStatusStatus] = useUpdateTrmStatusMutation();

    let tooltipMessage = "";
    let url = "";

    if (!activeTrm && publisherTrms && !isEmpty(publisherTrms)) {
        const activeTrm = publisherTrms.find((trm) => trm.active);

        setActiveTrm(activeTrm);
    }

    if (!selectedTrm.active && activeTrm) {
        const measure = measures?.find((measure) => measure.measureNumber === selectedMeasure);

        tooltipMessage = selectedMeasure
            ? "The selected measure is not available in the active version. You will be redirected to the TRM page."
            : "You will be redirected to the TRM page.";
        url = `${window.location.protocol}//${window.location.host}/${activeTrm.trmNumber}`;

        if (measure) {
            tooltipMessage = `Version ${activeTrm.applicableYear} / ${measure.measureName}`;
            url += `/${measure.measureNumber}`;
        }
    }

    const onSave = async () => {
        // Do nothing if either saving is in progress or TRMs have not loaded
        if (updateTrmStatusStatus.isLoading || !publisherTrms) {
            return;
        }

        const newActiveTrmNumber = publisherTrms.find((trm) => trm.applicableYear === currentApplicableYear)?.trmNumber;

        if (!newActiveTrmNumber) {
            return;
        }

        const response = await updateTrmStatus({
            activeTrmNumber: newActiveTrmNumber,
            currentTrmNumber: selectedTrm.trmNumber,
            publisherNumber: selectedTrm.publisherNumber,
            trmStatus: {
                active: true,
            },
        }).unwrap();

        if (response.responseStatus === "success") {
            // If active TRM updated to inactive, change TRMs and measures filter
            // to "Show All"
            // If inactive TRM updated to active, change TRMs and measures filter
            // to "Show Active"
            const filter = selectedTrm.active ? TrmFilters.All : TrmFilters.Active;

            dispatch(selectorChange({ selector: "filter", value: filter, persist: true }));
        }
    };

    if (isLoadingPublisherTrms) {
        return null;
    }

    return (
        <div className="flex-row align-center gap-2">
            <IdsText customClasses="text-secondary" size="md" weight="bold">
                Status
            </IdsText>
            <Menu
                id="trm-status-menu"
                buttonLabel={selectedTrm.active ? "Active" : "Inactive"}
                buttonIconAfter={caretDownSolidIcon}
                buttonColor={selectedTrm.active ? "system-success" : "neutral"}
                width={248}
            >
                <div className="flex-column gap-3">
                    {selectedTrm.applicableYear !== null && !selectedTrm.active && (
                        <div className="flex-column gap-3">
                            <IdsText customClasses="text-secondary" size="md" weight="bold">
                                Currently browsing
                            </IdsText>
                            <div className="flex-row align-center gap-2">
                                <FontAwesomeIcon className="icon-secondary" icon={circleMinusSolidIcon} />
                                <IdsText>{selectedTrm.applicableYear}</IdsText>
                            </div>
                        </div>
                    )}
                    <div className="flex-column gap-3">
                        <IdsText customClasses="text-secondary" size="md" weight="bold">
                            Active version
                        </IdsText>
                        <div className="flex-row align-center justify-space-between gap-1">
                            <div className="flex-row align-center gap-2">
                                <FontAwesomeIcon icon={circleCheckSolidIcon} color="var(--theme-feedback-success)" />
                                <IdsText>{activeTrm?.applicableYear}</IdsText>
                            </div>
                            {!selectedTrm.active && (
                                <Tooltip message={tooltipMessage} placement="right">
                                    <IdsLink href={url} target="_blank" isInline size="md">
                                        <FontAwesomeIcon icon={arrowUpRightFromSquareRegularIcon} size="lg" />
                                    </IdsLink>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                    <Menu
                        id="trm-status-menu"
                        buttonLabel="Change active version"
                        buttonIconBefore={gearRegularIcon}
                        buttonIconAfter={chevronRightRegularIcon}
                        buttonVariant="tertiary"
                        buttonPadding="sm"
                        buttonIsDisabled={!publisherTrms || publisherTrms.length === 1}
                        buttonFullWidth
                        actionLabel="Save"
                        width={196}
                        isSubMenu
                        onAction={onSave}
                        onClose={() => setCurrentApplicableYear(selectedTrm.applicableYear)}
                    >
                        <div className="flex-column gap-1">
                            <div>
                                <IdsText customClasses="text-secondary" size="md" weight="bold">
                                    Select to activate
                                </IdsText>
                            </div>
                            <div className="with-scroll" style={{ maxHeight: 340, width: "calc(100% + 1rem)" }}>
                                <IdsRadioButtonGroup>
                                    {publisherTrms?.map((trm) => (
                                        <IdsRadioButton
                                            key={trm.applicableYear}
                                            idValue={`${trm.applicableYear}`}
                                            name="applicable-year"
                                            label={`${trm.applicableYear}`}
                                            defaultChecked={trm.applicableYear === currentApplicableYear}
                                            clickHandler={() => setCurrentApplicableYear(trm.applicableYear)}
                                        />
                                    ))}
                                </IdsRadioButtonGroup>
                            </div>
                        </div>
                    </Menu>
                </div>
            </Menu>
        </div>
    );
};
