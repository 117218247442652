import { IdsLink, IdsText } from "@emergn-infinity/ids-react";

export const Header: React.FC<{
    onClose: () => void;
}> = ({ onClose }) => {
    return (
        <div className="flex flex-col px-5 py-4 gap-5 col-span-full">
            <IdsLink onClick={onClose} iconLeft="ui-navigation-arrow_left" size="md" variant="brand">
                Back to Explore TRM
            </IdsLink>
            <div className="flex flex-col gap-2">
                <IdsText weight="bold" component="h2">
                    Sectors
                </IdsText>
                <IdsText customClasses="text-secondary">
                    Explore the full TRM document and all relevant information for the selected sector.
                </IdsText>
            </div>
        </div>
    );
};
