import cn from "classnames";
import { isEmpty } from "lodash";
import { useContext, useMemo } from "react";

import { IdsDropdown, IdsText, IdsCheckbox } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tile } from "components/Tile";

import { BenchmarkTrmContext } from "pages/BenchmarkTrm";
import { selectorChange } from "pages/BenchmarkTrm/benchmarkTrmSlice";

import { useGetMeasuresByTrmQuery, useGetTrmsQuery } from "store/apiSlice";
import { useAppDispatch } from "store/hooks";

import { checkLightIcon, cloudBoltSunIcon, xmarkLightIcon } from "utils/icons";

import type { RelevantAlgorithm } from "types";

const SAVINGS_CALCULATIONS_LIMIT = 5;

export const Sidebar: React.FC<{
    selectedTrm: string;
    selectedMeasure: string;
    selectedSector: string;
    selectedVintage: string;
    selectedFuelType: string;
    selectedRelAlgorithms: RelevantAlgorithm[];
    selectTrmRef: React.RefObject<HTMLIdsDropdownElement>;
    isFetching: boolean;
    relAlgorithms?: RelevantAlgorithm[];
    isSavingsCalculationsTab: boolean;
}> = ({ selectedTrm, selectedMeasure, selectedRelAlgorithms, selectTrmRef, isFetching, relAlgorithms, isSavingsCalculationsTab }) => {
    const dispatch = useAppDispatch();

    const { setIsSelecting } = useContext(BenchmarkTrmContext);

    const { data: trms, isLoading: isLoadingTrms } = useGetTrmsQuery({ status: true });

    const { data: measures, isLoading: isLoadingMeasures } = useGetMeasuresByTrmQuery(
        { trmNumber: selectedTrm, measureRelated: true },
        { skip: isEmpty(selectedTrm) },
    );

    const trmList = useMemo(() => {
        return (trms ?? []).map((trm) => ({
            value: trm.trmNumber,
            label: trm.trmFamiliarName,
        }));
    }, [trms]);

    const measureList = useMemo(() => {
        return (measures ?? []).map((measure) => ({
            value: measure.measureNumber,
            label: measure.measureName,
        }));
    }, [measures]);

    const onMeasureChange = (value: string) => {
        if (isEmpty(value)) {
            dispatch(selectorChange({ selector: "measure", value, label: "" }));
        } else {
            dispatch(
                selectorChange({
                    selector: "measure",
                    value,
                    label: measures?.find((measure) => measure.measureNumber === value)?.measureName,
                }),
            );
        }

        setIsSelecting?.(false);
    };

    return (
        <div className="flex-column fill-height">
            <div className="flex-column gap-3 pb-4">
                <div className="flex-column gap-2">
                    <IdsText customClasses="sidebar-title" weight="bold">
                        Select TRM & Measure
                    </IdsText>
                    <IdsDropdown
                        ref={selectTrmRef}
                        idValue="select-trm"
                        isSearchable={!isLoadingTrms} // A hack to properly preselect the dropdown value
                        items={trmList}
                        initialSelectedItems={selectedTrm ? [selectedTrm] : []}
                        placeholder={isLoadingTrms ? "Loading" : "Type to Search"}
                        changeHandler={(value) =>
                            dispatch(
                                selectorChange({
                                    selector: "trm",
                                    value,
                                    label: trms?.find((trm) => trm.trmNumber === value)?.trmFamiliarName,
                                }),
                            )
                        }
                        clearHandler={() => dispatch(selectorChange({ selector: "trm", value: "", label: "" }))}
                    />
                    <IdsDropdown
                        key={`measure-dropdown-${selectedTrm}`}
                        idValue="select-measure"
                        isSearchable={!isLoadingMeasures} // A hack to properly preselect the dropdown value
                        isDisabled={isEmpty(selectedTrm)}
                        items={measureList}
                        initialSelectedItems={selectedMeasure ? [selectedMeasure] : []}
                        placeholder={isLoadingMeasures ? "Loading" : "Type to Search"}
                        changeHandler={(value) => onMeasureChange(value)}
                        clearHandler={() => onMeasureChange("")}
                    />
                </div>
            </div>

            {!isEmpty(selectedTrm) && !isEmpty(selectedMeasure) && relAlgorithms !== undefined && !isFetching && (
                <Tile
                    className="flex-one-in-column"
                    bodyClassName="h-0 with-scroll"
                    title="Compare with"
                    itemCount={relAlgorithms.length}
                    counter={selectedRelAlgorithms.length}
                    counterLimit={isSavingsCalculationsTab ? SAVINGS_CALCULATIONS_LIMIT : undefined}
                >
                    {relAlgorithms.map((algorithm, index) => (
                        <div
                            key={`relevant-algorithm-${algorithm.algorithmNumber}`}
                            className={cn("flex-row px-3 py-2 gap-2 align-center", {
                                "border-bottom": index !== relAlgorithms.length - 1,
                            })}
                        >
                            <IdsCheckbox
                                idValue={algorithm.algorithmNumber}
                                hideLabel
                                defaultChecked={
                                    selectedRelAlgorithms.find((a) => a.algorithmNumber === algorithm.algorithmNumber) !== undefined
                                }
                                changeHandler={() =>
                                    dispatch(
                                        selectorChange({
                                            selector: "relevantAlgorithms",
                                            value: algorithm,
                                            limit: isSavingsCalculationsTab ? SAVINGS_CALCULATIONS_LIMIT : undefined,
                                        }),
                                    )
                                }
                                customClasses="no-shrink"
                                style={{
                                    width: "calc(var(--ids-checkbox-input-size) + var(--ids-checkbox-border-width))",
                                }}
                            />
                            <div className="flex-column flex-grow gap-1">
                                <div>
                                    <IdsText weight="bold" size="sm">
                                        {algorithm.trmFamiliarName}
                                    </IdsText>
                                    <IdsText weight="bold" size="sm">
                                        {algorithm.measure}
                                    </IdsText>
                                </div>
                                <div>
                                    <IdsText size="sm">{algorithm.algorithmDescr}</IdsText>
                                </div>
                            </div>
                            <div
                                className={cn("flex-row align-center gap-1 px-2 py-1 rounded-pill")}
                                title={`This TRM ${
                                    algorithm.isSameClimateZone ? "represents" : "DOES NOT represent"
                                } the same ASHRAE Climate Zone as ${trms?.find((trm) => trm.trmNumber === selectedTrm)?.trmFamiliarName ?? ""}`}
                                style={{
                                    backgroundColor: algorithm.isSameClimateZone
                                        ? "var(--ids-core-color-brand-e-50)"
                                        : "var(--ids-core-color-system-a-50)",
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={cloudBoltSunIcon}
                                    color={
                                        algorithm.isSameClimateZone
                                            ? "var(--ids-core-color-brand-e-800)"
                                            : "var(--ids-core-color-system-a-800)"
                                    }
                                />
                                {algorithm.isSameClimateZone ? (
                                    <FontAwesomeIcon icon={checkLightIcon} size="xs" color="var(--ids-core-color-brand-e-800)" />
                                ) : (
                                    <FontAwesomeIcon icon={xmarkLightIcon} size="xs" color="var(--ids-core-color-system-a-800)" />
                                )}
                            </div>
                        </div>
                    ))}
                </Tile>
            )}
        </div>
    );
};
