import { MeasureAlgorithm, Variable, AlgorithmVariable } from "types";
import { MeasureLivesTile } from "./MeasureLives/MeasureLivesTile";
import { MeasureCostsTile } from "./MeasureCosts/MeasureCostsTile";
import { AlgorithmsTile } from "./Algorithms/AlgorithmsTile";
import { VariablesTile } from "./Variables/VariablesTile";
import { SavingsCalculationTile } from "./SavingsCalculationTile";
import { copyVariablesToClipboard } from "./utils";
import { useState } from "react";
import { isEmpty } from "lodash";
import { useGetAlgorithmAssumptionsQuery } from "store/apiSlice";
import { useAppSelector } from "store/hooks";

export const MeasureTiles: React.FC<{
    compact?: boolean;
    sectorNumber?: string;
    readOnly?: boolean;
}> = ({ compact = false, sectorNumber, readOnly = false }) => {
    const { selectedMeasure } = useAppSelector((state) => state.exploreTrms);

    const [selectedAlgorithm, setSelectedAlgorithm] = useState<MeasureAlgorithm>();
    const [assignedSavingsCalc, setAssignedSavingsCalc] = useState<string>();
    const [variableValues, setVariableValues] = useState<Variable[]>([]);

    const {
        data: algorithmAssumptionsData,
        isFetching: isFetchingAlgorithmAssumptions,
        isLoading: isLoadingAlgorithmAssumptions,
        isError: isErrorAlgorithmAssumptions,
    } = useGetAlgorithmAssumptionsQuery(
        { algorithmNumber: selectedAlgorithm?.algorithmNumber! },
        { skip: !selectedAlgorithm?.algorithmNumber, refetchOnMountOrArgChange: true },
    );
    const algorithmAssumptions = algorithmAssumptionsData?.assumptionList;

    if (algorithmAssumptionsData && !isFetchingAlgorithmAssumptions && !assignedSavingsCalc) {
        setAssignedSavingsCalc(algorithmAssumptionsData.assignedSavingsCalculation);
    }

    if (algorithmAssumptions && !isEmpty(algorithmAssumptions) && !isFetchingAlgorithmAssumptions && isEmpty(variableValues)) {
        const newVariableValues = algorithmAssumptions.map((assumption) => ({
            id: assumption.id,
            assignedValue: assumption.assignedValue ?? undefined,
            variable: assumption.variable,
            userInput: assumption.userInput ?? undefined,
            equation: assumption.equation ?? undefined,
            isFoundInAlgorithm: assumption.isFoundInAlgorithm,
        }));

        setVariableValues(newVariableValues);
    }

    const onAlgorithmSelect = (algorithm: MeasureAlgorithm) => {
        setSelectedAlgorithm(algorithm);
        setAssignedSavingsCalc(undefined);
        setVariableValues([]);
    };

    const onExportVariables = async (variables: AlgorithmVariable[]) => {
        await copyVariablesToClipboard(variables, variableValues);
    };

    return (
        <>
            <MeasureLivesTile selectedMeasure={selectedMeasure} sectorNumber={sectorNumber} readOnly={readOnly} />
            <MeasureCostsTile selectedMeasure={selectedMeasure} sectorNumber={sectorNumber} readOnly={readOnly} />
            <AlgorithmsTile
                selectedMeasure={selectedMeasure}
                selectedAlgorithmNumber={selectedAlgorithm?.algorithmNumber}
                onAlgorithmSelect={onAlgorithmSelect}
                sectorNumber={sectorNumber}
                readOnly={readOnly}
            />
            <VariablesTile
                selectedMeasure={selectedMeasure}
                algorithm={selectedAlgorithm}
                assignedSavingsCalc={assignedSavingsCalc}
                variableValues={variableValues}
                algorithmAssumptions={algorithmAssumptions}
                isLoading={isLoadingAlgorithmAssumptions}
                isFetching={isFetchingAlgorithmAssumptions}
                isError={isErrorAlgorithmAssumptions}
                setAssignedSavingsCalc={setAssignedSavingsCalc}
                setVariableValues={setVariableValues}
                onExport={onExportVariables}
                readOnly={readOnly}
                compact={compact}
            />
            <SavingsCalculationTile
                algorithm={selectedAlgorithm}
                savingsCalc={algorithmAssumptionsData?.savingsCalculation}
                assignedSavingsCalc={assignedSavingsCalc}
                variableValues={variableValues}
            />
        </>
    );
};
