import { useRef, useState } from "react";

import { IdsButtonGroup, IdsLabel, IdsText, IdsTextarea } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";

import { sanitizeAndSetNumericInput } from "utils/string";

import { arrowRightRegularIcon, checkLightIcon, penToSquareRegularIcon, xmarkLightIcon } from "utils/icons";

export const ChangesField: React.FC<{
    idValue: string;
    label: string;
    sourceValue: string;
    targetValue: string;
    yearInput?: boolean;
    onSave: (value: string) => void;
}> = ({ idValue, label, sourceValue, targetValue, yearInput, onSave }) => {
    const [value, setValue] = useState(targetValue);
    const [isEditing, setIsEditing] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const valuesDiffer = sourceValue !== targetValue;

    const onChange = (value: string) => {
        let newValue = value;

        if (yearInput) {
            newValue = sanitizeAndSetNumericInput(value, inputRef, { allowDecimals: false, maxLength: 4 }) ?? "";
        }

        setValue(newValue);
    };

    const onSaveClick = () => {
        setIsEditing(false);

        onSave(value);
    };

    return (
        <div>
            <IdsLabel>{label}</IdsLabel>
            <div className="flex-row align-center border-bottom px-2 py-3">
                <div className="flex-row align-center fill-width">
                    {valuesDiffer && (
                        <>
                            <div style={{ width: "50%" }}>
                                <IdsText
                                    style={{
                                        color: "var(--ids-semantic-ink-color-brand-b-subtlest)",
                                        textDecoration: "line-through",
                                    }}
                                >
                                    {sourceValue}
                                </IdsText>
                            </div>
                            <FontAwesomeIcon
                                className="px-4"
                                icon={arrowRightRegularIcon}
                                color="var(--ids-semantic-ink-color-brand-b-subtlest)"
                            />
                        </>
                    )}
                    <div style={{ width: valuesDiffer ? "50%" : "100%" }}>
                        {isEditing ? (
                            <IdsTextarea innerRef={inputRef} idValue={idValue} defaultValue={value} changeHandler={onChange} />
                        ) : (
                            <IdsText>{targetValue}</IdsText>
                        )}
                    </div>
                </div>
                <div className="pl-3">
                    {isEditing ? (
                        <IdsButtonGroup customClasses="flex-no-wrap" spaceBetween="md">
                            <IconButton icon={xmarkLightIcon} title="Cancel" size="lg" square onClick={() => setIsEditing(false)} />
                            <IconButton icon={checkLightIcon} title="Save" size="lg" square onClick={onSaveClick} />
                        </IdsButtonGroup>
                    ) : (
                        <IconButton icon={penToSquareRegularIcon} title="Edit" size="lg" square onClick={() => setIsEditing(true)} />
                    )}
                </div>
            </div>
        </div>
    );
};
