import { isEmpty, map } from "lodash";
import { useMemo, useRef, useState } from "react";

import { IdsCheckbox, IdsTabItem, IdsTabPanel, IdsTabs, IdsTextInput } from "@emergn-infinity/ids-react";

import { Badge } from "components/Badge";

import { Grid } from "./Grid";

import type { AttributeStaging } from "types";
import type { StepName } from "pages/ReviewUpdates";

import "./style.scss";

const ItemGridTabs = {
    AllUpdates: {
        id: "all-updates",
        name: "All Updates",
    },
    Create: {
        id: "create",
        name: "Added",
    },
    Skip: {
        id: "skip",
        name: "Removed",
    },
    Copy: {
        id: "copy",
        name: "Existing",
    },
} as const;

export const ItemGrid: React.FC<{
    attribute: StepName;
    items: AttributeStaging[];
    selectedItems: AttributeStaging[];
    setSelectedItems: (selectedItems: AttributeStaging[]) => void;
}> = ({ attribute, items, selectedItems, setSelectedItems }) => {
    const [search, setSearch] = useState("");
    const [showExisting, setShowExisting] = useState(false);
    const [activeTab, setActiveTab] = useState<Tab>(ItemGridTabs.AllUpdates.id);
    const [allGridItems, setAllGridItems] = useState<AttributeStaging[]>([]);

    const itemGridRef = useRef<HTMLDivElement>();

    const addedGridItems = useMemo(() => {
        return allGridItems.filter((item) => item.task === "create");
    }, [allGridItems]);

    const removedGridItems = useMemo(() => {
        return allGridItems.filter((item) => item.task === "skip");
    }, [allGridItems]);

    const [existingGridItems, existingItemsCount] = useMemo(() => {
        const existingGridItems = allGridItems.filter((item) => item.task === "copy");
        const existingItemsCount = items.filter((item) => item.task === "copy").length;

        return [existingGridItems, existingItemsCount];
    }, [items, allGridItems]);

    if (!showExisting && activeTab === ItemGridTabs.Copy.id) {
        setActiveTab(ItemGridTabs.AllUpdates.id);
    }

    let gridItems = allGridItems;

    if (activeTab === ItemGridTabs.Create.id) {
        gridItems = addedGridItems;
    } else if (activeTab === ItemGridTabs.Skip.id) {
        gridItems = removedGridItems;
    } else if (activeTab === ItemGridTabs.Copy.id) {
        gridItems = existingGridItems;
    }

    const onMount = (element: HTMLDivElement | null) => {
        if (!element || itemGridRef.current || isEmpty(items) || !isEmpty(selectedItems)) {
            return;
        }

        itemGridRef.current = element;

        const searchItems = items.filter((item) => item.name.toLowerCase().includes(search.toLowerCase().trim()));
        const gridItems = showExisting ? searchItems : searchItems.filter((item) => item.task !== "copy");

        setAllGridItems(gridItems);
        setSelectedItems(gridItems);
    };

    const getBadgeStyle = (isActive: boolean) => {
        const defaultBackgroundColor = "var(--ids-semantic-background-color-neutral-subtle-default)";
        let activeBackgroundColor = "var(--ids-semantic-background-color-brand-a-subtle-default)";

        const defaultColor = "var(--ids-semantic-ink-color-neutral-accent-onlight)";
        let activeColor = "var(--ids-semantic-ink-color-brand-a-accent)";

        if (activeTab === ItemGridTabs.Create.id) {
            activeBackgroundColor = "var(--ids-core-color-brand-e-200)";
            activeColor = "var(--ids-semantic-ink-color-success-accent)";
        } else if (activeTab === ItemGridTabs.Skip.id) {
            activeBackgroundColor = "var(--ids-semantic-background-color-critical-subtle-default)";
            activeColor = "var(--ids-semantic-ink-color-critical-accent)";
        }

        return {
            backgroundColor: isActive ? activeBackgroundColor : defaultBackgroundColor,
            color: isActive ? activeColor : defaultColor,
        };
    };

    const onTabChange = (id: Tab) => {
        setActiveTab(id);

        let items = allGridItems.filter((item) => item.task !== "copy");

        if (id === ItemGridTabs.Create.id) {
            items = addedGridItems;
        } else if (id === ItemGridTabs.Skip.id) {
            items = removedGridItems;
        }

        setSelectedItems(items);
    };

    return (
        <div ref={onMount} className="item-grid">
            <div className="flex-row align-center gap-3 pb-3">
                <IdsTextInput
                    idValue="search"
                    placeholder="Search"
                    defaultValue={search}
                    iconLeft="ui-search-search"
                    changeHandler={(value) => setSearch(value)}
                />
                {existingItemsCount > 0 && (
                    <IdsCheckbox
                        idValue="show-all"
                        label={`Show ${existingItemsCount} existing ${attribute}`}
                        defaultChecked={showExisting}
                        changeHandler={(e: any) => setShowExisting(e.target.checked)}
                    />
                )}
            </div>
            <div style={{ position: "relative" }}>
                {/* Badge for "All Updates" tab */}
                <div style={{ position: "absolute", top: "0.5rem", left: "1.025rem", zIndex: 1, pointerEvents: "none" }}>
                    <Badge
                        number={allGridItems.length}
                        withoutLimit
                        size="lg"
                        style={getBadgeStyle(activeTab === ItemGridTabs.AllUpdates.id)}
                    />
                </div>

                {/* Badge for "Added" tab */}
                <div style={{ position: "absolute", top: "0.5rem", left: "calc(142px + 1.025rem)", zIndex: 1, pointerEvents: "none" }}>
                    <Badge
                        number={addedGridItems.length}
                        withoutLimit
                        size="lg"
                        style={getBadgeStyle(activeTab === ItemGridTabs.Create.id)}
                    />
                </div>

                {/* Badge for "Removed" tab */}
                <div
                    style={{
                        position: "absolute",
                        top: "0.5rem",
                        left: "calc(142px + 107px + 1.025rem)",
                        zIndex: 1,
                        pointerEvents: "none",
                    }}
                >
                    <Badge
                        number={removedGridItems.length}
                        withoutLimit
                        size="lg"
                        style={getBadgeStyle(activeTab === ItemGridTabs.Skip.id)}
                    />
                </div>

                {/* Badge for "Existing" tab */}
                {showExisting && (
                    <div
                        style={{
                            position: "absolute",
                            top: "0.5rem",
                            left: "calc(142px + 107px + 126px + 1.025rem)",
                            zIndex: 1,
                            pointerEvents: "none",
                        }}
                    >
                        <Badge
                            number={existingGridItems.length}
                            withoutLimit
                            size="lg"
                            style={getBadgeStyle(activeTab === ItemGridTabs.Copy.id)}
                        />
                    </div>
                )}

                <IdsTabs customClasses="item-grid--tabs">
                    {map(ItemGridTabs, (tab) => (
                        <IdsTabItem
                            key={`${tab.id}-tab`}
                            slot="header"
                            idValue={tab.id}
                            label={tab.name}
                            icon="ui-template-diamond"
                            iconSize="sm"
                            isActive={activeTab === tab.id}
                            onClick={() => onTabChange(tab.id)}
                            style={{ display: tab.id === ItemGridTabs.Copy.id && !showExisting ? "none" : undefined }}
                        />
                    ))}

                    {map(ItemGridTabs, (tab) => (
                        <IdsTabPanel key={`${tab.id}-panel`} slot="panel" idValue={tab.id} style={{ backgroundColor: "inherit" }}>
                            <Grid
                                attribute={attribute}
                                items={gridItems}
                                allGridItems={allGridItems}
                                selectedItems={selectedItems}
                                isExistingTab={activeTab === ItemGridTabs.Copy.id}
                                setAllGridItems={setAllGridItems}
                                setSelectedItems={setSelectedItems}
                            />
                        </IdsTabPanel>
                    ))}
                </IdsTabs>
            </div>
        </div>
    );
};

type Tab = (typeof ItemGridTabs)[keyof typeof ItemGridTabs]["id"];
