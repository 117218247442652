import { memo, useRef } from "react";

/**
 * Memoizes children component to skip re-renders.
 */
export const MemoizeComponentWrapper: React.NamedExoticComponent<{
    active: boolean;
    children: React.ReactNode;
}> = memo(
    ({ active, children }) => {
        const viewActivatedRef = useRef(false);

        if (!viewActivatedRef.current) {
            viewActivatedRef.current = active;

            if (!active) {
                return null;
            }
        }

        return children;
    },
    (_, nextProps) => {
        return !nextProps.active;
    },
);
