import { createSlice } from "@reduxjs/toolkit";

import { saveStateInStorage, loadStateFromStorage } from "store/utils";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { RelevantAlgorithm } from "types";

const STATE_KEY = "benchmark-trm-state";

const initialState = {
    selectedTrm: "",
    selectedTrmName: "",
    selectedMeasure: "",
    selectedMeasureName: "",
    selectedSector: "",
    selectedSectorName: "",
    selectedVintage: "",
    selectedVintageName: "",
    selectedFuelType: "",
    selectedFuelTypeName: "",
};

export const benchmarkTrmSlice = createSlice({
    name: "benchmark-trm",
    initialState: {
        ...loadStateFromStorage(STATE_KEY, initialState),
        selectedRelAlgorithms: [],
        triggerSelect: true,
    } as BenchmarkTrmState,
    reducers: {
        selectorChange: (state, action: PayloadAction<SelectorChangePayload>) => {
            const { selector, value, label, limit } = action.payload;

            switch (selector) {
                case "trm":
                    state.selectedTrm = value as string;
                    state.selectedTrmName = label ?? "";

                    state.selectedMeasure = "";
                    state.selectedMeasureName = "";
                    state.selectedSector = "";
                    state.selectedVintage = "";
                    state.selectedFuelType = "";
                    state.selectedRelAlgorithms = [];

                    break;
                case "measure":
                    state.selectedMeasure = value as string;
                    state.selectedMeasureName = label ?? "";

                    state.selectedSector = "";
                    state.selectedVintage = "";
                    state.selectedFuelType = "";
                    state.selectedRelAlgorithms = [];

                    state.triggerSelect = true;

                    break;
                case "sector":
                    state.selectedSector = value as string;
                    state.selectedSectorName = label ?? "";

                    state.triggerSelect = true;

                    break;
                case "vintage":
                    state.selectedVintage = value as string;
                    state.selectedVintageName = label ?? "";

                    state.triggerSelect = true;

                    break;
                case "fuelType":
                    state.selectedFuelType = value as string;
                    state.selectedFuelTypeName = label ?? "";

                    state.triggerSelect = true;

                    break;
                case "relevantAlgorithms":
                    {
                        let relevantAlgorithms = [...state.selectedRelAlgorithms];

                        // Uncheck relevant algorithm
                        if (relevantAlgorithms.find((a) => a.algorithmNumber === (value as RelevantAlgorithm).algorithmNumber)) {
                            relevantAlgorithms = relevantAlgorithms.filter(
                                (a) => a.algorithmNumber !== (value as RelevantAlgorithm).algorithmNumber,
                            );
                        }
                        // Check relevant algorithm if limit is not reached
                        else if (relevantAlgorithms.length !== limit) {
                            relevantAlgorithms.push(value as RelevantAlgorithm);
                        }

                        state.selectedRelAlgorithms = relevantAlgorithms;
                    }

                    break;
                default:
            }

            const { selectedRelAlgorithms, triggerSelect, ...rest } = state;

            saveStateInStorage(STATE_KEY, rest);
        },
        setSelectedRelAlgorithms: (state, action: PayloadAction<RelevantAlgorithm[]>) => {
            state.selectedRelAlgorithms = action.payload;
        },
        setTrigger: (state, action: PayloadAction<boolean>) => {
            state.triggerSelect = action.payload;
        },
    },
});

type BenchmarkTrmState = {
    selectedTrm: string;
    selectedTrmName: string;
    selectedMeasure: string;
    selectedMeasureName: string;
    selectedSector: string;
    selectedSectorName: string;
    selectedVintage: string;
    selectedVintageName: string;
    selectedFuelType: string;
    selectedFuelTypeName: string;
    selectedRelAlgorithms: RelevantAlgorithm[];
    triggerSelect: boolean;
};

type SelectorChangePayload = {
    selector: string;
    value: string | RelevantAlgorithm;
    label?: string;
    limit?: number;
};

export const { selectorChange, setSelectedRelAlgorithms, setTrigger } = benchmarkTrmSlice.actions;

export default benchmarkTrmSlice.reducer;
