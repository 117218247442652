import { map } from "lodash";

import { IdsTabs, IdsTabItem, IdsTabPanel } from "@emergn-infinity/ids-react";

import { Tabs } from "pages/BenchmarkTrm/utils";

import { MeasureLives } from "./MeasureLives";
import { MeasureCosts } from "./MeasureCosts";
import { SavingsCalculations } from "./SavingsCalculations";

import type { Tab } from "pages/BenchmarkTrm";

export const MainPanel: React.FC<{
    activeTab: Tab;
    onTabChange: (id: Tab) => void;
}> = ({ activeTab, onTabChange }) => {
    return (
        <IdsTabs customClasses="flex flex-col" fullHeight>
            {map(Tabs, (tab) => (
                <IdsTabItem
                    key={`${tab.id}-tab`}
                    slot="header"
                    idValue={tab.id}
                    label={tab.name}
                    isActive={activeTab === tab.id}
                    onClick={() => onTabChange(tab.id)}
                />
            ))}

            {map(Tabs, (tab) => (
                <IdsTabPanel key={`${tab.id}-panel`} customClasses="bg-inherit" slot="panel" idValue={tab.id}>
                    {activeTab === Tabs.MeasureLives.id && <MeasureLives />}
                    {activeTab === Tabs.MeasureCosts.id && <MeasureCosts />}
                    {activeTab === Tabs.SavingsCalculations.id && <SavingsCalculations />}
                </IdsTabPanel>
            ))}
        </IdsTabs>
    );
};
