import cn from "classnames";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { IdsModal, IdsText, IdsButtonGroup, IdsButton, IdsTable, IdsTableRow, IdsTableCell, IdsTag } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EditFormTags } from "components/EditFormTags";
import { IconButton } from "components/IconButton";
import { Menu } from "components/Menu";
import { NothingFoundBlock } from "components/NothingFoundBlock";

import {
    useUpdateStandardizingsForSingleAlgorithmMutation,
    useGetFuelTypesQuery,
    useGetStdEndUsesQuery,
    useGetStdMeasuresQuery,
    useGetStdPermutationsQuery,
    useGetStdSectorsQuery,
    useGetStdVintagesQuery,
} from "store/apiSlice";

import { arrowProgressSolidIcon, circlePlusRegularIcon, copyRegularIcon, penToSquareRegularIcon, trashRegularIcon } from "utils/icons";

import { MenuContent } from "./MenuContent";
import Skeleton from "./Skeleton";

import type { Measure, MeasureAlgorithm, StdPermutation } from "types";

const ALGORITHM_MAPPING_MODAL_STYLE = {
    width: "948px",
    maxWidth: "100%",
    height: "100%",
};

const MENU_WIDTH = 420;

const style = {
    measure: {
        minWidth: 0,
        width: "100%",
    },
    sector: {
        minWidth: "10.125rem",
    },
    endUse: {
        minWidth: "7.5rem",
    },
    vintage: {
        minWidth: "8.25rem",
    },
    fuelType: {
        minWidth: "7.5rem",
    },
    actions: {
        minWidth: "5.25rem",
    },
};

let timeoutId: NodeJS.Timeout;

export const AlgorithmMappings: React.FC<{
    measure: Measure;
    algorithm: MeasureAlgorithm;
    onClose: () => void;
}> = ({ measure, algorithm, onClose }) => {
    const [stdPermutationList, setStdPermutationList] = useState<StdPermutationModel[]>([]);
    const [isPermutationListFilled, setIsPermutationListFilled] = useState(false); // flag to fill standard permutations with data from database

    const [selectedStdMeasure, setSelectedStdMeasure] = useState("");
    const [selectedStdSector, setSelectedStdSector] = useState("");
    const [selectedStdEndUse, setSelectedStdEndUse] = useState("");
    const [selectedStdVintage, setSelectedStdVintage] = useState("");
    const [selectedFuelType, setSelectedFuelType] = useState("");

    const { data: stdPermutations, isLoading: isLoadingStdPermutations } = useGetStdPermutationsQuery({
        algorithmNumber: algorithm.algorithmNumber,
    });
    const { data: stdMeasures, isLoading: isLoadingStdMeasures } = useGetStdMeasuresQuery();
    const { data: stdSectors, isLoading: isLoadingStdSectors } = useGetStdSectorsQuery();
    const { data: stdEndUses, isLoading: isLoadingStdEndUses } = useGetStdEndUsesQuery({});
    const { data: stdVintages, isLoading: isLoadingStdVintages } = useGetStdVintagesQuery();
    const { data: fuelTypes, isLoading: isLoadingFuelTypes } = useGetFuelTypesQuery();

    const [updateAlgorithmStandardizing, updateAlgorithmStandardizingStatus] = useUpdateStandardizingsForSingleAlgorithmMutation();

    const isLoading =
        isLoadingStdPermutations ||
        isLoadingStdMeasures ||
        isLoadingStdSectors ||
        isLoadingStdEndUses ||
        isLoadingStdVintages ||
        isLoadingFuelTypes;

    if (stdPermutations && !isEmpty(stdPermutations) && isEmpty(stdPermutationList) && !isPermutationListFilled) {
        setIsPermutationListFilled(true);

        setStdPermutationList(
            stdPermutations.map((permutation) => ({
                ...permutation,
                isBeingDeleted: false,
            })),
        );
    }

    useEffect(() => {
        // If permutation is marked as to be deleted,
        // remove it from the permutation list after 10 seconds
        if (stdPermutationList.some((permutation) => permutation.isBeingDeleted)) {
            const stdPermutationNumber = stdPermutationList.find((permutation) => permutation.isBeingDeleted)?.stdPermutationNumber;

            timeoutId = setTimeout(() => {
                setStdPermutationList(
                    stdPermutationList.filter((permutation) => permutation.stdPermutationNumber !== stdPermutationNumber),
                );
            }, 10000);
        }

        // Cancels timeout on every re-run
        return () => {
            clearTimeout(timeoutId);
        };
    }, [stdPermutationList]);

    const areChangesMade = useMemo(() => {
        // No changes made while loading standard permutations
        if (!stdPermutations) {
            return false;
        }

        const filteredStdPermutationList = stdPermutationList.filter((permutation) => !permutation.isBeingDeleted);
        const notEqualLength = filteredStdPermutationList.length !== stdPermutations.length;

        // Changes are made if initial standard permutations array differs
        if (notEqualLength) {
            return true;
        }

        for (const p1 of filteredStdPermutationList) {
            for (const p2 of stdPermutations) {
                // Changes are made if initial standard permutation values differ
                if (
                    p1.stdPermutationNumber === p2.stdPermutationNumber &&
                    (p1.stdMeasureNumber !== p2.stdMeasureNumber ||
                        p1.stdSectorNumber !== p2.stdSectorNumber ||
                        p1.stdEndUseNumber !== p2.stdEndUseNumber ||
                        p1.stdVintageNumber !== p2.stdVintageNumber ||
                        p1.fuelTypeNumber !== p2.fuelTypeNumber)
                ) {
                    return true;
                }
            }
        }

        return false;
    }, [stdPermutations, stdPermutationList]);

    const stdMeasureList = useMemo(() => {
        return (stdMeasures ?? []).map((measure) => ({
            label: measure.stdMeasureName,
            value: measure.stdMeasureNumber,
        }));
    }, [stdMeasures]);

    const stdSectorList = useMemo(() => {
        return (stdSectors ?? []).map((sector) => ({
            label: sector.stdSectorName,
            value: sector.stdSectorNumber,
        }));
    }, [stdSectors]);

    const stdEndUseList = useMemo(() => {
        return (stdEndUses ?? []).map((endUse) => ({
            label: endUse.stdEndUseName,
            value: endUse.stdEndUseNumber,
        }));
    }, [stdEndUses]);

    const stdVintageList = useMemo(() => {
        return (stdVintages ?? []).map((vintage) => ({
            label: vintage.stdVintageName,
            value: vintage.stdVintageNumber,
        }));
    }, [stdVintages]);

    const fuelTypeList = useMemo(() => {
        return (fuelTypes ?? []).map((ft) => ({
            label: ft.fuelType,
            value: ft.fuelTypeNumber,
        }));
    }, [fuelTypes]);

    const clearSelectedValues = () => {
        setSelectedStdMeasure("");
        setSelectedStdSector("");
        setSelectedStdEndUse("");
        setSelectedStdVintage("");
        setSelectedFuelType("");
    };

    const onMenuOpen = (item: StdPermutationModel) => {
        setSelectedStdMeasure(item.stdMeasureNumber);
        setSelectedStdSector(item.stdSectorNumber);
        setSelectedStdEndUse(item.stdEndUseNumber);
        setSelectedStdVintage(item.stdVintageNumber);
        setSelectedFuelType(item.fuelTypeNumber);
    };

    const onAddMapping = () => {
        if (!stdMeasures || !stdSectors || !stdEndUses || !stdVintages || !fuelTypes) {
            return;
        }

        const newStdPermutation = {
            stdPermutationNumber: uuidv4(),
            stdMeasureNumber: selectedStdMeasure,
            stdSectorNumber: selectedStdSector,
            stdEndUseNumber: selectedStdEndUse,
            stdVintageNumber: selectedStdVintage,
            fuelTypeNumber: selectedFuelType,
            measure: stdMeasures.find((measure) => measure.stdMeasureNumber === selectedStdMeasure)?.stdMeasureName ?? "",
            sector: stdSectors.find((sector) => sector.stdSectorNumber === selectedStdSector)?.stdSectorName ?? "",
            endUse: stdEndUses.find((endUse) => endUse.stdEndUseNumber === selectedStdEndUse)?.stdEndUseName ?? "",
            vintage: stdVintages.find((vintage) => vintage.stdVintageNumber === selectedStdVintage)?.stdVintageName ?? "",
            fuelType: fuelTypes.find((ft) => ft.fuelTypeNumber === selectedFuelType)?.fuelType ?? "",
            isBeingDeleted: false,
        };

        setStdPermutationList([...stdPermutationList, newStdPermutation]);

        toast.success("Mapping successfully added");

        clearSelectedValues();
    };

    const onEditMapping = (stdPermutationNumber: string) => {
        if (!stdMeasures || !stdSectors || !stdEndUses || !stdVintages || !fuelTypes) {
            return;
        }

        const newStdPermutationList = stdPermutationList.map((permutation) =>
            permutation.stdPermutationNumber === stdPermutationNumber
                ? {
                      ...permutation,
                      stdMeasureNumber: selectedStdMeasure,
                      stdSectorNumber: selectedStdSector,
                      stdEndUseNumber: selectedStdEndUse,
                      stdVintageNumber: selectedStdVintage,
                      fuelTypeNumber: selectedFuelType,
                      measure: stdMeasures.find((measure) => measure.stdMeasureNumber === selectedStdMeasure)?.stdMeasureName ?? "",
                      sector: stdSectors.find((sector) => sector.stdSectorNumber === selectedStdSector)?.stdSectorName ?? "",
                      endUse: stdEndUses.find((endUse) => endUse.stdEndUseNumber === selectedStdEndUse)?.stdEndUseName ?? "",
                      vintage: stdVintages.find((vintage) => vintage.stdVintageNumber === selectedStdVintage)?.stdVintageName ?? "",
                      fuelType: fuelTypes.find((ft) => ft.fuelTypeNumber === selectedFuelType)?.fuelType ?? "",
                  }
                : permutation,
        );

        setStdPermutationList(newStdPermutationList);

        toast.success("Mapping successfully updated");

        clearSelectedValues();
    };

    const onChange = (value: string, name: string) => {
        switch (name) {
            case "stdMeasure":
                setSelectedStdMeasure(value);

                break;

            case "stdSector":
                setSelectedStdSector(value);

                break;

            case "stdEndUse":
                setSelectedStdEndUse(value);

                break;

            case "stdVintage":
                setSelectedStdVintage(value);

                break;

            case "fuelType":
                setSelectedFuelType(value);

                break;
        }
    };

    const onDeleteMapping = (stdPermutationNumber: string) => {
        let newStdPermutationList = [...stdPermutationList];

        // If there already is a permutation that is marked as to be deleted,
        // remove it from the permutation list immediately
        if (newStdPermutationList.some((permutation) => permutation.isBeingDeleted)) {
            const stdPermutation = newStdPermutationList.find((permutation) => permutation.isBeingDeleted);

            if (stdPermutation) {
                newStdPermutationList = newStdPermutationList.filter(
                    (permutation) => permutation.stdPermutationNumber !== stdPermutation.stdPermutationNumber,
                );
            }
        }

        newStdPermutationList = newStdPermutationList.map((permutation) =>
            permutation.stdPermutationNumber === stdPermutationNumber ? { ...permutation, isBeingDeleted: true } : permutation,
        );

        setStdPermutationList(newStdPermutationList);
    };

    const onUndoDelete = (stdPermutationNumber: string) => {
        setStdPermutationList(
            stdPermutationList.map((permutation) =>
                permutation.stdPermutationNumber === stdPermutationNumber ? { ...permutation, isBeingDeleted: false } : permutation,
            ),
        );
    };

    const onSave = async () => {
        if (updateAlgorithmStandardizingStatus.isLoading || isEmpty(stdPermutationList)) {
            return;
        }

        try {
            const dateActivated = new Date(Date.now()).toISOString();

            const model = stdPermutationList
                .filter((permutation) => !permutation.isBeingDeleted)
                .map((permutation) => ({
                    stdMeasureNumber: permutation.stdMeasureNumber,
                    stdSectorNumber: permutation.stdSectorNumber,
                    stdEndUseNumber: permutation.stdEndUseNumber,
                    stdVintageNumber: permutation.stdVintageNumber,
                    fuelTypeNumber: permutation.fuelTypeNumber,
                    dateActivated,
                }));

            await updateAlgorithmStandardizing({ algorithmNumber: algorithm.algorithmNumber, model });

            onClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <IdsModal
            version={2}
            isOpen
            closeHandler={onClose}
            showCloseButton
            customClasses={cn("slideout", {
                "empty-state": isLoading || isEmpty(stdPermutationList),
            })}
        >
            <div slot="header">
                <IdsText size="sm" weight="bold" component="h3">
                    Standardize Algorithm
                </IdsText>
            </div>
            <div className="flex flex-col" slot="main" style={ALGORITHM_MAPPING_MODAL_STYLE}>
                {/* TODO: Remove style attribute and add "gap-6 pb-6" classes */}
                <div className="flex flex-row items-end" style={{ gap: "1.5rem", paddingBottom: "1.5rem" }}>
                    <EditFormTags
                        trmName={measure.trmFamiliarName}
                        measureName={measure.measureName}
                        algorithm={algorithm.algorithm}
                        withoutPadding
                    />
                    <Menu
                        id="algorithm-mappings-menu"
                        buttonLabel="Add Mapping"
                        buttonIconBefore={circlePlusRegularIcon}
                        buttonVariant="secondary"
                        buttonPadding="sm"
                        buttonIsDisabled={isLoading}
                        actionLabel="Add"
                        actionIsDisabled={
                            isEmpty(selectedStdMeasure) ||
                            isEmpty(selectedStdSector) ||
                            isEmpty(selectedStdEndUse) ||
                            isEmpty(selectedStdVintage) ||
                            isEmpty(selectedFuelType)
                        }
                        header="Add Mapping"
                        width={MENU_WIDTH}
                        onAction={onAddMapping}
                    >
                        <MenuContent
                            stdMeasureList={stdMeasureList}
                            stdSectorList={stdSectorList}
                            stdEndUseList={stdEndUseList}
                            stdVintageList={stdVintageList}
                            fuelTypeList={fuelTypeList}
                            onChange={onChange}
                        />
                    </Menu>
                </div>
                {isLoading && <Skeleton />}

                {!isLoading && isEmpty(stdPermutationList) && (
                    <NothingFoundBlock
                        icon={arrowProgressSolidIcon}
                        title="Algorithm not mapped"
                        message="Mapped standard permutations will show here"
                    />
                )}

                {!isLoading && !isEmpty(stdPermutationList) && (
                    <IdsTable variant="alternate" spacing="sm">
                        <IdsTableRow rowType="table-heading-row" customClasses="sticky-top">
                            <IdsTableCell cellType="table-heading-cell" heading="Standard Measure" style={style.measure} />
                            <IdsTableCell cellType="table-heading-cell" heading="Sector" style={style.sector} />
                            <IdsTableCell cellType="table-heading-cell" heading="End Use" style={style.endUse} />
                            <IdsTableCell cellType="table-heading-cell" heading="Vintage" style={style.vintage} />
                            <IdsTableCell cellType="table-heading-cell" heading="Fuel Type" style={style.fuelType} />
                            <IdsTableCell cellType="table-heading-cell" style={style.actions} />
                        </IdsTableRow>
                        {stdPermutationList.map((item) => (
                            <IdsTableRow key={item.stdPermutationNumber} rowType="table-body-row">
                                <IdsTableCell style={{ ...style.measure, verticalAlign: "middle" }}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsText>
                                                <>{item.measure}</>
                                            </IdsText>
                                        ) : (
                                            // TODO: Remove style attribute and add "gap-2" class
                                            <div className="flex flex-row items-center" style={{ gap: "0.5rem" }}>
                                                <FontAwesomeIcon className="icon-secondary" icon={trashRegularIcon} size="lg" />
                                                <IdsText customClasses="text-secondary" size="md">
                                                    Mapping Deleted
                                                </IdsText>
                                            </div>
                                        )}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.sector}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsTag variant="brand-c" size="sm" style={{ maxWidth: "9.125rem" }}>
                                                <div className="text-truncate">{item.sector}</div>
                                            </IdsTag>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.endUse}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsTag variant="brand-d" size="sm" style={{ maxWidth: "6.5rem" }}>
                                                <div className="text-truncate">{item.endUse}</div>
                                            </IdsTag>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.vintage}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsTag size="sm" style={{ maxWidth: "7.25rem" }}>
                                                <div className="text-truncate">{item.vintage}</div>
                                            </IdsTag>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={style.fuelType}>
                                    <div>
                                        {!item.isBeingDeleted ? (
                                            <IdsText>
                                                <>{item.fuelType}</>
                                            </IdsText>
                                        ) : null}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={{ ...style.actions, verticalAlign: "middle" }}>
                                    <div className="flex flex-row justify-end">
                                        {!item.isBeingDeleted ? (
                                            <IdsButtonGroup customClasses="flex-nowrap">
                                                <Menu
                                                    id={`duplicate-mapping-menu-${item.stdPermutationNumber}`}
                                                    buttonIcon={copyRegularIcon}
                                                    buttonIconTitle="Duplicate Mapping"
                                                    buttonIconSize="lg"
                                                    actionLabel="Save"
                                                    header="Duplicate Mapping"
                                                    width={MENU_WIDTH}
                                                    onOpen={() => onMenuOpen(item)}
                                                    onAction={onAddMapping}
                                                >
                                                    <MenuContent
                                                        selectedStdMeasure={selectedStdMeasure}
                                                        selectedStdSector={selectedStdSector}
                                                        selectedStdEndUse={selectedStdEndUse}
                                                        selectedStdVintage={selectedStdVintage}
                                                        selectedFuelType={selectedFuelType}
                                                        stdMeasureList={stdMeasureList}
                                                        stdSectorList={stdSectorList}
                                                        stdEndUseList={stdEndUseList}
                                                        stdVintageList={stdVintageList}
                                                        fuelTypeList={fuelTypeList}
                                                        onChange={onChange}
                                                    />
                                                </Menu>
                                                <Menu
                                                    id={`edit-mapping-menu-${item.stdPermutationNumber}`}
                                                    buttonIcon={penToSquareRegularIcon}
                                                    buttonIconTitle="Edit Mapping"
                                                    buttonIconSize="lg"
                                                    actionLabel="Save"
                                                    header="Edit Mapping"
                                                    width={MENU_WIDTH}
                                                    onOpen={() => onMenuOpen(item)}
                                                    onAction={() => onEditMapping(item.stdPermutationNumber)}
                                                >
                                                    <MenuContent
                                                        selectedStdMeasure={selectedStdMeasure}
                                                        selectedStdSector={selectedStdSector}
                                                        selectedStdEndUse={selectedStdEndUse}
                                                        selectedStdVintage={selectedStdVintage}
                                                        selectedFuelType={selectedFuelType}
                                                        stdMeasureList={stdMeasureList}
                                                        stdSectorList={stdSectorList}
                                                        stdEndUseList={stdEndUseList}
                                                        stdVintageList={stdVintageList}
                                                        fuelTypeList={fuelTypeList}
                                                        onChange={onChange}
                                                    />
                                                </Menu>
                                                <IconButton
                                                    icon={trashRegularIcon}
                                                    size="lg"
                                                    title="Delete Mapping"
                                                    onClick={() => onDeleteMapping(item.stdPermutationNumber)}
                                                />
                                            </IdsButtonGroup>
                                        ) : (
                                            <IdsButton
                                                variant="tertiary"
                                                padding="sm"
                                                clickHandler={() => onUndoDelete(item.stdPermutationNumber)}
                                            >
                                                Undo
                                            </IdsButton>
                                        )}
                                    </div>
                                </IdsTableCell>
                            </IdsTableRow>
                        ))}
                    </IdsTable>
                )}
            </div>
            <div slot="footer">
                {areChangesMade && (
                    <IdsButtonGroup spaceBetween="lg">
                        <IdsButton variant="secondary" clickHandler={onClose}>
                            Discard and Cancel
                        </IdsButton>
                        <IdsButton clickHandler={onSave}>Save Changes</IdsButton>
                    </IdsButtonGroup>
                )}
            </div>
        </IdsModal>
    );
};

type StdPermutationModel = StdPermutation & {
    isBeingDeleted: boolean;
};
