import { Skeleton } from "components/Skeleton";

const AlgorithmMappingsSkeleton: React.FC = () => {
    return (
        <div className="flex flex-col h-full space-y-4">
            {/* TODO: Remove style attribute and add "p-4" class */}
            <div className="flex flex-col h-full justify-between bg-theme-base" style={{ padding: "1rem" }}>
                <div className="flex flex-row space-x-2">
                    <div className="flex flex-row w-full space-x-2">
                        <Skeleton className="h-[24px] w-1/5 rounded" backgroundClassName="bg-theme-base-darker" />
                        <Skeleton className="h-[24px] w-1/5 rounded" backgroundClassName="bg-theme-base-darker" />
                        <Skeleton className="h-[24px] w-1/5 rounded" backgroundClassName="bg-theme-base-darker" />
                        <Skeleton className="h-[24px] w-1/5 rounded" backgroundClassName="bg-theme-base-darker" />
                        <Skeleton className="h-[24px] w-1/5 rounded" backgroundClassName="bg-theme-base-darker" />
                        <div className="w-[18px]" />
                        <div className="w-[18px]" />
                        <div className="w-[18px]" />
                    </div>
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
                <div className="flex-row space-x-2">
                    <Skeleton className="h-[16px] w-full rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                    <Skeleton className="h-[16px] w-[18px] rounded" backgroundClassName="bg-theme-base-darker" />
                </div>
            </div>
            <div className="flex flex-row items-center justify-between space-x-4">
                <div>
                    <Skeleton className="h-[16px] w-[160px] rounded" />
                </div>
                <div className="flex flex-row items-center space-x-2">
                    <Skeleton className="h-[16px] w-[204px] rounded" />
                    <Skeleton className="h-[40px] w-[64px] rounded" />
                    <Skeleton className="h-[40px] w-[40px] rounded" />
                    <Skeleton className="h-[40px] w-[40px] rounded" />
                    <Skeleton className="h-[40px] w-[40px] rounded" />
                </div>
            </div>
        </div>
    );
};

export default AlgorithmMappingsSkeleton;
