import { isEmpty } from "lodash";
import { createContext, useCallback, useMemo, useRef, useState } from "react";

import { IdsContainer, IdsRow, IdsCol, IdsCard } from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { useGetRelevantAlgorithmsQuery } from "store/apiSlice";

import { useAppSelector, useAppDispatch } from "store/hooks";

import { bookSolidIcon } from "utils/icons";

import { setSelectedRelAlgorithms, setTrigger } from "./benchmarkTrmSlice";
import { MainPanel } from "./MainPanel";
import { Sidebar } from "./Sidebar";
import { Tabs } from "./utils";

export const BenchmarkTrmContext = createContext<{
    isSelecting?: boolean;
    setIsSelecting?: (isSelecting: boolean) => void;
}>({});

export const BenchmarkTrm = () => {
    const dispatch = useAppDispatch();

    const [activeTab, setActiveTab] = useState<Tab>(Tabs.MeasureLives.id);
    const [isSelecting, setIsSelecting] = useState(false);

    const selectTrmRef = useRef<HTMLIdsDropdownElement>(null);

    const { selectedTrm, selectedMeasure, selectedSector, selectedVintage, selectedFuelType, selectedRelAlgorithms, triggerSelect } =
        useAppSelector((state) => state.benchmarkTrm);

    const { data: relAlgorithms, isFetching: isFetchingRelAlgorithms } = useGetRelevantAlgorithmsQuery(
        {
            trmNumber: selectedTrm,
            measureNumber: selectedMeasure,
            sectorNumber: selectedSector,
            vintageNumber: selectedVintage,
            fuelTypeNumber: selectedFuelType,
        },
        { skip: isEmpty(selectedTrm) || isEmpty(selectedMeasure) },
    );

    const showMainContent = !isEmpty(selectedTrm) && !isEmpty(selectedMeasure);
    const isSavingsCalculationsTab = activeTab === Tabs.SavingsCalculations.id;

    // Selects all relevant algorithms on measure, sector, vintage or fuel type change - if on EUL or Costs tab
    // Deselects all relevant algorithms on measure, sector, vintage or fuel type change - if on Savings Calculations tab
    if (relAlgorithms !== undefined && !isFetchingRelAlgorithms && triggerSelect) {
        dispatch(setTrigger(false));

        if (activeTab === Tabs.SavingsCalculations.id) {
            dispatch(setSelectedRelAlgorithms([]));
        } else {
            dispatch(setSelectedRelAlgorithms([...relAlgorithms]));
        }
    }

    const onTabChange = (id: Tab) => {
        dispatch(setTrigger(true));

        setActiveTab(id);
    };

    const onChooseClick = useCallback(() => {
        setTimeout(() => {
            selectTrmRef.current?.querySelector("ids-icon")?.click();
        }, 100);
    }, []);

    return (
        <BenchmarkTrmContext.Provider value={useMemo(() => ({ isSelecting, setIsSelecting }), [isSelecting, setIsSelecting])}>
            <IdsContainer fullHeight customClasses="p-0">
                <IdsRow noGutters>
                    <IdsCol xs="3">
                        <IdsCard customClasses="fill-height" style={{ borderRight: "1px solid var(--theme-base-border)" }}>
                            <div slot="slot1" className="fill-height">
                                <Sidebar
                                    selectedTrm={selectedTrm}
                                    selectedMeasure={selectedMeasure}
                                    selectedSector={selectedSector}
                                    selectedVintage={selectedVintage}
                                    selectedFuelType={selectedFuelType}
                                    selectedRelAlgorithms={selectedRelAlgorithms}
                                    selectTrmRef={selectTrmRef}
                                    isFetching={isFetchingRelAlgorithms}
                                    relAlgorithms={relAlgorithms}
                                    isSavingsCalculationsTab={isSavingsCalculationsTab}
                                />
                            </div>
                        </IdsCard>
                    </IdsCol>
                    <IdsCol xs="9">
                        {showMainContent ? (
                            <IdsCard customClasses="shadow-none fill-height">
                                <div slot="slot1" className="fill-height">
                                    <MainPanel activeTab={activeTab} onTabChange={onTabChange} />
                                </div>
                            </IdsCard>
                        ) : (
                            <NothingFoundBlock
                                icon={bookSolidIcon}
                                title="No TRM Chosen"
                                message="Please select a TRM and Measure to benchmark"
                                secondaryActionLabel="Choose TRM"
                                onSecondaryActionClick={onChooseClick}
                            />
                        )}
                    </IdsCol>
                </IdsRow>
            </IdsContainer>
        </BenchmarkTrmContext.Provider>
    );
};

export type Tab = (typeof Tabs)[keyof typeof Tabs]["id"];
