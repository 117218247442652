import { isEmpty } from "lodash";
import { useMemo } from "react";

import { useGetSavingsBenchmarksQuery, useGetStdVariablesQuery } from "store/apiSlice";

import { IdsTable } from "@emergn-infinity/ids-react";

import { NothingFoundBlock } from "components/NothingFoundBlock";

import { calculatorSimpleSolidIcon } from "utils/icons";

import { SavingsCalculationsGridHeader } from "./SavingsCalculationsGridHeader";
import { SavingsCalculationsGridRows } from "./SavingsCalculationsGridRows";

import type { RelevantAlgorithm } from "types";
import type { SavingsCalculationsMatrix } from "pages/BenchmarkTrm/MainPanel/SavingsCalculations";

export const SavingsCalculationsGrid: React.FC<{
    selectedTrmName: string;
    selectedMeasureName: string;
    items: RelevantAlgorithm[];
    assumptionsForStandardizing: SavingsCalculationsMatrix;
    setAssumptionsForStandardizing: (assumptionsForStandardizing: SavingsCalculationsMatrix) => void;
    setAssumptionNumberForMapping: (assumptionNumber: string) => void;
}> = ({
    selectedTrmName,
    selectedMeasureName,
    items,
    assumptionsForStandardizing,
    setAssumptionsForStandardizing,
    setAssumptionNumberForMapping,
}) => {
    const relAlgorithmNumbers = items.map((item) => item.algorithmNumber);
    const trmsAndMeasures = items.map((item) => ({ trmFamiliarName: item.trmFamiliarName, measureName: item.measure }));

    const { data: savingsBenchmarks } = useGetSavingsBenchmarksQuery(
        { relevantAlgorithmNumbers: relAlgorithmNumbers },
        { skip: isEmpty(relAlgorithmNumbers) },
    );
    const { data: stdVariables } = useGetStdVariablesQuery();

    const headers = useMemo(() => {
        const headers: Header[] = [];

        if (savingsBenchmarks !== undefined) {
            trmsAndMeasures.forEach((trmAndMeasure) => {
                const formattedAlgorithm =
                    savingsBenchmarks.find(
                        (savings) =>
                            savings.trmFamiliarName === trmAndMeasure.trmFamiliarName && savings.measureName === trmAndMeasure.measureName,
                    )?.formattedAlgorithm ?? "";

                headers.push({
                    trmFamiliarName: trmAndMeasure.trmFamiliarName,
                    measureName: trmAndMeasure.measureName,
                    formattedAlgorithm,
                });
            });
        }

        return headers;
    }, [savingsBenchmarks, trmsAndMeasures]);

    // Row items are sorted alphabetically, but first items
    // are savings related to selected TRM and measure.
    //
    // Following structure:
    //
    //  {
    //      "Quantity installed": {
    //          "Arkansas Statewide TRM V9.1 (2022)-Electric Vehicle Charging Systems (EV Chargers)": {
    //              formattedAlgorithm: "kWh = <DeemedSavingsValue> * <Chargers>",
    //              ...
    //          },
    //          isStandardized: false,
    //      },
    //      ...
    //  }
    const { rowItems, unstandardizedRowItems } = useMemo(() => {
        let rowItems: SavingsCalculationsMatrix = {};
        let unstandardizedRowItems: SavingsCalculationsMatrix = {};

        if (savingsBenchmarks && stdVariables) {
            const sortedSavingsByVar = [...savingsBenchmarks].sort((a, b) => a.displayVarAs?.localeCompare(b.displayVarAs));

            const selectedSavings = sortedSavingsByVar.filter(
                (savings) => savings.trmFamiliarName === selectedTrmName && savings.measureName === selectedMeasureName,
            );

            const relevantSavings = sortedSavingsByVar.filter(
                (savings) => savings.trmFamiliarName !== selectedTrmName || savings.measureName !== selectedMeasureName,
            );

            const sortedSavings = selectedSavings.concat(relevantSavings);

            rowItems = sortedSavings.reduce((acc, cv) => {
                if (acc === undefined || acc?.[cv.displayVarAs] === undefined) {
                    const newAccumulator = { ...acc };

                    newAccumulator[cv.displayVarAs] = {
                        isStandardized: stdVariables.find((v) => v.stdVariableDesc === cv.displayVarAs) !== undefined,
                    };

                    // NOTE: TRMs and measures can be the same,
                    // so using index here to make each column unique
                    trmsAndMeasures.forEach((trmAndMeasure, index) => {
                        newAccumulator[cv.displayVarAs][`${trmAndMeasure.trmFamiliarName}-${trmAndMeasure.measureName}-${index}`] = {};
                    });

                    acc = newAccumulator;
                }

                for (let index = 0; index < trmsAndMeasures.length; index++) {
                    const trmAndMeasure = trmsAndMeasures[index];

                    if (trmAndMeasure.trmFamiliarName === cv.trmFamiliarName && trmAndMeasure.measureName === cv.measureName) {
                        acc[cv.displayVarAs][`${trmAndMeasure.trmFamiliarName}-${trmAndMeasure.measureName}-${index}`] = cv;

                        break;
                    }
                }

                return acc;
            }, {});

            Object.keys(rowItems).forEach((assumptionKey) => {
                if (rowItems[assumptionKey].isStandardized === false) {
                    unstandardizedRowItems[assumptionKey] = rowItems[assumptionKey];
                }
            });
        }

        return { rowItems, unstandardizedRowItems };
    }, [selectedTrmName, selectedMeasureName, savingsBenchmarks, stdVariables, trmsAndMeasures]);

    const areAllRowsSelected = Object.keys(unstandardizedRowItems).length === Object.keys(assumptionsForStandardizing).length;

    const onSelectAll = () => {
        setAssumptionsForStandardizing(unstandardizedRowItems);
    };

    const onDeselectAll = () => {
        setAssumptionsForStandardizing({});
    };

    return (
        <div className="flex flex-row">
            <IdsTable key={`savings-calculations-${items.length}`} variant="alternate" spacing="sm">
                <SavingsCalculationsGridHeader
                    headers={headers}
                    areAllRowsSelected={areAllRowsSelected}
                    onSelectAll={onSelectAll}
                    onDeselectAll={onDeselectAll}
                />
                {!isEmpty(rowItems) && (
                    <SavingsCalculationsGridRows
                        rowItems={rowItems}
                        assumptionsForStandardizing={assumptionsForStandardizing}
                        setAssumptionsForStandardizing={setAssumptionsForStandardizing}
                        setAssumptionNumberForMapping={setAssumptionNumberForMapping}
                    />
                )}
            </IdsTable>
            {items.length === 1 && (
                <div className="flex flex-col w-full">
                    <NothingFoundBlock
                        icon={calculatorSimpleSolidIcon}
                        title="No Algorithms Selected"
                        message="Select algorithms to compare"
                    />
                </div>
            )}
        </div>
    );
};

export type Header = {
    trmFamiliarName: string;
    measureName: string;
    formattedAlgorithm: string;
};
