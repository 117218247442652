import { combineReducers } from "@reduxjs/toolkit";

import { USER_DELETE } from "./actionTypes";
import { api } from "./apiSlice";
import { signalrApi } from "./signalrSlice";

import menuReducer from "components/Menu/menuSlice";
import tourReducer from "components/TourCard/tourSlice";

import benchmarkTrmReducer from "pages/BenchmarkTrm/benchmarkTrmSlice";
import exploreTrmsReducer from "pages/ExploreTrms/exploreTrmsSlice";
import homeReducer from "pages/Home/homeSlice";

import upgradeTrmReducer from "./upgradeTrmSlice";
import { reducer as loginReducer } from "./login/reducer";
import { reducer as systemReducer } from "./system/reducer";
import { reducer as userReducer } from "./user/reducer";
import { reducer as windowReducer } from "./window/reducer";

const appReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    [signalrApi.reducerPath]: signalrApi.reducer,
    benchmarkTrm: benchmarkTrmReducer,
    exploreTrms: exploreTrmsReducer,
    home: homeReducer,
    login: loginReducer,
    menu: menuReducer,
    system: systemReducer,
    tour: tourReducer,
    upgradeTrm: upgradeTrmReducer,
    user: userReducer,
    window: windowReducer,
});

const rootReducer = (state, action) => {
    if (action.type === USER_DELETE) {
        const tourState = { ...state.tour };

        state = {};
        state.tour = tourState; // persist tour state
    }

    return appReducer(state, action);
};

export default rootReducer;
