import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { IdsButton, IdsButtonGroup, IdsCard, IdsCol, IdsContainer, IdsRow, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setActiveTab } from "pages/Home/homeSlice";
import { Tabs } from "pages/Home/utils";

import {
    useGetEndUseStagingsQuery,
    useUpdateEndUseStagingsMutation,
    useGetMeasureStagingsQuery,
    useUpdateMeasureStagingsMutation,
    useGetSectorStagingsQuery,
    useUpdateSectorStagingsMutation,
    useUpdateTrmMutation,
    useGetTrmDetailsQuery,
    useCommitUpgradeTrmVersionMutation,
    useGetVintageStagingsQuery,
    useUpdateVintageStagingsMutation,
} from "store/apiSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { arrowRightRegularIcon, arrowsRotateRegularIcon } from "utils/icons";

import { Step } from "./Step";
import { TrmInformation } from "./TrmInformation";
import { Attribute } from "./Attribute";
import { ProgressSteps } from "./utils";

import type { AttributeStaging, TrmUpdateModel } from "types";

export const ReviewUpdates: React.FC = () => {
    const dispatch = useAppDispatch();

    const { selectedTrm } = useAppSelector((state) => state.exploreTrms);
    const { publisherNumber, sourceTrmNumber, stagingNumber } = useAppSelector((state) => state.upgradeTrm[selectedTrm]);

    const [activeStep, setActiveStep] = useState(0);
    const [selectedSectors, setSelectedSectors] = useState<AttributeStaging[]>([]);
    const [selectedEndUses, setSelectedEndUses] = useState<AttributeStaging[]>([]);
    const [selectedMeasures, setSelectedMeasures] = useState<AttributeStaging[]>([]);
    const [selectedVintages, setSelectedVintages] = useState<AttributeStaging[]>([]);

    const [targetTrmDetailsState, setTargetTrmDetailsState] = useState<TrmUpdateModel>();

    const { data: targetTrmDetails } = useGetTrmDetailsQuery({ trmNumber: selectedTrm });
    const { data: sectors } = useGetSectorStagingsQuery({ stagingNumber });
    const { data: endUses } = useGetEndUseStagingsQuery({ stagingNumber });
    const { data: measures } = useGetMeasureStagingsQuery({ stagingNumber });
    const { data: vintages } = useGetVintageStagingsQuery({ stagingNumber });

    const [updateTrm, updateTrmStatus] = useUpdateTrmMutation();
    const [updateSectors, updateSectorsStatus] = useUpdateSectorStagingsMutation();
    const [updateEndUses, updateEndUsesStatus] = useUpdateEndUseStagingsMutation();
    const [updateMeasures, updateMeasuresStatus] = useUpdateMeasureStagingsMutation();
    const [updateVintages, updateVintagesStatus] = useUpdateVintageStagingsMutation();
    const [commitUpgrade, commitUpgradeStatus] = useCommitUpgradeTrmVersionMutation();

    const sectorItems = useMemo(() => {
        let sectorItems: AttributeStaging[] = [];

        if (sectors && !isEmpty(sectors)) {
            sectorItems = sectors.map((sector) => {
                const { sourceSectorNumber, ...rest } = sector;

                return {
                    ...rest,
                    sourceAttributeNumber: sourceSectorNumber,
                };
            });
        }

        return sectorItems;
    }, [sectors]);

    const endUseItems = useMemo(() => {
        let endUseItems: AttributeStaging[] = [];

        if (endUses && !isEmpty(endUses)) {
            endUseItems = endUses.map((endUse) => {
                const { sourceEndUseNumber, ...rest } = endUse;

                return {
                    ...rest,
                    sourceAttributeNumber: sourceEndUseNumber,
                };
            });
        }

        return endUseItems;
    }, [endUses]);

    const measureItems = useMemo(() => {
        let measureItems: AttributeStaging[] = [];

        if (measures && !isEmpty(measures)) {
            measureItems = measures.map((measure) => {
                const { sourceMeasureNumber, ...rest } = measure;

                return {
                    ...rest,
                    sourceAttributeNumber: sourceMeasureNumber,
                };
            });
        }

        return measureItems;
    }, [measures]);

    const vintageItems = useMemo(() => {
        let vintageItems: AttributeStaging[] = [];

        if (vintages && !isEmpty(vintages)) {
            vintageItems = vintages.map((vintage) => {
                const { sourceVintageNumber, ...rest } = vintage;

                return {
                    ...rest,
                    sourceAttributeNumber: sourceVintageNumber,
                };
            });
        }

        return vintageItems;
    }, [vintages]);

    if (targetTrmDetails && !targetTrmDetailsState) {
        setTargetTrmDetailsState({
            trmNumber: targetTrmDetails.trmNumber,
            trmFamiliarName: targetTrmDetails.trmFamiliarName,
            trmName: targetTrmDetails.trmName,
            preparedBy: targetTrmDetails.preparedBy,
            applicableYear: targetTrmDetails.applicableYear,
            yearPrepared: targetTrmDetails.yearPrepared,
            defSummerPeak: targetTrmDetails.defSummerPeak,
            defWinterPeak: targetTrmDetails.defWinterPeak,
        });
    }

    const runTrmInformationStep = async () => {
        if (updateTrmStatus.isLoading || !targetTrmDetailsState) {
            return;
        }

        try {
            await updateTrm({ model: targetTrmDetailsState });
        } catch (error) {
            console.error(error);
        }
    };

    const runSectorsStep = async () => {
        if (updateSectorsStatus.isLoading || isEmpty(sectors)) {
            return;
        }

        try {
            const sectorList = selectedSectors.map((sector) => {
                const { sourceAttributeNumber, ...rest } = sector;

                return {
                    ...rest,
                    sourceSectorNumber: sourceAttributeNumber,
                };
            });

            await updateSectors({ model: sectorList });
        } catch (error) {
            console.error(error);
        }
    };

    const runEndUsesStep = async () => {
        if (updateEndUsesStatus.isLoading || isEmpty(endUses)) {
            return;
        }

        try {
            const endUseList = selectedEndUses.map((endUse) => {
                const { sourceAttributeNumber, ...rest } = endUse;

                return {
                    ...rest,
                    sourceEndUseNumber: sourceAttributeNumber,
                };
            });

            await updateEndUses({ model: endUseList });
        } catch (error) {
            console.error(error);
        }
    };

    const runMeasuresStep = async () => {
        if (updateMeasuresStatus.isLoading || isEmpty(measures)) {
            return;
        }

        try {
            const measureList = selectedMeasures.map((measure) => {
                const { sourceAttributeNumber, ...rest } = measure;

                return {
                    ...rest,
                    sourceMeasureNumber: sourceAttributeNumber,
                };
            });

            await updateMeasures({ model: measureList });
        } catch (error) {
            console.error(error);
        }
    };

    const runVintagesStep = async () => {
        if (updateVintagesStatus.isLoading) {
            return false;
        }

        if (isEmpty(vintages)) {
            return true;
        }

        try {
            const vintageList = selectedVintages.map((vintage) => {
                const { sourceAttributeNumber, ...rest } = vintage;

                return {
                    ...rest,
                    sourceVintageNumber: sourceAttributeNumber,
                };
            });

            const response = await updateVintages({ model: vintageList }).unwrap();

            if (response.responseStatus === "success") {
                return true;
            }
        } catch (error) {
            console.error(error);
        }

        return false;
    };

    const runCommitUpgrade = async () => {
        if (commitUpgradeStatus.isLoading) {
            return;
        }

        try {
            const model = {
                publisherNumber,
                sourceTrmNumber,
                targetTrmNumber: selectedTrm,
                stagingNumber,
            };

            const response = await commitUpgrade({ model }).unwrap();

            if (response.responseStatus === "success") {
                dispatch(setActiveTab(Tabs.ExploreTrms.id));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const goBack = () => {
        if (activeStep === 0) {
            dispatch(setActiveTab(Tabs.ExploreTrms.id));

            return;
        }

        setActiveStep((prev) => prev - 1);
    };

    const goForward = async () => {
        switch (activeStep) {
            case 0:
                runTrmInformationStep();

                break;
            case 1:
                runSectorsStep();

                break;
            case 2:
                runEndUsesStep();

                break;
            case 3:
                runMeasuresStep();

                break;
            default:
                {
                    const readyToCommit = await runVintagesStep();

                    if (readyToCommit) {
                        runCommitUpgrade();
                    }
                }

                return;
        }

        setActiveStep((prev) => prev + 1);
    };

    return (
        <div className="flex-column fill-height">
            <div className="flex-row align-center gap-3 bg-white px-4 py-3" style={{ borderBottom: "1px solid var(--theme-base-border)" }}>
                <FontAwesomeIcon icon={arrowsRotateRegularIcon} color="var(--ids-semantic-ink-color-brand-b-subtlest)" />
                <div className="flex-row gap-1">
                    <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>Reviewing updates for</IdsText>
                    <IdsText weight="bold" style={{ color: "var(--ids-semantic-ink-color-neutral-accent-onlight)" }}>
                        {targetTrmDetails?.trmFamiliarName}
                    </IdsText>
                </div>
            </div>
            <div className="flex-column min-h-0 fill-height">
                <IdsContainer customClasses="p-0" fullHeight>
                    <IdsRow noGutters>
                        <IdsCol xs="2">
                            <IdsCard customClasses="fill-height">
                                <div slot="slot1">
                                    {ProgressSteps.map((step, index, steps) => (
                                        <Step
                                            key={step}
                                            number={index + 1}
                                            name={step}
                                            isActive={activeStep === index}
                                            isComplete={activeStep > index}
                                            steps={steps}
                                        />
                                    ))}
                                </div>
                            </IdsCard>
                        </IdsCol>
                        <IdsCol xs="10">
                            <div className="fill-height with-scroll bg-white pt-4 pr-4">
                                <div
                                    className="rounded-edges-rounder min-h-100 bg-theme-base py-4"
                                    style={{ paddingRight: 120, paddingLeft: 120 }}
                                >
                                    <div className="flex-column gap-4 py-4">
                                        <div className="flex-column gap-2">
                                            <IdsText
                                                weight="bold"
                                                component="h3"
                                                size="sm"
                                                style={{ color: "var(--ids-text-heading-color)" }}
                                            >
                                                <>{ProgressSteps[activeStep]}</>
                                            </IdsText>
                                            <IdsText style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                                                Review the updated information and edit if necessary.
                                            </IdsText>
                                        </div>

                                        {activeStep === 0 && targetTrmDetails && targetTrmDetailsState && (
                                            <TrmInformation
                                                sourceTrmNumber={sourceTrmNumber}
                                                targetTrmDetails={targetTrmDetails}
                                                targetTrmDetailsState={targetTrmDetailsState}
                                                setTargetTrmDetailsState={setTargetTrmDetailsState}
                                            />
                                        )}
                                        {activeStep === 1 && (
                                            <Attribute
                                                attribute={ProgressSteps[activeStep]}
                                                items={sectorItems}
                                                selectedItems={selectedSectors}
                                                setSelectedItems={setSelectedSectors}
                                            />
                                        )}
                                        {activeStep === 2 && (
                                            <Attribute
                                                attribute={ProgressSteps[activeStep]}
                                                items={endUseItems}
                                                selectedItems={selectedEndUses}
                                                setSelectedItems={setSelectedEndUses}
                                            />
                                        )}
                                        {activeStep === 3 && (
                                            <Attribute
                                                attribute={ProgressSteps[activeStep]}
                                                items={measureItems}
                                                selectedItems={selectedMeasures}
                                                setSelectedItems={setSelectedMeasures}
                                            />
                                        )}
                                        {activeStep === 4 && (
                                            <Attribute
                                                attribute={ProgressSteps[activeStep]}
                                                items={vintageItems}
                                                selectedItems={selectedVintages}
                                                setSelectedItems={setSelectedVintages}
                                            />
                                        )}

                                        <IdsButtonGroup position="right" spaceBetween="lg">
                                            <IdsButton variant="tertiary" clickHandler={goBack}>
                                                Back
                                            </IdsButton>
                                            <IdsButton clickHandler={goForward}>
                                                <div className="flex-row align-center gap-2">
                                                    {activeStep === ProgressSteps.length - 1 ? (
                                                        "Finish Review"
                                                    ) : (
                                                        <>
                                                            Next
                                                            <FontAwesomeIcon icon={arrowRightRegularIcon} />
                                                        </>
                                                    )}
                                                </div>
                                            </IdsButton>
                                        </IdsButtonGroup>
                                    </div>
                                </div>
                            </div>
                        </IdsCol>
                    </IdsRow>
                </IdsContainer>
            </div>
        </div>
    );
};

export type StepName = (typeof ProgressSteps)[number];
