import { Link } from "./Link";
import { LinkEdit } from "./LinkEdit";

export const LinkCard: React.FC<{
    isEditing?: boolean;
    linkNumber?: string;
    availability?: string;
    format?: string;
    documentType?: string;
    linkName?: string;
    linkDescription?: string;
    urlLink?: string;
    isLoading?: boolean;
    onSave?: (params: LinkSaveParams) => void;
    onCancel?: () => void;
    onDelete?: () => void;
    onEdit?: () => void;
}> = ({
    isEditing,
    linkNumber,
    availability,
    format,
    documentType,
    linkName,
    linkDescription,
    urlLink,
    isLoading,
    onSave,
    onCancel,
    onDelete,
    onEdit,
}) => {
    return (
        // TODO: Add Tailwind classes
        <div
            className="border border-solid border-base-color-border rounded-lg"
            style={{
                paddingTop: isEditing ? "1rem" : "0.25rem",
                paddingBottom: isEditing ? "1rem" : "0.25rem",
                paddingRight: "1rem",
                paddingLeft: isEditing ? "1rem" : "0.25rem",
            }}
        >
            {isEditing ? (
                <LinkEdit
                    linkNumber={linkNumber}
                    availability={availability}
                    format={format}
                    documentType={documentType}
                    linkName={linkName}
                    linkDescription={linkDescription}
                    urlLink={urlLink}
                    isLoading={isLoading}
                    onSave={onSave}
                    onCancel={onCancel}
                />
            ) : (
                linkName &&
                urlLink && <Link linkName={linkName} urlLink={urlLink} availability={availability} onDelete={onDelete} onEdit={onEdit} />
            )}
        </div>
    );
};

export type LinkSaveParams = {
    availability: string;
    format: string;
    documentType: string;
    linkName: string;
    urlLink: string;
    linkDescription?: string;
    linkNumber?: string;
};
