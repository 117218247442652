import { isEmpty } from "lodash";
import { useMemo } from "react";

import { IdsText, IdsTable, IdsTableRow, IdsTableCell, IdsTag } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";

import { TrmGlossaryAttributes } from "pages/ExploreTrms/utils";

import { useGetSectorsQuery, useGetEndUsesQuery, useGetVintagesQuery, useGetSectorPagesQuery } from "store/apiSlice";
import { useAppSelector } from "store/hooks";

import { circleCheckSolidIcon, circleXmarkSolidIcon, fileImportRegularIcon } from "utils/icons";

import type { Attribute } from ".";

export const AttributeTable: React.FC<{
    isVerified?: boolean;
    selectedAttribute: Attribute;
    onOpenTrmViewer: (sectorNumber: string) => void;
}> = ({ isVerified, selectedAttribute, onOpenTrmViewer }) => {
    const { selectedTrm, selectedMeasure } = useAppSelector((state) => state.exploreTrms);

    // External users with readOnly rights can see only verified sectors, end uses and vintages
    const { data: sectors, isError: isGetSectorsError } = useGetSectorsQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm) || selectedAttribute !== TrmGlossaryAttributes.Sectors,
        },
    );

    const { data: endUses, isError: isGetEndUsesError } = useGetEndUsesQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm) || selectedAttribute !== TrmGlossaryAttributes.EndUses,
        },
    );

    const { data: vintages, isError: isGetVintagesError } = useGetVintagesQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm) || selectedAttribute !== TrmGlossaryAttributes.Vintages,
        },
    );

    const tableData: TableRow[] = useMemo(() => {
        switch (selectedAttribute) {
            case TrmGlossaryAttributes.Sectors:
                return (isGetSectorsError ? [] : (sectors ?? [])).map((sector) => ({
                    name: sector.sectorName,
                    verified: sector.secVerified,
                    hasPages: sector.sectorHasPages,
                    id: sector.sectorNumber,
                }));
            case TrmGlossaryAttributes.EndUses:
                return (isGetEndUsesError ? [] : (endUses ?? [])).map((endUse) => ({
                    name: endUse.endUseName,
                    verified: endUse.endUseVerified,
                    hasPages: false,
                    id: endUse.endUseNumber,
                }));
            case TrmGlossaryAttributes.Vintages:
                return (isGetVintagesError ? [] : (vintages ?? [])).map((vintage) => ({
                    name: vintage.vintageName,
                    description: vintage.vintageDef,
                    verified: vintage.vintageVerified,
                    hasPages: false,
                    id: vintage.vintageNumber,
                }));
            default:
                return [];
        }
    }, [selectedAttribute, isGetSectorsError, sectors, isGetEndUsesError, endUses, isGetVintagesError, vintages]);

    const items = tableData?.filter((row) => row.verified === Boolean(isVerified));

    const tableTagVariant = useMemo(() => {
        switch (selectedAttribute) {
            case TrmGlossaryAttributes.Sectors:
                return "brand-c";
            case TrmGlossaryAttributes.EndUses:
                return "brand-d";
            default:
                return undefined;
        }
    }, [selectedAttribute]);

    if (isEmpty(items)) {
        return null;
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="flex flex-row items-center gap-2">
                <FontAwesomeIcon
                    icon={isVerified ? circleCheckSolidIcon : circleXmarkSolidIcon}
                    color={isVerified ? "var(--ids-core-color-brand-e-600)" : "var(--ids-core-color-system-a-600)"}
                />
                <IdsText weight="bold" size="md" style={{ color: "var(--ids-semantic-ink-color-brand-b-subtlest)" }}>
                    {`${isVerified ? "Verified" : "Unverified"} ${TrmGlossaryAttributes[selectedAttribute]}`}
                </IdsText>
            </div>

            {/* TODO: Add Tailwind classes */}
            <div className="rounded flex flex-col gap-2 border overflow-hidden px-2">
                <IdsTable spacing="sm">
                    {items.map((row, index) => (
                        <IdsTableRow key={`row-${row.name}`}>
                            <IdsTableCell
                                customClasses="py-2 !bg-transparent"
                                style={{ borderBottom: index === items.length - 1 ? "none" : undefined, minWidth: 0, overflow: "hidden" }}
                            >
                                <div>
                                    <IdsTag variant={tableTagVariant} size="sm">
                                        <>{row.name}</>
                                    </IdsTag>
                                    {row.description && <IdsText size="md">{row.description}</IdsText>}
                                </div>
                            </IdsTableCell>
                            <IdsTableCell
                                customClasses="!bg-transparent"
                                style={{
                                    borderBottom: index === items.length - 1 ? "none" : undefined,
                                    minWidth: 0,
                                    width: "2.75rem",
                                    verticalAlign: "middle",
                                }}
                            >
                                <div>
                                    {row.hasPages && (
                                        <TrmViewerButton
                                            sectorNumber={row.id}
                                            measureNumber={selectedMeasure}
                                            onOpenTrmViewer={onOpenTrmViewer}
                                        />
                                    )}
                                </div>
                            </IdsTableCell>
                        </IdsTableRow>
                    ))}
                </IdsTable>
            </div>
        </div>
    );
};

export const TrmViewerButton: React.FC<{
    sectorNumber?: string;
    measureNumber?: string;
    onOpenTrmViewer: (sectorNumber: string) => void;
}> = ({ sectorNumber, measureNumber, onOpenTrmViewer }) => {
    const { data } = useGetSectorPagesQuery(
        { sectorNumber: sectorNumber! },
        {
            skip: isEmpty(sectorNumber),
        },
    );

    if (!sectorNumber || !measureNumber || isEmpty(data) || !data?.find((page) => page.measureNumber === measureNumber)) {
        return null;
    }

    return <IconButton icon={fileImportRegularIcon} title="Open in TRM" size="lg" onClick={() => onOpenTrmViewer(sectorNumber)} />;
};

type TableRow = {
    name: string;
    description?: string;
    verified: boolean;
    hasPages: boolean;
    id: string;
};
