import { isEmpty, isNil } from "lodash";
import React, { useMemo, useState } from "react";

import {
    IdsButton,
    IdsButtonGroup,
    IdsTextInput,
    IdsTable,
    IdsTableRow,
    IdsTableCell,
    IdsText,
    IdsTablePagination,
} from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";
import { NothingFoundBlock } from "components/NothingFoundBlock";
import { Tooltip } from "components/Tooltip";

import { copyLookupsToClipboard } from "pages/ExploreTrms/utils";

import { DEFAULT_ITEMS_PER_PAGE, LookupVariants } from "utils/constants";
import {
    checkLightIcon,
    circlePlusRegularIcon,
    fileExportRegularIcon,
    penToSquareRegularIcon,
    subtitlesSlashSolidIcon,
    trashRegularIcon,
    xmarkLightIcon,
} from "utils/icons";
import { createPaginationItems } from "utils/pagination";
import { sanitizeAndSetNumericInput } from "utils/string";

import type { LookupState } from ".";

export const LookupsGrid: React.FC<{
    lookups: LookupState[];
    lookupVariant: keyof typeof LookupVariants;
    hasErrors: boolean;
    setLookups: (lookups: LookupState[]) => void;
}> = ({ lookups, lookupVariant, hasErrors, setLookups }) => {
    const [tempLookups, setTempLookups] = useState(lookups);
    const [search, setSearch] = useState("");
    const [editIndex, setEditIndex] = useState(-1);
    const [isPasted, setIsPasted] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const isEditingLookups = editIndex !== -1;

    // list of refs to input elements
    const inputRefs = useMemo(() => {
        const inputRefs: React.RefObject<HTMLInputElement>[] = [];

        if (lookupVariant === LookupVariants.Equation) {
            return inputRefs;
        }

        for (let index = 0; index < DEFAULT_ITEMS_PER_PAGE; index++) {
            inputRefs.push(React.createRef<HTMLInputElement>());
        }

        return inputRefs;
    }, [lookupVariant]);

    const paginationItem = useMemo(() => {
        const filteredLookups = [...tempLookups].filter((lookup) =>
            lookup.lookupCriteria.toLowerCase().includes(search.toLowerCase().trim()),
        );
        const items = createPaginationItems(filteredLookups, DEFAULT_ITEMS_PER_PAGE);
        const totalRows = filteredLookups.length;
        let fromNumber = currentPage * DEFAULT_ITEMS_PER_PAGE - DEFAULT_ITEMS_PER_PAGE + 1;
        let toNumber = currentPage * DEFAULT_ITEMS_PER_PAGE;

        if (totalRows < toNumber) {
            toNumber = totalRows;
        }

        const totalItemsText = totalRows > 0 ? `${fromNumber}-${toNumber} of ${totalRows} items` : `${totalRows} items`;

        return { lookups: items, totalItemsText };
    }, [tempLookups, search, currentPage]);

    if (!isEmpty(paginationItem.lookups) && isPasted) {
        setIsPasted(false);
        setCurrentPage(Object.keys(paginationItem.lookups).length);
    }

    const getCellStyle = (isEditing: boolean) => {
        if (!isEditingLookups || isEditing) {
            return undefined;
        }

        return { backgroundColor: "var(--ids-semantic-background-color-neutral-subtlest-default)" };
    };

    const onSearchChange = (value: string) => {
        setEditIndex(-1);
        setSearch(value);
    };

    const onCopyToClipboard = () => {
        const entries = lookups.map((lookup) => ({ ...lookup, lookupValue: Number(lookup.lookupValue) }));

        copyLookupsToClipboard(entries, lookupVariant);
    };

    const onAddLookup = () => {
        const newTempLookups = [...tempLookups, { lookupCriteria: "", lookupValue: undefined, lookupEquation: undefined }];
        const index = newTempLookups.length - 1;

        const itemPage = Math.ceil(newTempLookups.length / DEFAULT_ITEMS_PER_PAGE);

        if (currentPage !== itemPage) {
            setCurrentPage(itemPage);
        }

        setEditIndex(index);
        setTempLookups(newTempLookups);

        // focus the added line
        setTimeout(() => {
            const criteriaInputs = document.querySelectorAll(".lookup-criteria");
            if (criteriaInputs.length > 0) {
                criteriaInputs[criteriaInputs.length - 1].querySelector("input")?.focus();
            }
        }, 100);
    };

    const onCriteriaChange = (value: string, index: number) => {
        setTempLookups(tempLookups.map((item, i) => (i === index ? { ...item, lookupCriteria: value } : item)));
    };

    const onValueChange = (value: string, lookupIndex: number, refIndex?: number) => {
        let newTempLookups: LookupState[] = [];

        if (lookupVariant === LookupVariants.Value && refIndex !== undefined) {
            newTempLookups = tempLookups.map((item, i) =>
                i === lookupIndex
                    ? { ...item, lookupValue: sanitizeAndSetNumericInput(value, inputRefs[refIndex], { allowNegative: true }) }
                    : item,
            );
        } else if (lookupVariant === LookupVariants.Equation) {
            newTempLookups = tempLookups.map((item, i) => (i === lookupIndex ? { ...item, lookupEquation: value } : item));
        }

        setTempLookups(newTempLookups);
    };

    const onLookupValuePaste = async () => {
        const lookupTable = await navigator.clipboard.readText();

        const newLookups = lookupTable
            .split("\n")
            .map((row) => row.split("\t"))
            .map((rowData) => ({
                lookupCriteria: (rowData[0] ?? "").replace("\r", ""),
                lookupValue: isFinite(Number(rowData[1])) ? Number(rowData[1]) : undefined,
            }))
            // Remove invalid values
            .filter((item) => !isEmpty(item.lookupCriteria) && !isNil(item.lookupValue));

        if (newLookups.length === 0) {
            return;
        }

        const allLookups = [...tempLookups, ...newLookups].map((lookup) => ({ ...lookup, lookupValue: String(lookup.lookupValue) }));

        setTempLookups(allLookups);
        setLookups(allLookups);
        setIsPasted(true);
    };

    const onLookupEquationPaste = async () => {
        const lookupTable = await navigator.clipboard.readText();

        const newLookups = lookupTable
            .split("\n")
            .map((row) => row.split("\t"))
            .map((rowData) => ({
                lookupCriteria: (rowData[0] ?? "").replace("\r", ""),
                lookupEquation: rowData[1] !== "Lookup Equation\r" ? rowData[1] : undefined,
            }))
            // Remove invalid values
            .filter((item) => !isEmpty(item.lookupCriteria) && !isNil(item.lookupEquation));

        if (newLookups.length === 0) {
            return;
        }

        const allLookups = [...tempLookups, ...newLookups];

        setTempLookups(allLookups);
        setLookups(allLookups);
        setIsPasted(true);
    };

    const onCancel = () => {
        // Go to previous page if last item in the current page
        // is about to be removed
        if (paginationItem.lookups?.[currentPage]?.length === 1 && currentPage !== 1) {
            setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
        }

        setEditIndex(-1);
        setTempLookups(lookups);
    };

    const onSave = () => {
        setEditIndex(-1);
        setLookups(tempLookups);
    };

    const onRemove = (index: number) => {
        const newTempLookups = [...tempLookups].filter((_, i) => i !== index);

        // Go to previous page if last item in the current page
        // is about to be removed
        if (paginationItem.lookups?.[currentPage]?.length === 1 && currentPage !== 1) {
            setCurrentPage((prevCurrentPage) => prevCurrentPage - 1);
        }

        setTempLookups(newTempLookups);
        setLookups(newTempLookups);
    };

    const onPageChange = (pageNumber: number) => {
        setEditIndex(-1);
        setCurrentPage(pageNumber);
    };

    return isEmpty(tempLookups) ? (
        <NothingFoundBlock
            icon={subtitlesSlashSolidIcon}
            title="No Lookups"
            message="Added lookups will show here"
            primaryActionLabel="Add Lookup"
            primaryActionIcon={circlePlusRegularIcon}
            secondaryActionLabel={"Paste CSV From Clipboard"}
            onPrimaryActionClick={onAddLookup}
            onSecondaryActionClick={lookupVariant === LookupVariants.Value ? onLookupValuePaste : onLookupEquationPaste}
        />
    ) : (
        <>
            <div className="rounded" style={{ border: "1px solid var(--theme-base-border)" }}>
                <div
                    className="flex flex-row items-center justify-between sticky-top bg-white p-2"
                    style={{ borderBottom: "1px solid var(--theme-base-border)" }}
                >
                    <IdsTextInput
                        defaultValue={search}
                        placeholder="Search..."
                        size="sm"
                        iconLeft="ui-search-search"
                        changeHandler={onSearchChange}
                    />
                    <div className="flex flex-row gap-2">
                        <Tooltip message="Copy to clipboard and paste into Excel." placement="top">
                            <IdsButton padding="sm" variant="tertiary" isDisabled={isEmpty(lookups)} clickHandler={onCopyToClipboard}>
                                <div className="flex flex-row items-center gap-2">
                                    <FontAwesomeIcon icon={fileExportRegularIcon} fixedWidth />
                                    Export CSV
                                </div>
                            </IdsButton>
                        </Tooltip>
                        <IdsButton padding="sm" variant="tertiary" isDisabled={isEditingLookups} clickHandler={onAddLookup}>
                            <div className="flex flex-row items-center gap-2">
                                <FontAwesomeIcon icon={circlePlusRegularIcon} fixedWidth />
                                Add Lookup
                            </div>
                        </IdsButton>
                    </div>
                </div>
                <IdsTable customClasses="pt-2 pr-2 pl-2" spacing="sm">
                    <IdsTableRow rowType="table-heading-row">
                        <IdsTableCell cellType="table-heading-cell" heading="" style={{ minWidth: 0, width: "2rem" }} />
                        <IdsTableCell cellType="table-heading-cell" heading="Criteria" style={{ minWidth: 0, width: "auto" }} />
                        {lookupVariant === LookupVariants.Value && (
                            <IdsTableCell cellType="table-heading-cell" heading="Value" style={{ minWidth: 0, width: "6rem" }} />
                        )}
                        {lookupVariant === LookupVariants.Equation && (
                            <IdsTableCell cellType="table-heading-cell" heading="Equation" style={{ minWidth: 0 }} />
                        )}
                        <IdsTableCell cellType="table-heading-cell" heading="" style={{ minWidth: 0, width: "0" }} />
                    </IdsTableRow>
                    {paginationItem.lookups?.[currentPage]?.map((item, index) => {
                        const lookupIndex = currentPage * DEFAULT_ITEMS_PER_PAGE - DEFAULT_ITEMS_PER_PAGE + index;
                        const isEditing = editIndex === lookupIndex;

                        return (
                            <IdsTableRow key={`lookup-${currentPage}-${index}`} rowType="table-body-row">
                                <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>{lookupIndex + 1}.</IdsTableCell>
                                <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>
                                    <div>
                                        {isEditing ? (
                                            <IdsTextInput
                                                customClasses="lookup-criteria"
                                                defaultValue={item.lookupCriteria}
                                                changeHandler={(value) => onCriteriaChange(value, lookupIndex)}
                                                isInvalid={hasErrors && isEmpty(item.lookupCriteria)}
                                            />
                                        ) : (
                                            <IdsText>
                                                <>{item.lookupCriteria}</>
                                            </IdsText>
                                        )}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>
                                    <div>
                                        {lookupVariant === LookupVariants.Value &&
                                            (isEditing ? (
                                                <IdsTextInput
                                                    customClasses="lookup-value"
                                                    innerRef={inputRefs[index]}
                                                    defaultValue={item.lookupValue ?? ""}
                                                    changeHandler={(value) => onValueChange(value, lookupIndex, index)}
                                                    isInvalid={hasErrors && !item.lookupValue}
                                                />
                                            ) : (
                                                <IdsText>
                                                    <>{item.lookupValue ?? ""}</>
                                                </IdsText>
                                            ))}
                                        {lookupVariant === LookupVariants.Equation &&
                                            (isEditing ? (
                                                <IdsTextInput
                                                    customClasses="lookup-equation"
                                                    defaultValue={item.lookupEquation}
                                                    changeHandler={(value) => onValueChange(value, lookupIndex)}
                                                    isInvalid={hasErrors && !item.lookupEquation}
                                                />
                                            ) : (
                                                <IdsText>
                                                    <>{item.lookupEquation}</>
                                                </IdsText>
                                            ))}
                                    </div>
                                </IdsTableCell>
                                <IdsTableCell style={{ ...getCellStyle(isEditing), minWidth: 0 }}>
                                    <IdsButtonGroup customClasses="flex-no-wrap" position="right" spaceBetween="md">
                                        {isEditing ? (
                                            <>
                                                <IconButton icon={xmarkLightIcon} size="lg" square title="Cancel" onClick={onCancel} />
                                                <IconButton icon={checkLightIcon} size="lg" square title="Save" onClick={onSave} />
                                            </>
                                        ) : (
                                            <>
                                                <IconButton
                                                    icon={trashRegularIcon}
                                                    size="lg"
                                                    square
                                                    title="Remove Criteria"
                                                    isDisabled={isEditingLookups}
                                                    onClick={() => onRemove(lookupIndex)}
                                                />
                                                <IconButton
                                                    icon={penToSquareRegularIcon}
                                                    size="lg"
                                                    square
                                                    title="Edit Criteria"
                                                    isDisabled={isEditingLookups}
                                                    onClick={() => setEditIndex(lookupIndex)}
                                                />
                                            </>
                                        )}
                                    </IdsButtonGroup>
                                </IdsTableCell>
                            </IdsTableRow>
                        );
                    })}
                </IdsTable>
            </div>
            <div className="flex flex-row items-center justify-between pt-2">
                <IdsText>{paginationItem.totalItemsText}</IdsText>
                <div key={`lookups-pagination-${Object.keys(paginationItem.lookups).length}`}>
                    <IdsTablePagination
                        customClasses="input-table-pagination"
                        currentPage={currentPage}
                        totalItems={DEFAULT_ITEMS_PER_PAGE}
                        totalPages={Object.keys(paginationItem.lookups).length}
                        type="input"
                        iconOnly
                        pageChangeHandler={onPageChange}
                    />
                </div>
            </div>
        </>
    );
};
