import { Tile } from "components/Tile";

import { StepName } from "pages/ReviewUpdates";

import { ItemGrid } from "./ItemGrid";

import type { AttributeStaging } from "types";

export const Attribute: React.FC<{
    attribute: StepName;
    items: AttributeStaging[];
    selectedItems: AttributeStaging[];
    setSelectedItems: (selectedItems: AttributeStaging[]) => void;
}> = ({ attribute, items, selectedItems, setSelectedItems }) => {
    return (
        <Tile>
            <div className="p-3">
                <ItemGrid attribute={attribute} items={items} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
            </div>
        </Tile>
    );
};
