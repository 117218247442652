import { IdsTabItem, IdsTabs } from "@emergn-infinity/ids-react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { PdfViewer } from "./PdfViewer";
import { Sectors } from "./Sectors";
import { MeasureDetails } from "./MeasureDetails";
import { Header } from "./Header";
import { useViewerData } from "./useViewerData";
import { Tabs } from "pages/Home/utils";
import * as actions from "pages/Home/homeSlice";
import { useMediaQuery } from "utils/useMediaQuery";
import cn from "classnames";

const TrmViewerTabs = {
    PdfViewer: {
        id: "tab-pdf-viewer",
        name: "OriginalDocument",
    },
    MeasureDetails: {
        id: "tab-measure-history",
        name: "Measure Details",
    },
};

export const TrmViewer = () => {
    const dispatch = useAppDispatch();
    const { selectedTrm, selectedMeasure: measureNumber, sectorNumber } = useAppSelector((state) => state.exploreTrms);

    const [selectedMeasure, setSelectedMeasure] = useState<string | undefined>(measureNumber);
    const [selectedSectorNumber, setSelectedSectorNumber] = useState<string | undefined>(sectorNumber);
    const [activeTab, setActiveTab] = useState<string>(TrmViewerTabs.PdfViewer.id);

    const isDesktop = useMediaQuery("lg");
    const { pageNumber, trmFile } = useViewerData(selectedTrm, selectedSectorNumber, selectedMeasure);

    return (
        <div
            className={cn("h-full overflow-y-auto overflow-x-hidden", {
                "flex flex-col": !isDesktop,
                "grid grid-cols-2 gap-x-6 grid-rows-[auto_1fr]": isDesktop,
            })}
        >
            <Header onClose={() => dispatch(actions.setActiveTab(Tabs.ExploreTrms.id))} />

            {/* Sectors */}
            <div
                className="px-5 col-span-full sticky top-0 z-20 h-fit"
                style={{
                    backgroundColor: "var(--ids-semantic-background-color-neutral-subtlest-default)",
                }}
            >
                <Sectors selectedSectorNumber={selectedSectorNumber} onSectorChange={setSelectedSectorNumber} />
            </div>

            {isDesktop ? (
                <>
                    {/* Left Column - Sticky below the header */}
                    <div className="pl-8 py-3 sticky top-[64px] h-[calc(100dvh-164px)]">
                        <div className="h-full overflow-hidden border">
                            <PdfViewer file={trmFile} pageNumber={pageNumber} />
                        </div>
                    </div>

                    {/* Right Column - Scrollable content */}
                    <div className="pr-8 py-3">
                        <MeasureDetails
                            selectedMeasure={selectedMeasure}
                            selectedSectorNumber={selectedSectorNumber}
                            onMeasureChange={setSelectedMeasure}
                        />
                    </div>
                </>
            ) : (
                <>
                    <IdsTabs
                        id="trm-viewer-tabs"
                        className="col-span-full px-5 min-h-0 flex-shrink-0 sticky top-[64px] bg-white z-20"
                        style={{
                            backgroundColor: "var(--ids-semantic-background-color-neutral-subtlest-default)",
                        }}
                    >
                        {Object.values(TrmViewerTabs).map((tab) => (
                            <IdsTabItem
                                key={`tab-header-${tab.id}`}
                                slot="header"
                                idValue={tab.id}
                                label={tab.name}
                                isActive={tab.id === activeTab}
                                onClick={() => setActiveTab(tab.id)}
                                customClasses="bg-inherit"
                            />
                        ))}
                    </IdsTabs>
                    <div
                        className={cn("px-5 py-6 h-[calc(100dvh-185px)] flex-shrink-0", {
                            hidden: activeTab !== TrmViewerTabs.PdfViewer.id,
                        })}
                    >
                        <PdfViewer file={trmFile} pageNumber={pageNumber} />
                    </div>
                    <div
                        className={cn("px-5 py-6", {
                            hidden: activeTab !== TrmViewerTabs.MeasureDetails.id,
                        })}
                    >
                        <MeasureDetails
                            selectedMeasure={selectedMeasure}
                            selectedSectorNumber={selectedSectorNumber}
                            onMeasureChange={setSelectedMeasure}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
