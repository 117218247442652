import { isEmpty, map } from "lodash";
import { useCallback, useState } from "react";

import {
    IdsButton,
    IdsButtonGroup,
    IdsDropdown,
    IdsFieldWrapper,
    IdsHelper,
    IdsRadioButton,
    IdsRadioButtonGroup,
    IdsLabel,
    IdsText,
    IdsTextInput,
} from "@emergn-infinity/ids-react";

import { AccessLevels, LinkFormats, DocumentTypes } from "utils/constants";

import type { LinkSaveParams } from ".";

export const LinkEdit: React.FC<{
    linkNumber?: string;
    availability?: string;
    format?: string;
    documentType?: string;
    linkName?: string;
    linkDescription?: string;
    urlLink?: string;
    isLoading?: boolean;
    onSave?: (params: LinkSaveParams) => void;
    onCancel?: () => void;
}> = (props) => {
    const [availability, setAvailability] = useState<string>(props.availability ?? AccessLevels.Public);
    const [format, setFormat] = useState<string>(props.format ?? LinkFormats.PDF);
    const [linkName, setLinkName] = useState(props.linkName ?? "");
    const [linkDescription, setLinkDescription] = useState(props.linkDescription ?? "");
    const [documentType, setDocumentType] = useState(props.documentType ?? "");
    const [urlLink, setUrlLink] = useState(props.urlLink ?? "");
    const [errors, setErrors] = useState<LinkErrors>({});

    const onChange = (value: string, name: string) => {
        switch (name) {
            case "availability":
                setAvailability(value);

                break;

            case "format":
                setFormat(value);

                break;

            case "linkName":
                setLinkName(value);

                break;

            case "linkDescription":
                setLinkDescription(value);

                break;

            case "documentType":
                setDocumentType(value);

                break;

            case "urlLink":
                setUrlLink(value);

                break;
        }

        setErrors({
            ...errors,
            [name]: undefined,
        });
    };

    const onClearAll = useCallback(() => {
        setFormat(LinkFormats.PDF);
        setLinkName("");
        setLinkDescription("");
        setUrlLink("");
    }, []);

    const onPaste = useCallback(async () => {
        const url = await navigator.clipboard.readText();

        setUrlLink(url);
    }, []);

    const onCancelClick = () => {
        setErrors({});

        props.onCancel?.();
    };

    const onSaveClick = () => {
        if (isEmpty(linkName) || isEmpty(urlLink)) {
            setErrors({
                linkName: isEmpty(linkName) ? "Name is required" : undefined,
                urlLink: isEmpty(urlLink) ? "URL is required" : undefined,
            });

            return;
        }

        props.onSave?.({ availability, format, documentType, linkName, urlLink, linkDescription, linkNumber: props.linkNumber });
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between pb-2">
                <IdsText weight="bold">New link</IdsText>
                <IdsButton variant="tertiary" clickHandler={onClearAll}>
                    Clear All
                </IdsButton>
            </div>
            {/* TODO: Add Tailwind classes */}
            <div className="flex flex-col" style={{ paddingBottom: "1.5rem" }}>
                {/* TODO: Add Tailwind classes */}
                <IdsRadioButtonGroup customClasses="pt-1" horizontalOrientation style={{ paddingBottom: "1rem" }}>
                    <IdsRadioButton
                        idValue={AccessLevels.Public}
                        label={AccessLevels.Public}
                        defaultChecked={availability === AccessLevels.Public}
                        changeHandler={(e: any) => onChange(e.target.id, "availability")}
                    />
                    <IdsRadioButton
                        idValue={AccessLevels.Confidential}
                        label={AccessLevels.Confidential}
                        defaultChecked={availability === AccessLevels.Confidential}
                        changeHandler={(e: any) => onChange(e.target.id, "availability")}
                    />
                </IdsRadioButtonGroup>
                <IdsFieldWrapper htmlFor="format" wrapperLabel="Format" isRequired>
                    <IdsDropdown
                        idValue="format"
                        items={map(LinkFormats, (format) => ({ value: format, label: format }))}
                        initialSelectedItems={[format]}
                        changeHandler={(value) => onChange(value, "format")}
                    />
                </IdsFieldWrapper>
                <IdsFieldWrapper
                    customClasses="w-full"
                    htmlFor="name"
                    wrapperLabel="Name"
                    isInvalid={!isEmpty(errors.linkName)}
                    helperInvalidText={errors.linkName}
                    isRequired
                >
                    <IdsTextInput idValue="name" defaultValue={linkName} changeHandler={(value) => onChange(value, "linkName")} />
                </IdsFieldWrapper>
                <IdsFieldWrapper customClasses="w-full" htmlFor="description" wrapperLabel="Description">
                    <IdsTextInput
                        idValue="description"
                        defaultValue={linkDescription}
                        changeHandler={(value) => onChange(value, "linkDescription")}
                    />
                </IdsFieldWrapper>
                <IdsFieldWrapper htmlFor="document-type" wrapperLabel="Document Type" isRequired>
                    <IdsDropdown
                        idValue="document-type"
                        placeholder="Select a document type"
                        items={map(DocumentTypes, (type) => ({ value: type, label: type }))}
                        initialSelectedItems={[documentType]}
                        changeHandler={(value) => onChange(value, "documentType")}
                    />
                </IdsFieldWrapper>
                <div className="flex flex-row items-center justify-between pb-2">
                    <IdsLabel htmlFor="url" wrapperLabel="URL" isRequired />
                    <IdsButton variant="tertiary" padding="xs" clickHandler={onPaste}>
                        Paste from clipboard
                    </IdsButton>
                </div>
                <IdsTextInput
                    idValue="url"
                    defaultValue={urlLink}
                    isInvalid={!isEmpty(errors.urlLink)}
                    changeHandler={(value) => onChange(value, "urlLink")}
                />
                <IdsHelper customClasses="pt-1" isInvalid={!isEmpty(errors.urlLink)} helperInvalidText={errors.urlLink} />
            </div>
            <IdsButtonGroup spaceBetween="lg">
                <IdsButton variant="secondary" clickHandler={onCancelClick}>
                    Cancel
                </IdsButton>
                <IdsButton clickHandler={onSaveClick}>
                    <>{props.isLoading ? "Saving..." : "Save Link"}</>
                </IdsButton>
            </IdsButtonGroup>
        </div>
    );
};

type LinkErrors = {
    linkName?: string;
    urlLink?: string;
};
