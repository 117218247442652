import { createSlice } from "@reduxjs/toolkit";

import { saveStateInStorage, loadStateFromStorage } from "store/utils";

import { Tabs } from "./utils";

import type { PayloadAction } from "@reduxjs/toolkit";

const STATE_KEY = "home-state";

const initialState: HomeState = {
    activeTab: Tabs.ExploreTrms.id,
};

export const homeSlice = createSlice({
    name: "home",
    initialState: loadStateFromStorage(STATE_KEY, initialState),
    reducers: {
        setActiveTab: (state, action: PayloadAction<Tab>) => {
            state.activeTab = action.payload;

            saveStateInStorage(STATE_KEY, state);
        },
    },
});

type Tab = (typeof Tabs)[keyof typeof Tabs]["id"];

type HomeState = {
    activeTab: Tab;
};

export const { setActiveTab } = homeSlice.actions;

export default homeSlice.reducer;
