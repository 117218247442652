import { IdsTagGroup, IdsTag } from "@emergn-infinity/ids-react";
import { isEmpty } from "lodash";
import { useGetSectorsQuery } from "store/apiSlice";
import { useAppSelector } from "store/hooks";
import { useMediaQuery } from "utils/useMediaQuery";

export const Sectors: React.FC<{ selectedSectorNumber?: string; onSectorChange: (sector: string) => void }> = ({
    selectedSectorNumber,
    onSectorChange,
}) => {
    const isDesktop = useMediaQuery("lg");
    const { selectedTrm } = useAppSelector((state) => state.exploreTrms);

    const { data: sectors } = useGetSectorsQuery(
        { trmNumber: selectedTrm },
        {
            skip: isEmpty(selectedTrm),
        },
    );

    return (
        <IdsTagGroup
            customClasses="py-3"
            style={{ borderBottom: isDesktop ? "var(--ids-tabs-separator-border-width) solid var(--ids-tabs-separator-color)" : undefined }}
        >
            {sectors?.map((sector) => (
                <IdsTag
                    key={sector.sectorNumber}
                    variant="brand-c"
                    isActive={sector.sectorNumber === selectedSectorNumber}
                    clickHandler={() => onSectorChange(sector.sectorNumber)}
                    size="sm"
                >
                    {sector.sectorName}
                </IdsTag>
            ))}
        </IdsTagGroup>
    );
};
