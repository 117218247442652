import cn from "classnames";

import "./style.scss";

export const Badge: React.FC<{
    variant?: "brand" | "critical" | "success" | "neutral";
    size?: "sm" | "md" | "lg";
    number?: number;
    withoutLimit?: boolean;
    style?: React.CSSProperties;
}> = ({ variant = "brand", size = "sm", number, withoutLimit, style }) => {
    let content: string | null = null;

    if (number !== undefined) {
        content = number > 9 && !withoutLimit ? "+9" : String(number);
    }

    return (
        <div className={cn("flex-column justify-center text-center badge", variant, size)} style={style}>
            {content}
        </div>
    );
};

export type BadgeVariant = "brand" | "critical" | "success" | "neutral";
