import cn from "classnames";

import { IdsButtonGroup, IdsButton, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";

import { useAppDispatch, useAppSelector } from "store/hooks";

import { xmarkLightIcon } from "utils/icons";
import { useClickAway } from "utils/useClickAway";

import { setIsOpen } from "./menuSlice";

import type { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import type { IdsButtonColor, IdsButtonPadding, IdsButtonVariant } from "types";

import "./style.scss";

export const Menu: React.FC<{
    id: string;
    buttonLabel?: string;
    buttonIcon?: IconProp;
    buttonIconTitle?: string;
    buttonIconBefore?: IconProp;
    buttonIconAfter?: IconProp;
    buttonIconSize?: SizeProp;
    buttonVariant?: IdsButtonVariant;
    buttonColor?: IdsButtonColor;
    buttonPadding?: IdsButtonPadding;
    buttonIsDisabled?: boolean;
    buttonFullWidth?: boolean;
    actionLabel?: string;
    actionIsDisabled?: boolean;
    header?: string;
    width?: number;
    isSubMenu?: boolean;
    children: React.ReactNode;
    onOpen?: () => void;
    onAction?: () => void;
    onClose?: () => void;
}> = ({
    id,
    buttonLabel,
    buttonIcon,
    buttonIconTitle,
    buttonIconBefore,
    buttonIconAfter,
    buttonIconSize,
    buttonVariant = "primary",
    buttonColor = "brand",
    buttonPadding = "md",
    buttonIsDisabled,
    buttonFullWidth,
    actionLabel,
    actionIsDisabled,
    header,
    width,
    isSubMenu,
    children,
    onOpen,
    onAction,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const menuState = useAppSelector((state) => state.menu[id]);
    const isMainMenuOpen = menuState?.isMainMenuOpen;
    const isSubMenuOpen = menuState?.isSubMenuOpen;

    const isOpen = Boolean((!isSubMenu && isMainMenuOpen) || (isSubMenu && isSubMenuOpen));
    const renderButtons = actionLabel !== undefined;

    const onCancelClick = () => {
        dispatch(setIsOpen({ id, isMainMenuOpen: false, isSubMenuOpen: false }));

        onClose?.();
    };

    const menuRef = useClickAway<HTMLDivElement>(onCancelClick);

    const onButtonClick = () => {
        if (isSubMenu) {
            dispatch(setIsOpen({ id, isMainMenuOpen: true, isSubMenuOpen: !isSubMenuOpen }));
        } else {
            dispatch(setIsOpen({ id, isMainMenuOpen: !isMainMenuOpen, isSubMenuOpen: false }));
        }

        if (isOpen) {
            onClose?.();
        } else if (!isSubMenu) {
            onOpen?.();
        }
    };

    const onActionClick = () => {
        dispatch(setIsOpen({ id, isMainMenuOpen: false, isSubMenuOpen: false }));

        onAction?.();
    };

    return (
        <div ref={!isSubMenu ? menuRef : undefined} className="menu">
            {/* Label + Icons */}
            {buttonLabel && !buttonIcon && (
                <IdsButton
                    customClasses={cn("menu--activator", {
                        "menu--activator--open": isOpen,
                    })}
                    variant={buttonVariant}
                    color={buttonColor}
                    padding={buttonPadding}
                    isDisabled={buttonIsDisabled}
                    fullWidth={buttonFullWidth}
                    clickHandler={onButtonClick}
                >
                    {buttonIconBefore || buttonIconAfter ? (
                        <span className="flex flex-row items-center gap-2">
                            {buttonIconBefore && <FontAwesomeIcon icon={buttonIconBefore} size={buttonIconSize} fixedWidth />}
                            {buttonLabel}
                            {buttonIconAfter && <FontAwesomeIcon icon={buttonIconAfter} size={buttonIconSize} fixedWidth />}
                        </span>
                    ) : (
                        buttonLabel
                    )}
                </IdsButton>
            )}
            {/* Icon only */}
            {buttonIcon && !buttonLabel && (
                <div className={cn("menu--activator", { "menu--activator--open": isOpen })}>
                    <IconButton icon={buttonIcon} size={buttonIconSize} title={buttonIconTitle} onClick={onButtonClick} />
                </div>
            )}

            {isOpen && (
                <div className="rounded-edges-rounder menu--list-container" style={{ width }}>
                    {header && (
                        <div
                            className="flex flex-row items-center justify-between"
                            // TODO: Add Tailwind classes
                            style={{ paddingTop: "1rem", paddingRight: "1rem", paddingLeft: "1rem" }}
                        >
                            <IdsText weight="bold">{header}</IdsText>
                            <IconButton icon={xmarkLightIcon} size="lg" onClick={onCancelClick} />
                        </div>
                    )}
                    <div
                        // TODO: Add Tailwind classes
                        style={{
                            borderBottom: renderButtons ? "2px solid var(--theme-base-border)" : undefined,
                            paddingTop: "1rem",
                            paddingBottom: renderButtons ? "1rem" : undefined,
                            paddingRight: "1rem",
                            paddingLeft: "1rem",
                        }}
                    >
                        {children}
                    </div>
                    {renderButtons && (
                        // TODO: Add Tailwind classes
                        <IdsButtonGroup spaceBetween="md" style={{ padding: "1rem" }}>
                            <IdsButton padding="sm" isDisabled={actionIsDisabled} clickHandler={onActionClick}>
                                {actionLabel}
                            </IdsButton>
                            <IdsButton variant="secondary" padding="sm" clickHandler={onCancelClick}>
                                Cancel
                            </IdsButton>
                        </IdsButtonGroup>
                    )}
                </div>
            )}
        </div>
    );
};
