import { isEmpty } from "lodash";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
    IdsButtonGroup,
    IdsCheckbox,
    IdsTable,
    IdsTableCell,
    IdsTableRow,
    IdsTag,
    IdsText,
    IdsTextInput,
} from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconButton } from "components/IconButton";
import { NothingFoundBlock } from "components/NothingFoundBlock";

import { checkLightIcon, circleInfoRegularIcon, magnifyingGlassSolidIcon, penToSquareRegularIcon, xmarkLightIcon } from "utils/icons";

import type { AttributeStaging } from "types";
import type { StepName } from "pages/ReviewUpdates";

const style = {
    select: {
        verticalAlign: "middle",
        minWidth: 0,
        width: 44,
    },
    status: {
        verticalAlign: "middle",
        minWidth: "5.125rem",
    },
    attribute: {
        verticalAlign: "middle",
        width: "100%",
    },
    edit: {
        verticalAlign: "middle",
        minWidth: 0,
    },
};

const tagVariants = {
    create: "success",
    skip: "critical",
    copy: "neutral",
};

const tagLabels = {
    create: "Added",
    skip: "Removed",
    copy: "Existing",
};

export const Grid: React.FC<{
    attribute: StepName;
    items: AttributeStaging[];
    allGridItems: AttributeStaging[];
    selectedItems: AttributeStaging[];
    isExistingTab: boolean;
    setAllGridItems: (allGridItems: AttributeStaging[]) => void;
    setSelectedItems: (selectedItems: AttributeStaging[]) => void;
}> = ({ attribute, items, allGridItems, selectedItems, isExistingTab, setAllGridItems, setSelectedItems }) => {
    const [editIndex, setEditIndex] = useState(-1);

    const selectableItems = items.filter((item) => item.task !== "copy");
    const areAllRowsSelected = selectedItems.length === selectableItems.length;

    const onSelectAll = () => {
        // Deselect all
        if (areAllRowsSelected) {
            setSelectedItems([]);
        }
        // Select all
        else {
            setSelectedItems(selectableItems);
        }
    };

    const onSelect = (item: AttributeStaging) => {
        // Deselect item
        if (selectedItems.find((it) => it.name === item.name)) {
            setSelectedItems(selectedItems.filter((it) => it.name !== item.name));
        }
        // Select item
        else {
            setSelectedItems([...selectedItems, item]);
        }
    };

    const onSave = (name: string) => {
        const value = (document.getElementById(`item-${editIndex}`) as HTMLInputElement)?.value;

        const newAllGridItems = [...allGridItems];

        newAllGridItems[editIndex].name = value;

        setAllGridItems(newAllGridItems);

        // Update selected items if name that is about to change
        // is part of the list
        if (selectedItems.find((it) => it.name === name)) {
            const newSelectedItems = [...selectedItems];

            newSelectedItems[editIndex].name = value;

            setSelectedItems(newSelectedItems);
        }

        setEditIndex(-1);
    };

    return (
        <div>
            <div className="flex-row align-center gap-2 px-2 py-3">
                <FontAwesomeIcon icon={circleInfoRegularIcon} color="var(--ids-semantic-ink-color-neutral-subtle-onlight)" />
                <IdsText style={{ color: "var(--ids-semantic-ink-color-neutral-subtle-onlight)" }}>
                    Only the selected changes will be applied.
                </IdsText>
            </div>
            {isEmpty(items) ? (
                <NothingFoundBlock icon={magnifyingGlassSolidIcon} title="No Results" />
            ) : (
                <IdsTable spacing="lg">
                    <IdsTableRow rowType="table-heading-row">
                        {!isExistingTab && (
                            <IdsTableCell style={style.select}>
                                <IdsCheckbox idValue="select-all" defaultChecked={areAllRowsSelected} changeHandler={onSelectAll} />
                            </IdsTableCell>
                        )}
                        <IdsTableCell cellType="table-heading-cell" heading="Status" style={style.status} />
                        <IdsTableCell cellType="table-heading-cell" heading={attribute} style={style.attribute} />
                        <IdsTableCell cellType="table-heading-cell" style={style.edit} />
                    </IdsTableRow>
                    {items.map((item, index) => (
                        <IdsTableRow key={`item-${uuidv4()}`}>
                            {!isExistingTab && (
                                <IdsTableCell style={style.select}>
                                    {item.task !== "copy" && (
                                        <IdsCheckbox
                                            defaultChecked={selectedItems.find((it) => it.name === item.name) !== undefined}
                                            changeHandler={() => onSelect(item)}
                                        />
                                    )}
                                </IdsTableCell>
                            )}
                            <IdsTableCell style={style.status}>
                                <IdsTag variant={tagVariants[item.task as string]}>{tagLabels[item.task]}</IdsTag>
                            </IdsTableCell>
                            <IdsTableCell style={style.attribute}>
                                <div>
                                    {editIndex === index ? (
                                        <IdsTextInput idValue={`item-${index}`} defaultValue={item.name} size="sm" />
                                    ) : (
                                        <IdsText>{item.name}</IdsText>
                                    )}
                                </div>
                            </IdsTableCell>
                            <IdsTableCell style={style.edit}>
                                <div>
                                    {item.task === "create" &&
                                        (editIndex === index ? (
                                            <IdsButtonGroup customClasses="flex-no-wrap" spaceBetween="md">
                                                <IconButton
                                                    icon={xmarkLightIcon}
                                                    title="Cancel"
                                                    size="lg"
                                                    square
                                                    onClick={() => setEditIndex(-1)}
                                                />
                                                <IconButton
                                                    icon={checkLightIcon}
                                                    title="Save"
                                                    size="lg"
                                                    square
                                                    onClick={() => onSave(item.name)}
                                                />
                                            </IdsButtonGroup>
                                        ) : (
                                            <IconButton
                                                icon={penToSquareRegularIcon}
                                                title="Edit"
                                                size="lg"
                                                onClick={() => setEditIndex(index)}
                                            />
                                        ))}
                                </div>
                            </IdsTableCell>
                        </IdsTableRow>
                    ))}
                </IdsTable>
            )}
        </div>
    );
};
