import { isEmpty } from "lodash";
import { useMemo, useState } from "react";

import { useGetMeasuresByTrmQuery, useGetTrmsQuery } from "store/apiSlice";

import { useAppDispatch, useAppSelector } from "store/hooks";

import { selectorChange } from "../exploreTrmsSlice";

import { IdsCol, IdsCard, IdsDropdown, IdsText, IdsDivider } from "@emergn-infinity/ids-react";

import { CustomRadio } from "components/CustomRadio";

import { TrmFilters, TrmGlossaryAttributes } from "pages/ExploreTrms/utils";
import { setActiveTab } from "pages/Home/homeSlice";
import { Tabs } from "pages/Home/utils";

import { TrmRights, MeasuresRights } from "utils/constants";
import { fileCheckRegularIcon, filesRegularIcon } from "utils/icons";
import { hasAllRights } from "utils/user";

import { AttributeTable } from "./AttributeTable";

export const Sidebar: React.FC<{
    selectedTrm: string;
    selectedMeasure: string;
    selectTrmRef: React.RefObject<HTMLIdsDropdownElement>;
    selectMeasureRef: React.RefObject<HTMLIdsDropdownElement>;
    onTrmSelect: (trmNumber: string) => void;
    onMeasureSelect: (measureNumber: string) => void;
    onMeasureClear: () => void;
}> = ({ selectedTrm, selectedMeasure, selectTrmRef, selectMeasureRef, onTrmSelect, onMeasureSelect, onMeasureClear }) => {
    const dispatch = useAppDispatch();
    const { filter } = useAppSelector((state) => state.exploreTrms);

    const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(TrmGlossaryAttributes.Sectors);

    const isInternalUser = hasAllRights([TrmRights, MeasuresRights]);

    const {
        data: trms,
        isLoading: isLoadingTrms,
        isFetching: isFetchingTrms,
    } = useGetTrmsQuery(
        { status: filter === TrmFilters.Active },
        {
            refetchOnMountOrArgChange: true,
        },
    );

    const trmList = useMemo(
        () =>
            (trms ?? []).map((trm) => ({
                value: trm.trmNumber,
                label: trm.trmFamiliarName,
            })),
        [trms],
    );

    const { data: measures, isLoading: isLoadingMeasures } = useGetMeasuresByTrmQuery(
        {
            trmNumber: selectedTrm,
            measureRelated: filter === TrmFilters.Active ? true : undefined,
            measureVerified: !isInternalUser ? true : undefined,
        },
        { skip: isEmpty(selectedTrm) },
    );
    const measureList = useMemo(
        () =>
            (measures ?? []).map((measure) => ({
                value: measure.measureNumber,
                label: measure.measureName,
            })),
        [measures],
    );

    const onStatusChange = (id: string) => {
        dispatch(selectorChange({ selector: "filter", value: id }));
        onTrmSelect("");
    };

    const onOpenTrmViewer = (sectorNumber: string) => {
        dispatch(selectorChange({ selector: "sector", value: sectorNumber }));
        dispatch(setActiveTab(Tabs.TrmViewer.id));
    };

    return (
        <IdsCol xs="3">
            <IdsCard customClasses="shadow-none h-full overflow-auto" style={{ borderRight: "1px solid var(--theme-base-border)" }}>
                {/* TODO: Change gap-3 to gap-4 */}
                <div slot="slot1" className="flex flex-col h-full gap-3">
                    {/* Reset dropdown when status changes */}
                    <div key={`trm-dropdown-${filter}`} className="flex flex-col gap-2">
                        <IdsText customClasses="sidebar-title" weight="bold">
                            Select TRM & Measure
                        </IdsText>
                        {isInternalUser && (
                            <div className="flex flex-row gap-2 py-1">
                                <CustomRadio
                                    className="w-full"
                                    id={TrmFilters.Active}
                                    name="trms-and-measures-filter"
                                    label="Show Active"
                                    defaultChecked={filter === TrmFilters.Active}
                                    icon={fileCheckRegularIcon}
                                    onClick={() => onStatusChange(TrmFilters.Active)}
                                />
                                <CustomRadio
                                    className="w-full"
                                    id={TrmFilters.All}
                                    name="trms-and-measures-filter"
                                    label="Show All"
                                    defaultChecked={filter === TrmFilters.All}
                                    icon={filesRegularIcon}
                                    onClick={() => onStatusChange(TrmFilters.All)}
                                />
                            </div>
                        )}
                        <IdsDropdown
                            ref={selectTrmRef}
                            idValue="select-trm"
                            isSearchable={!isLoadingTrms && !isFetchingTrms} // A hack to properly preselect the dropdown value
                            items={trmList}
                            initialSelectedItems={selectedTrm ? [selectedTrm] : []}
                            placeholder={isLoadingTrms ? "Loading" : "Type to Search"}
                            changeHandler={onTrmSelect}
                            clearHandler={() => onTrmSelect("")}
                        />
                        <IdsDropdown
                            key={`measure-dropdown-${selectedTrm}`}
                            ref={selectMeasureRef}
                            idValue="select-measure"
                            isSearchable={!isLoadingMeasures} // A hack to properly preselect the dropdown value
                            isDisabled={isEmpty(selectedTrm)}
                            items={measureList}
                            initialSelectedItems={selectedMeasure ? [selectedMeasure] : []}
                            placeholder={isLoadingMeasures ? "Loading" : "Type to Search"}
                            changeHandler={onMeasureSelect}
                            clearHandler={onMeasureClear}
                        />
                    </div>
                    {!isEmpty(selectedTrm) && (
                        <>
                            <IdsDivider customClasses="!my-2" style={{ backgroundColor: "var(--theme-base-border)" }} />
                            {/* TODO: Change gap-4 to gap-6 */}
                            <div className="flex flex-col flex-1 gap-4 pb-4">
                                <div className="flex flex-col gap-2">
                                    <IdsText weight="bold">TRM Glossary</IdsText>
                                    <div className="flex flex-row items-center gap-2">
                                        <IdsText>Show all:</IdsText>
                                        <IdsDropdown
                                            idValue="select-attribute"
                                            size="sm"
                                            items={Object.values(TrmGlossaryAttributes).map((attribute) => ({
                                                value: attribute,
                                                label: attribute,
                                            }))}
                                            initialSelectedItems={selectedAttribute ? [selectedAttribute] : []}
                                            placeholder="Select Tab"
                                            changeHandler={(value) => setSelectedAttribute(value as Attribute)}
                                            style={{ width: "10rem" }}
                                        />
                                    </div>
                                </div>
                                <AttributeTable selectedAttribute={selectedAttribute} onOpenTrmViewer={onOpenTrmViewer} />
                                <AttributeTable isVerified selectedAttribute={selectedAttribute} onOpenTrmViewer={onOpenTrmViewer} />
                            </div>
                        </>
                    )}
                </div>
            </IdsCard>
        </IdsCol>
    );
};

export type Attribute = (typeof TrmGlossaryAttributes)[keyof typeof TrmGlossaryAttributes];
