export const Tabs = {
    CurrentLandscape: {
        id: "tab-current-landscape",
        name: "Current Landscape",
        reportId: process.env.REACT_APP_CURRENT_LANDSCAPE_REPORT_ID,
    },
    MeasureHistory: {
        id: "tab-measure-history",
        name: "Measure History",
        reportId: process.env.REACT_APP_MEASURE_HISTORY_REPORT_ID,
    },
} as const;
