import { IdsDropdown } from "@emergn-infinity/ids-react";

import type { DropdownItem } from "types";

export const MenuContent: React.FC<{
    selectedStdMeasure?: string;
    selectedStdSector?: string;
    selectedStdEndUse?: string;
    selectedStdVintage?: string;
    selectedFuelType?: string;
    stdMeasureList: DropdownItem[];
    stdSectorList: DropdownItem[];
    stdEndUseList: DropdownItem[];
    stdVintageList: DropdownItem[];
    fuelTypeList: DropdownItem[];
    onChange: (value: string, name: string) => void;
}> = ({
    selectedStdMeasure,
    selectedStdSector,
    selectedStdEndUse,
    selectedStdVintage,
    selectedFuelType,
    stdMeasureList,
    stdSectorList,
    stdEndUseList,
    stdVintageList,
    fuelTypeList,
    onChange,
}) => {
    return (
        // TODO: Remove style attribute and add "gap-4" class
        <div className="flex flex-col" style={{ gap: "1rem" }}>
            <IdsDropdown
                size="sm"
                idValue="std-measures"
                isSearchable
                placeholder="Search Standard Measures"
                items={stdMeasureList}
                initialSelectedItems={selectedStdMeasure ? [selectedStdMeasure] : undefined}
                changeHandler={(stdMeasure) => onChange(stdMeasure, "stdMeasure")}
                clearHandler={() => onChange("", "stdMeasure")}
            />
            <IdsDropdown
                size="sm"
                idValue="std-sectors"
                isSearchable
                placeholder="Search Standard Sectors"
                items={stdSectorList}
                initialSelectedItems={selectedStdSector ? [selectedStdSector] : undefined}
                changeHandler={(stdSector) => onChange(stdSector, "stdSector")}
                clearHandler={() => onChange("", "stdSector")}
            />
            <IdsDropdown
                size="sm"
                idValue="std-end-uses"
                isSearchable
                placeholder="Search Standard End Uses"
                items={stdEndUseList}
                initialSelectedItems={selectedStdEndUse ? [selectedStdEndUse] : undefined}
                changeHandler={(stdEndUse) => onChange(stdEndUse, "stdEndUse")}
                clearHandler={() => onChange("", "stdEndUse")}
            />
            <IdsDropdown
                size="sm"
                idValue="std-vintages"
                isSearchable
                placeholder="Search Standard Vintages"
                items={stdVintageList}
                initialSelectedItems={selectedStdVintage ? [selectedStdVintage] : undefined}
                changeHandler={(stdVintage) => onChange(stdVintage, "stdVintage")}
                clearHandler={() => onChange("", "stdVintage")}
            />
            <IdsDropdown
                size="sm"
                idValue="std-fuel-type"
                isSearchable
                placeholder="Search Fuel Types"
                items={fuelTypeList}
                initialSelectedItems={selectedFuelType ? [selectedFuelType] : undefined}
                changeHandler={(fuelType) => onChange(fuelType, "fuelType")}
                clearHandler={() => onChange("", "fuelType")}
            />
        </div>
    );
};
