import { IdsDropdown, IdsHelper } from "@emergn-infinity/ids-react";
import { NothingFoundBlock } from "components/NothingFoundBlock";
import { Tile } from "components/Tile";
import { isEmpty } from "lodash";
import { MeasureTiles } from "pages/ExploreTrms/MeasureTiles";
import { useMemo, useRef } from "react";
import { useGetMeasuresByTrmQuery, useGetSectorPagesQuery } from "store/apiSlice";
import { useAppSelector } from "store/hooks";
import { fileSlashSolidIcon } from "utils/icons";

export const MeasureDetails: React.FC<{
    selectedSectorNumber?: string;
    selectedMeasure?: string;
    onMeasureChange: (measure?: string) => void;
}> = ({ selectedSectorNumber, selectedMeasure, onMeasureChange }) => {
    const { selectedTrm } = useAppSelector((state) => state.exploreTrms);
    const { measures, sectorPages, isLoading } = useSectorMeasures(selectedTrm, selectedSectorNumber);

    const showSectorPagesError =
        !isEmpty(measures) &&
        !isEmpty(sectorPages) &&
        !isEmpty(selectedMeasure) &&
        !sectorPages?.some((i) => i.measureNumber === selectedMeasure);

    // NOTE: This is a workaround to trigger the dropdown to open when the "Choose Measure" button is clicked
    const selectMeasureRef = useRef<HTMLIdsDropdownElement>(null);
    const onChooseMeasure = () => {
        setTimeout(() => {
            selectMeasureRef.current?.querySelector("ids-icon")?.click();
        }, 100);
    };

    return (
        <>
            <Tile title="Measure">
                <div className="flex flex-col p-3 gap-2">
                    <IdsDropdown
                        ref={selectMeasureRef}
                        idValue="select-measure"
                        isSearchable={!isLoading}
                        items={measures ?? []}
                        initialSelectedItems={selectedMeasure ? [selectedMeasure] : []}
                        placeholder={isLoading ? "Loading" : "Type to Search"}
                        changeHandler={onMeasureChange}
                        clearHandler={() => onMeasureChange(undefined)}
                    />
                    {showSectorPagesError && (
                        <IdsHelper
                            helperInvalidText="Selected measure has no sector pages."
                            helperInvalidIcon="ui-alert-alert_circle"
                            isInvalid
                        />
                    )}
                </div>
            </Tile>
            {!selectedMeasure && (
                <div className="h-80">
                    <NothingFoundBlock
                        icon={fileSlashSolidIcon}
                        title="No Measure Chosen"
                        message="Please select a measure to view its details."
                        secondaryActionLabel="Choose Measure"
                        onSecondaryActionClick={onChooseMeasure}
                    />
                </div>
            )}
            {selectedMeasure && !showSectorPagesError && (
                <div key={selectedMeasure} className="flex flex-col gap-3 pt-3 overflow-hidden">
                    <MeasureTiles key={`${selectedTrm}-${selectedMeasure}`} sectorNumber={selectedSectorNumber} compact readOnly />
                </div>
            )}
        </>
    );
};

const useSectorMeasures = (trmNumber: string, sectorNumber?: string) => {
    const { data: sectorPages, isLoading: isLoadingSectorPages } = useGetSectorPagesQuery(
        { sectorNumber: sectorNumber! },
        {
            skip: isEmpty(sectorNumber),
        },
    );

    const { data, isLoading: isLoadingMeasures } = useGetMeasuresByTrmQuery(
        { trmNumber, measureRelated: true },
        { skip: isEmpty(trmNumber) },
    );

    const measures = useMemo(
        () =>
            data?.map((measure) => ({
                value: measure.measureNumber,
                label: measure.measureName,
            })),
        [data],
    );

    return { measures, sectorPages, isLoading: isLoadingSectorPages || isLoadingMeasures };
};
