import { useEffect } from "react";

export const useObjectUrl = (file: Blob | undefined, pageNumber: number) => {
    const url = file ? `${URL.createObjectURL(file)}#page=${pageNumber}` : undefined;

    useEffect(() => {
        if (!url) return;

        return () => {
            URL.revokeObjectURL(url);
        };
    }, [url]);

    return url;
};
