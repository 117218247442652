import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { IdsText, IdsButton, IdsButtonGroup } from "@emergn-infinity/ids-react";

export const NothingFoundBlock: React.FC<{
    title: string;
    icon?: IconProp;
    message?: string;
    primaryActionLabel?: string;
    primaryActionIcon?: IconProp;
    secondaryActionLabel?: string;
    secondaryActionIcon?: IconProp;
    onPrimaryActionClick?: () => void;
    onSecondaryActionClick?: () => void;
}> = ({
    title,
    icon,
    message,
    primaryActionLabel,
    primaryActionIcon,
    secondaryActionLabel,
    secondaryActionIcon,
    onPrimaryActionClick,
    onSecondaryActionClick,
}) => {
    return (
        <div
            className="flex flex-col items-center justify-center fill-height rounded-lg bg-theme-base"
            // TODO: Add Tailwind classes
            style={{
                gap: "1rem",
                paddingTop: "2rem",
                paddingBottom: "2rem",
                paddingRight: "1rem",
                paddingLeft: "1rem",
            }}
        >
            <div className="flex-column align-center gap-1">
                {icon && (
                    <div>
                        <FontAwesomeIcon className="icon-secondary" icon={icon} size="2xl" />
                    </div>
                )}
                <div className="flex-column align-center gap-1">
                    <IdsText customClasses="text-secondary" weight="bold">
                        <>{title}</>
                    </IdsText>
                    {message && (
                        <IdsText customClasses="text-secondary">
                            <>{message}</>
                        </IdsText>
                    )}
                </div>
            </div>
            {((primaryActionLabel && onPrimaryActionClick) || (secondaryActionLabel && onSecondaryActionClick)) && (
                <IdsButtonGroup spaceBetween="md">
                    {secondaryActionLabel && (
                        <IdsButton variant="secondary" padding="sm" clickHandler={onSecondaryActionClick}>
                            <div className="flex flex-row items-center gap-2">
                                {secondaryActionIcon && <FontAwesomeIcon icon={secondaryActionIcon} size="lg" fixedWidth />}
                                {secondaryActionLabel}
                            </div>
                        </IdsButton>
                    )}
                    {primaryActionLabel && (
                        <IdsButton padding="sm" clickHandler={onPrimaryActionClick}>
                            <div className="flex flex-row items-center gap-2">
                                {primaryActionIcon && <FontAwesomeIcon icon={primaryActionIcon} size="lg" fixedWidth />}
                                {primaryActionLabel}
                            </div>
                        </IdsButton>
                    )}
                </IdsButtonGroup>
            )}
        </div>
    );
};
