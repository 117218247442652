import { IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Badge } from "components/Badge";

import { circleCheckSolidIcon, chevronRightRegularIcon } from "utils/icons";

import type { StepName } from ".";

export const Step: React.FC<{
    number: number;
    name: StepName;
    isActive: boolean;
    isComplete: boolean;
    steps: readonly StepName[];
}> = ({ number, name, isActive, isComplete, steps }) => {
    const index = number - 1;
    let status = "Pending";

    if (isActive) {
        status = "Reviewing";
    } else if (isComplete) {
        status = "Complete";
    }

    return (
        <div className="flex-row align-center justify-space-between">
            <div className="flex-row gap-2">
                <div className="flex-column justify-center">
                    <div
                        className="fill-height mx-auto"
                        style={{
                            border:
                                isActive || isComplete
                                    ? "1px solid var(--ids-semantic-ink-color-brand-b-subtlest)"
                                    : "1px solid var(--theme-base-border)",
                            width: 0,
                            visibility: index === 0 ? "hidden" : undefined,
                        }}
                    />
                    <div className="py-1">
                        {isComplete ? (
                            <FontAwesomeIcon icon={circleCheckSolidIcon} size="xl" color="var(--theme-feedback-success)" />
                        ) : (
                            <Badge variant={isActive ? "brand" : "neutral"} size="md" number={number} />
                        )}
                    </div>
                    <div
                        className="fill-height mx-auto"
                        style={{
                            border: isComplete
                                ? "1px solid var(--ids-semantic-ink-color-brand-b-subtlest)"
                                : "1px solid var(--theme-base-border)",
                            width: 0,
                            visibility: index === steps.length - 1 ? "hidden" : undefined,
                        }}
                    />
                </div>
                <div className="py-2">
                    <IdsText
                        weight="bold"
                        style={{
                            color: isActive || isComplete ? "var(--ids-text-body-color)" : "var(--ids-semantic-ink-color-brand-b-subtlest)",
                        }}
                    >
                        {name}
                    </IdsText>
                    <IdsText
                        size="sm"
                        style={{
                            color: isComplete ? "var(--theme-feedback-success)" : "var(--ids-semantic-ink-color-brand-b-subtlest)",
                        }}
                    >
                        <>{status}</>
                    </IdsText>
                </div>
            </div>
            {isActive && (
                <div>
                    <FontAwesomeIcon icon={chevronRightRegularIcon} color="var(--ids-semantic-ink-color-brand-a-accent)" fixedWidth />
                </div>
            )}
        </div>
    );
};
