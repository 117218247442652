import { IconProp } from "@fortawesome/fontawesome-svg-core";

// Light icons

export const checkLightIcon: IconProp = ["fal", "check"];
export const circleCheckLightIcon: IconProp = ["fal", "circle-check"];
export const circleExclamationLightIcon: IconProp = ["fal", "circle-exclamation"];
export const circleXmarkLightIcon: IconProp = ["fal", "circle-xmark"];
export const xmarkLightIcon: IconProp = ["fal", "xmark"];

// Regular icons

export const arrowProgressRegularIcon: IconProp = ["far", "arrow-progress"];
export const arrowRightRegularIcon: IconProp = ["far", "arrow-right"];
export const arrowRightToBracketRegularIcon: IconProp = ["far", "arrow-right-to-bracket"];
export const arrowsRotateRegularIcon: IconProp = ["far", "arrows-rotate"];
export const arrowUpRightFromSquareRegularIcon: IconProp = ["far", "arrow-up-right-from-square"];
export const chevronRightRegularIcon: IconProp = ["far", "chevron-right"];
export const circleInfoRegularIcon: IconProp = ["far", "circle-info"];
export const circlePlusRegularIcon: IconProp = ["far", "circle-plus"];
export const cloudBoltSunIcon: IconProp = ["far", "cloud-bolt-sun"];
export const copyRegularIcon: IconProp = ["far", "copy"];
export const envelopeRegularIcon: IconProp = ["far", "envelope"];
export const fileCheckRegularIcon: IconProp = ["far", "file-check"];
export const fileExportRegularIcon: IconProp = ["far", "file-export"];
export const fileImportRegularIcon: IconProp = ["far", "file-import"];
export const filesRegularIcon: IconProp = ["far", "files"];
export const filterRegularIcon: IconProp = ["far", "filter"];
export const gearRegularIcon: IconProp = ["far", "gear"];
export const linkSimpleRegularIcon: IconProp = ["far", "link-simple"];
export const linkSimpleSlashRegularIcon: IconProp = ["far", "link-simple-slash"];
export const lockKeyholeOpenRegularIcon: IconProp = ["far", "lock-keyhole-open"];
export const lockKeyholeRegularIcon: IconProp = ["far", "lock-keyhole"];
export const penToSquareRegularIcon: IconProp = ["far", "pen-to-square"];
export const phoneRegularIcon: IconProp = ["far", "phone"];
export const trashRegularIcon: IconProp = ["far", "trash"];
export const userRegularIcon: IconProp = ["far", "user"];
export const xmarkRegularIcon: IconProp = ["far", "xmark"];

// Solid icons

export const alignSlashSolidIcon: IconProp = ["fas", "align-slash"];
export const arrowProgressSolidIcon: IconProp = ["fas", "arrow-progress"];
export const arrowLeftSolidIcon: IconProp = ["fas", "arrow-left"];
export const arrowRightSolidIcon: IconProp = ["fas", "arrow-right"];
export const arrowsRotateSolidIcon: IconProp = ["fas", "arrows-rotate"];
export const bookSolidIcon: IconProp = ["fas", "book"];
export const calculatorSimpleSolidIcon: IconProp = ["fas", "calculator-simple"];
export const caretDownSolidIcon: IconProp = ["fas", "caret-down"];
export const circleCheckSolidIcon: IconProp = ["fas", "circle-check"];
export const circleMinusSolidIcon: IconProp = ["fas", "circle-minus"];
export const circleUserSolidIcon: IconProp = ["fas", "circle-user"];
export const circleXmarkSolidIcon: IconProp = ["fas", "circle-xmark"];
export const fileSlashSolidIcon: IconProp = ["fas", "file-slash"];
export const filterSlashSolidIcon: IconProp = ["fas", "filter-slash"];
export const loaderSolidIcon: IconProp = ["fas", "loader"];
export const magnifyingGlassSolidIcon: IconProp = ["fas", "magnifying-glass"];
export const subtitlesSlashSolidIcon: IconProp = ["fas", "subtitles-slash"];
export const triangleExclamationSolidIcon: IconProp = ["fas", "triangle-exclamation"];
export const userSlashSolidIcon: IconProp = ["fas", "user-slash"];
