import cn from "classnames";
import { isNumber } from "lodash";

import { IdsTag, IdsText } from "@emergn-infinity/ids-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { circleExclamationLightIcon } from "utils/icons";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export const Tile: React.FC<{
    className?: string;
    bodyClassName?: string;
    title?: string;
    icon?: IconProp;
    itemCount?: number;
    counter?: number;
    counterLimit?: number;
    action?: React.ReactNode;
    noDivider?: boolean;
    children: React.ReactNode;
}> = ({ className, bodyClassName, title, icon, itemCount, counter, counterLimit, action, noDivider, children }) => {
    return (
        <div className={cn("flex flex-col border bg-white", className)}>
            <div
                className="flex flex-row items-center gap-2 justify-between py-2 pr-2"
                style={{ boxShadow: title && !noDivider ? "0 2px 4px 0 rgba(25, 23, 43, 0.13)" : undefined }}
            >
                {title && (
                    <div style={{ borderLeft: "5px solid var(--ids-semantic-border-color-brand-a-accent)" }}>
                        <div className="flex flex-col px-2">
                            <div className="flex flex-row items-center gap-2">
                                {icon && <FontAwesomeIcon icon={icon} color="var(--ids-semantic-ink-color-brand-a-accent)" />}
                                <IdsText weight="bold">{title}</IdsText>
                            </div>
                            {isNumber(counter) && (
                                <div className="flex flex-row items-center gap-2">
                                    {counter === 0 && <FontAwesomeIcon className="danger" icon={circleExclamationLightIcon} />}
                                    <IdsText
                                        customClasses={cn({
                                            danger: counter === 0,
                                        })}
                                        size="md"
                                    >
                                        <>{isNumber(counterLimit) ? `Selected: ${counter}/${counterLimit}` : `Selected: ${counter}`}</>
                                    </IdsText>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {action}
                {isNumber(itemCount) && (
                    <IdsTag variant="brand">
                        <>{itemCount}</>
                    </IdsTag>
                )}
            </div>
            <div className={cn("flex-1", bodyClassName)}>{children}</div>
        </div>
    );
};
